import React from 'react';
import {  Row, Col, Button,Label,FormGroup,Form, Modal, ModalHeader, ModalBody} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown} from '@fortawesome/free-solid-svg-icons';
import { useForm } from "react-hook-form";
import { StateList } from './StateList';
import Swal from 'sweetalert2'
import { errorHandling } from '../../utlis';
import { baseString } from '../../config';

const AddPincode = (props) => {
  
  const { register:addPincode, handleSubmit:pincodeSubmit, formState: { errors }, reset } = useForm();
  const onAddPincodeSubmit = (data) => {
    props.setLoader !== undefined && props.setLoader(true);
    let dataAdd={"city_id":data.city_id,"state_id":data.state_id,"pincode":data.pincode};
      fetch(`${process.env.REACT_APP_ADD_PINCODE}`,{
        method:'POST',
        headers:{
          "Content-Type":'application/json',
          "Accept":'application/json',
          "token": window.btoa(baseString)
        },
        body:JSON.stringify(dataAdd)
      }).then((resp)=>{
        resp.json().then((result)=>{
          props.setLoader !== undefined && props.setLoader(false);
          if(result.error === false){
            props.setAddModal !== undefined && props.setAddModal(false);
            props.pincodeListing !== undefined && props.pincodeListing();
            reset()
            Swal.fire({
              title: 'Success',
              text: result.message,
              icon: 'success',
            })
          }
        });
      }).catch((err) =>{
        props.setLoader !== undefined && props.setLoader(false);
        errorHandling(err,"");
      });
  };
  
  const handleClick = event => {
    props.onclick(event.target.value);
  }

  return (

    <Modal isOpen={props.isOpen} toggle={props.toggle} scrollable={true}>
      <ModalHeader toggle={handleClick}>Add Pincode</ModalHeader>
      <ModalBody>
        <Form onSubmit={pincodeSubmit(onAddPincodeSubmit)}>
          <Row className="mx-0 justify-content-center addModal">
            <Col sm={12}>
            <FormGroup>
              <Label for="state_add">Select State<span className="text-danger">*</span></Label>
              <div className="position-relative">                          
                <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                  <select {...addPincode("state_id",{ required: 'Please Select State'})}  className="form-control-sm form-control" id="state_add" onChange={props.onchange}>
                      <option value="">Select State</option>
                      {StateList.map((item, index) => (
                        <option value={item.state_id}>{item.state_name}</option>
                      ))
                  }
                  </select>
                {errors.state_id && <span className="text-danger d-block error">{errors.state_id.message}</span>}
              </div>
            </FormGroup>
            </Col>
            {Object.entries(props.statecity).length >0 &&
            <Col sm={12}>
            <FormGroup>
              <Label for="city_add">Select City<span className="text-danger">*</span></Label>
              <div className="position-relative">
                <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                  <select {...addPincode("city_id",{ required: 'Please Select City'})}  className="form-control-sm form-control" id="city_add">
                      <option value="">Select City</option>
                        {Object.entries(props.statecity).length >0 && props.statecity !== undefined ? props.statecity.map((item, index) => (
                        <option value={item.id}>{item.name}</option>
                      ))
                  :''}
                  </select>
                {errors.city_id && <span className="text-danger d-block error">{errors.city_id.message}</span>}
              </div>
            </FormGroup>
            </Col>
            }
            <Col sm={12}>
                <FormGroup>
                  <Label for="pincode_add">Enter Picode<span className="text-danger">*</span></Label>
                  <input {...addPincode("pincode",{ required: 'Please Enter Pincode.',
                  minLength: { value: 6, message: "at least 6 digits"},
                  maxLength: { value: 6, message: "maximum 6 digits"},
                  pattern: {value:/^[+-]?\d+(\d+)?$/,message:'Please Enter Valid Pincode'} })} type="text" className="form-control-sm form-control" placeholder="Enter Pincode" id="pincode_add" />
                  {errors.pincode && <span className="text-danger d-block error">{errors.pincode.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={12} className="text-right">
              <Button className="ctm-btn btn-sm" color="primary">Save</Button>
            </Col>
          </Row>
        </Form>  
      </ModalBody>
    </Modal>
              
  );
}

export default AddPincode;