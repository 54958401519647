import React,{useState, useEffect} from 'react';
import { Row, Col, Card, CardBody,CardTitle,Form, FormGroup, Label, Button, InputGroup, InputGroupAddon, InputGroupText, Spinner } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import {Eye, EyeOff } from 'react-feather';
import loginBg from "../assets/img/login-bg.svg";
// import Logo from '../assets/img/logo.png';
import { useForm } from 'react-hook-form';
import { LoginMerchant } from '../actions';
import { resetReducer } from '../actions';

import { connect, useDispatch } from "react-redux";
import Swal from 'sweetalert2';
import { encryptStorageLogin } from '../EncStorage';
import Recaptcha from 'react-recaptcha'
// import {encryptStorage} from '../EncStorage';

const Login = (props) => {
    const [passwordType, setPasswordType] = useState(true);
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const logOutUser = () => {
        // localStorage.removeItem("user");
        dispatch(resetReducer());
        encryptStorageLogin.clear();
        localStorage.removeItem('userIdAdminLogin');
        history.push("/login");
      }

useEffect(() =>{
    // if(props.user !== undefined && props.user !== null && Object.entries(props.user).length > 0){
    //     history.push("/");
    //   }else{
    //     history.push("/login");
    //   }
}, [])

    const { register:loginInput, handleSubmit:loginSubmit, formState: { errors }} = useForm();
    
    const onMerchantLogin = (data) => {
        
        setLoader(true);
        dispatch(LoginMerchant(data.username.trim(),data.password.trim())).then(data => {
            setLoader(false)
            // console.log(data,"login")
            if(data.error !== true ){
                
                encryptStorageLogin.setItem('userenc', JSON.stringify(data.data));
                // if(data?.data[0]?.channel_order_settings.length>0){
                // const channelSetting=data?.data[0]?.channel_order_settings;
                // localStorage.setItem("channel-settings", JSON.stringify(channelSetting));
                // }else{
                //     null
                // }
                
                window.location.reload();

                if(data.data[0].document_status === 0 ){
                history.push("/settings/1");
                }else{
                history.push("/");
                }
            }else{
                Swal.fire({icon:'error', text:"Invalid Credentials", title:'Error'});
            }
          }).catch(function (response) {
            setLoader(false)
            Swal.fire({title:'Error', icon:'error', text:response.msg})
        });
    };
    // specifying your onload callback function
var callback = function () {
 // console.log('Done!!!!');
};
 
// specifying verify callback function
var verifyCallback = function (response) {
 // console.log(response);
};
    return (
        <div className="app-content m-0 p-0 content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
          <Row>
           <Col sm={12}>            
            <div className="p-0 LoginPage content-wrapper">
                <Row>
                    <Link href="#" className="brand-logo text-center py-3 py-lg-0 d-none" ><img src={`./logo${process.env.REACT_APP_ADMIN_ID}.png`} alt="logo" style={{maxWidth:'200px'}} /></Link>
                    <Col sm={12} lg={7} className="d-none d-lg-flex align-items-center p-5">
                        <div className="d-lg-flex justify-content-center align-items-center px-5 w-100">
                            <img className="img-fluid" src={loginBg} alt="img" />
                        </div>
                    </Col>
                    <Col sm={12} lg={5} className="d-flex align-items-center px-2 p-lg-5 bg-white">
                        <Card className="border-0 px-xl-3 mx-auto w-100">
                            <Link href="#" className="brand-logo2 text-center py-3 py-lg-0" ><img src={`./logo${process.env.REACT_APP_ADMIN_ID}.png`} alt="logo" style={{maxWidth:'200px'}} /></Link>
                            <CardBody className="align-items-center">
                              <CardTitle tag="h3" className="text-center">Welcome to {process.env.REACT_APP_NAME} !</CardTitle>
                              <CardTitle tag="h6" className="text-center mb-3">Login Here</CardTitle>
                              {/* <p className="card-text text-center font-weight-bold">Login Here</p> */}
                              <Form onSubmit={loginSubmit(onMerchantLogin)}>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <input {...loginInput("username",{ required: 'Please Enter Email'})} className="form-control" placeholder="Enter Email" id="email" />
                                    {errors.username && <span className="text-danger d-block mb-2">{errors.username.message}</span>}
                                </FormGroup> 
                                <FormGroup>
                                    <div className="d-flex justify-content-between">
                                        <Label for="password">Password</Label>
                                    </div>
                                    <InputGroup>
                                        <input {...loginInput("password",{ required: 'Please Enter Password'})} type={passwordType? "password" : "text"} placeholder="Enter Password" className="form-control" id="password" />
                                        <InputGroupAddon addonType="append" onClick={() => setPasswordType(!passwordType)}>
                                            <InputGroupText className="py-0"> {passwordType ? <Eye /> : <EyeOff />}</InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>  
                                    {errors.password && <span className="text-danger d-block mb-2">{errors.password.message}</span>}
                                </FormGroup>
                                <FormGroup>
                                    <Label><Link to="/forgot-password">Forgot Password?</Link></Label>
                                </FormGroup>                                   
                                <FormGroup> 
                                <Recaptcha
                                    sitekey="6LcI_OwdAAAAAGHocovhx4ZEh38AiAmwV4oQvqBP"
                                    render="explicit"
                                    verifyCallback={verifyCallback}
                                    onloadCallback={callback}
                                />              
                                    <Button className="btn-sm ctm-btn w-100 py-2" color="primary"> Sign in</Button>                                     
                                </FormGroup>
                                <p className="text-center">
                                    <span>New on our platform?</span>
                                    <Link to="/register" className="ml-25">Create an account</Link>
                                </p>
                              </Form>
                            </CardBody>
                        </Card>                          
                    </Col>                    
                </Row>
              
            </div>
            </Col>         
          </Row>
        </div>
    );
}
function mapStateToProps (state) {
    return{
      state:state.DataReducer
    }
  }
export default connect(mapStateToProps)(Login);