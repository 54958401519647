import React, { useEffect, useState } from 'react';
import { Row, Col, Label, Form, FormGroup, Button, CardTitle } from 'reactstrap';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Swal from 'sweetalert2'
import { checkData } from '../../../ObjectExist';
import { errorHandling } from '../../../utlis';
import { baseString } from '../../../config';

const BankDetail = (props) => {
  const [checkCopy, setCheckcopy] = useState({});
  const [bankDetails, setBankDetails] = useState();
  const [formDisabled, setFormDisabled] = useState(false);

  const handleFileInput = (e) => {
    setCheckcopy(e.target.files[0])
  }
  const { register: addMerchant_bankDetails, handleSubmit: merchantDetailsSubmit, formState: { errors }, reset } = useForm();
  const viewBackDetails = () => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_BANKDETAILS}/view`,
      data: { "merchant_id": props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ? props.user[0].id : '' },
      headers: { "token": window.btoa(baseString) }
    })
      .then(function (response) {
        if (response.data.length > 0) {
          // console.log(response.data,'enr')
          let myResponse = response.data[0];
          if (checkData(myResponse)) {
            setFormDisabled(true);
          }
          reset(myResponse);
          setBankDetails(myResponse)
          props.setBankDetails(myResponse)
        }
      })
      .catch(function (err) {
        errorHandling(err, "reload");
      });
  }
  useEffect(() => {
    viewBackDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset])
  const onAddbankDetails = (data) => {
    props.setLoader(true);
    let formData = new FormData();
    formData.append("merchant_id", props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ? props.user[0].id : '');
    formData.append("account_type", data.account_type);
    formData.append("bank_name", data.bank_name);
    formData.append("account_number", data.account_number);
    formData.append("ifsc_code", data.ifsc_code);
    formData.append("branch", data.branch);
    formData.append("cancel_cheque_copy", checkCopy !== '' ? checkCopy : bankDetails !== undefined && bankDetails.cancel_cheque_copy !== undefined && bankDetails.cancel_cheque_copy !== "" ? bankDetails.cancel_cheque_copy : 'NA');
   
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_BANKDETAILS}`,
      data: formData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      props.setLoader(false);
      if (res.data.error === false) {
        viewBackDetails();
        Swal.fire({
          title: 'Success',
          text: res.data.message,
          icon: 'success',
        })
      }
    })
      .catch((err) => {
        props.setLoader(false);
        errorHandling(err, "reload");
      });
  };

  return (
    <Form onSubmit={merchantDetailsSubmit(onAddbankDetails)}>
      <div className="sub-page-header">
        <CardTitle tag="h4" className="sub-page-title">Bank Details</CardTitle>
      </div>
      <Row>
        <Col sm={6}>
          <FormGroup>
            <Label for="bank_name">Bank Name</Label>
            <input disabled={formDisabled} {...addMerchant_bankDetails("bank_name", { required: 'Please Enter Bank Name' })} className="form-control" placeholder="Bank Name" id="bank_name" />
            {errors.bank_name && <span className="text-danger d-block error">{errors.bank_name.message}</span>}
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup>
            <Label for="acont_no">Account Number</Label>
            <input disabled={formDisabled} {...addMerchant_bankDetails("account_number", {
              required: 'Please Enter Account Number',
              pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Account Number ' }
            })} type="text" className="form-control" placeholder="Account Number" id="acont_no" />
            {errors.account_number && <span className="text-danger d-block error">{errors.account_number.message}</span>}
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup>
            <Label for="ifsc_code">IFSC Code</Label>
            <input disabled={formDisabled} {...addMerchant_bankDetails("ifsc_code", { required: 'Please Enter IFSC Code' })} className="form-control" placeholder="IFSC Code" id="ifsc_code" />
            {errors.ifsc_code && <span className="text-danger d-block error">{errors.ifsc_code.message}</span>}
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup>
            <Label for="branch_detl">Branch Name</Label>
            <input disabled={formDisabled} {...addMerchant_bankDetails("branch", { required: 'Please Enter Branch Name' })} className="form-control" placeholder="" id="branch_detl" />
            {errors.branch && <span className="text-danger d-block error">{errors.branch.message}</span>}
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup>
            <Label>Account Type</Label>
            <div>
              <span className="mr-2">
                <input disabled={formDisabled} {...addMerchant_bankDetails("account_type", { required: 'Please Select Account Type' })} type="radio" value="Saving" id="saving" />
                <Label className="mx-2" for="saving">Saving</Label>
              </span>
              <span>
                <input disabled={formDisabled} {...addMerchant_bankDetails("account_type", { required: 'Please Select Account Type' })} type="radio" value="Current" id="current" />
                <Label className="mx-2" for="current">Current</Label>
              </span>
            </div>
            {errors.account_type && <span className="text-danger d-block mb-2">{errors.account_type.message}</span>}
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup>
            <Label for="chequeCopy">Upload Cheque Book Copy</Label>
            <input disabled={formDisabled} {...addMerchant_bankDetails("cheque_copy", { required: bankDetails !== undefined && bankDetails.cancel_cheque_copy !== undefined && bankDetails.cancel_cheque_copy !== "" ? false : true && 'Please Add Cheque Book Copy' })} type="file" onChange={handleFileInput} accept=".jpg, .jpeg, .png" className="form-control-sm form-control" id="chequeCopy" />
            {bankDetails !== undefined && bankDetails.cancel_cheque_copy !== undefined && bankDetails.cancel_cheque_copy !== null && bankDetails.cancel_cheque_copy !== "" && <div className="edit-brand-logo mt-2"><img src={process.env.REACT_APP_IMAGE_MERCHANT + bankDetails.cancel_cheque_copy} alt="logo" style={{ width: "120px" }} /></div>}
            {errors.cheque_copy && <span className="text-danger d-block mb-2">{errors.cheque_copy.message}</span>}
          </FormGroup>
        </Col>
        {!formDisabled &&
          <Col sm={12} className="mt-2 text-right">
            <Button className="btn-sm ctm-btn" color="primary"> Save changes</Button>
          </Col>
        }
      </Row>
    </Form>
  );
}

export default BankDetail;
