import React from 'react';
import { Row, Col, Card, CardBody,Button, CardTitle, Input,FormGroup,Form, CardHeader, Table, Pagination,PaginationItem,PaginationLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown} from '@fortawesome/free-solid-svg-icons';

const MultiplePickPoint = (props) => {
  return (
    <div className="app-content content overflow-hidden">
      <Row>
       <Col sm={12}>
        <Card className="p-0 my-3 multiplePickupPoint shadow content-wrapper border-0">
          <Card className="border-0">
            <CardHeader className="page-header d-flex flex-wrap justify-content-between">
              <CardTitle className="mb-0 page-title">Manage Multiple Pickup Points</CardTitle>
            </CardHeader>
            <Row className="mx-0 pt-1 justify-content-center">
                <Col sm={3}>
                    <Form>
                        <FormGroup>
                            <Input type="text" name="ordernumber" id="ordernumber" placeholder="Search By" className="form-control-sm" />
                        </FormGroup>
                    </Form>
              </Col>
              <Col sm={3} className="text-left">
                <Button className="btn ctm-btn btn-sm search" color="primary">Search</Button>
              </Col>
            </Row>
              <CardBody className="p-0">
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Brand</th>
                      <th>Date</th>
                      <th>Actions</th>
                    </tr>
                   </thead>
                  <tbody>
                    <tr>
                        <td>236</td>
                        <td><span>Suresh Bhai</span><br /><span>Code-LP-MR-1616569776</span></td>
                        <td>onlineworking00011@gmail.com</td>
                        <td>ECOMMERCE STORE</td>
                        <td><span>Created- 3/24/21, 12:39 PM</span><br /><span>Updated- 3/24/21, 3:53 PM</span></td>
                        <td><span className="mr-2"><Button className="btn  btn-sm download" color="primary">Active</Button></span>
                        <span className="mr-2"><Button className="btn  btn-sm download" color="primary">Edit</Button></span>
                        <span className="mr-2"><Button className="btn  btn-sm download" color="primary">View</Button></span>
                        <span className="mr-2"><Button className="btn  btn-sm download" color="primary">More</Button></span>
                        </td>
                    </tr>
                  </tbody>
                </Table>
                <Row className="m-0 table-footer">
                  <Col xs="6">
                    <Pagination aria-label="Page navigation example">
                        <PaginationItem>
                          <PaginationLink previous href="#">Previous</PaginationLink> 
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink next href="#">Next</PaginationLink>
                        </PaginationItem>
                    </Pagination>
                  </Col>
                  <Col xs="6">                
                    <Form>
                      <FormGroup>
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <Input type="select" name="select" id="exampleSelect" className="form-control-sm">
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              </Input>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
        </Card>
      </Col>
      
      </Row>
       </div>
  );
}

export default MultiplePickPoint;