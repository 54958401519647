import React, {useEffect, useState} from 'react';
import {  Row, Col, Card, CardBody,Button,Spinner, CardTitle, FormGroup,Form, CardHeader, Table,Pagination, PaginationItem, PaginationLink} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import {ChevronLeft,ChevronRight} from 'react-feather';
import { useForm } from "react-hook-form";
import LoaderDefault from '../LoaderDefault';
import { changeIntoDate, remExtraSpace, errorHandling } from '../../utlis';
import AddPincode from './AddPincode';
import EditPincode from './EditPincode';
import Swal from 'sweetalert2';
import NodataFound from '../NodataFound';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { connect, useDispatch } from "react-redux";
import { AllPincode } from '../../actions';
import { baseString } from '../../config';

const Pincode = (props) => {
  const [pincode, setPincode] = useState({});
  const [page, setPage] = useState(1);
  const [selectperpage,setSelectperpage] = useState(20);
  const [addmodal, setAddModal] = useState(false);
  const [editmodal, seteditModal] = useState(false);
  const [stateCity, setStateCity] = useState({});
  const [editPin, setEditPin] = useState({});
  const [loader, setLoader] = useState(false);
  const [loadertable, setLoadertable] = useState(false);
  const [filterInput, setFilterInput] = useState('');
  const [currentCount,setCurrentCount] = useState(1);
  const dispatch = useDispatch();
  const dropFilterCount = 20
 
  const toggleAdd = () => setAddModal(!addmodal);
  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = {"page":1, "per_page":event.target.value};
    onpaginationFilter(filterInput,pagePerpage)
  }
  const toggleEditclose = () => seteditModal(!editmodal);
  const toggleEdit = (i) => {
    seteditModal(!editmodal)
    fetch(`${process.env.REACT_APP_GET_PINCODE}/${i}`,{
      method:'POST',
      headers:{
        "Content-Type":'application/json',
        "Accept":'application/json',
        "token": window.btoa(baseString)
      },
    }).then((resp)=>{
      resp.json().then((result)=>{
        setEditPin(result);
        fetch(`${process.env.REACT_APP_GET_CITY_STATE}?state_id=${result[0].state_id}`,{
          method:'POST',
          headers:{
            "Content-Type":'application/json',
            "Accept":'application/json',
            "token": window.btoa(baseString)
          },
        }).then((resp)=>{
          resp.json().then((result)=>{
            setStateCity(result);
          });
        });
      });
    });
  }
  const pincodeListing = () =>{
    setFilterInput('');
    setCurrentCount(1);
    setPage(1);
    let mypost={"merchant_id":1};
    let myget={"page":page,per_page:selectperpage};
    setLoadertable(true);
    dispatch(AllPincode(mypost,myget)).then(data => {
      setLoadertable(false)
          setPincode(data);
    }).catch((err) =>{
      setLoadertable(false);
      errorHandling(err,"reload");
    });
  }
  useEffect(() => {
    if(checkData(props.state) && checkData(props.state.viewAllPincode)){
      setPincode(props.state.viewAllPincode);
    }else{
      pincodeListing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectperpage,page])
  const { register, handleSubmit } = useForm();
  function nextPageData() {
    if(checkData(pincode.data) && checkUndeNullBlank(pincode.totalPage)){
        if(pincode.totalPage > page){
          setCurrentCount(currentCount+Object.entries(pincode.data).length)
      setPage(page+1)
      setLoader(true)
        let pagePerpage = {"page":page+1, "per_page":selectperpage};
        onpaginationFilter(filterInput,pagePerpage);
      }
    }
  }
  function previousPageData() {
    if(page >1){
      setPage(page-1)
      checkData(pincode.data)
      if(currentCount>Object.entries(pincode.data).length){
        setCurrentCount(currentCount-selectperpage)
      }else{
        setCurrentCount(1)
      }
      let pagePerpage = {"page":page >1 && page-1, "per_page":selectperpage};
      onpaginationFilter(filterInput,pagePerpage);
    }
  }
  const onpaginationFilter = (data,pagePerpage) => {
    onCommonFilter(data,pagePerpage)
  };
  const onSubmit = (data, pagePerpage) => {
    pagePerpage.page=1
    if(checkUndeNullBlank(data.state) || checkUndeNullBlank(data.city) || checkUndeNullBlank(data.pincode)){
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data,pagePerpage)
    }else{
      Swal.fire({title: 'info',text: 'Please Choose Filter',icon: 'info',})
    }
      
  };
  const onCommonFilter = (data,pagePerpage) => {
    setFilterInput(data);
    setLoader(true)
    fetch(`${process.env.REACT_APP_GET_PINCODE}?state=${checkUndeNullBlank(data.state)?remExtraSpace(data.state):''}&city=${checkUndeNullBlank(data.city)?remExtraSpace(data.city):''}&pincode=${checkUndeNullBlank(data.pincode)?remExtraSpace(data.pincode):''}&page=${checkUndeNullBlank(pagePerpage.page)?pagePerpage.page:page}&per_page=${checkUndeNullBlank(pagePerpage.per_page)?pagePerpage.per_page:selectperpage}`,{
      method:'POST',
      headers:{
        "Content-Type":'application/json',
        "Accept":'application/json',
        "token": window.btoa(baseString)
      },
    }).then((resp)=>{
      resp.json().then((result)=>{
        setLoader(false)
        setPincode(result);
      });
    }).catch((err) => {
      setLoader(false);
      errorHandling(err,"");
    });
  };
  const handleStateChange =(e) => {
    fetch(`${process.env.REACT_APP_GET_CITY_STATE}?state_id=${e.target.value}`,{
      method:'POST',
      headers:{
        "Content-Type":'application/json',
        "Accept":'application/json',
        "token": window.btoa(baseString)
      },
    }).then((resp)=>{
      resp.json().then((result)=>{
        setStateCity(result);
      });
    });
  }
  const RemovePincode = (id) => {
    Swal.fire({
      icon: 'warning',
      title: '<p class="confirm-msg"> Do you want to delete this record?</p>',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        setLoader(true);
        fetch(`${process.env.REACT_APP_REMOVE_PINCODE}/${id}`,{
          method:'POST',
          headers:{
            "Content-Type":'application/json',
            "Accept":'application/json',
            "token": window.btoa(baseString)
          },
        }).then((resp)=>{
          resp.json().then((result)=>{
            setLoader(false);
            pincodeListing();
            Swal.fire({title: 'Success', text: result.msg, icon: 'success'});
          });
        }).catch((err) =>{
          setLoader(false);
          errorHandling(err,"");
        });
      } 
    })
  }

  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
       <Col sm={12}>
        <Card className="p-0 my-3 managePincode shadow content-wrapper border-0">
          <Card className="border-0">
            <CardHeader className="page-header d-flex justify-content-between flex-wrap">
              <CardTitle className="mb-0 page-title">Manage Pincode </CardTitle>
              <div className="card-header-right d-flex align-items-center ml-auto">
                <div className="btn-text btn-light" onClick={toggleAdd}><span className="icon mr-2"><FontAwesomeIcon icon={faPlus} /></span><span>Add Pincode</span>
                </div>
              </div>
              <AddPincode setLoader={setLoader} pincodeListing={pincodeListing} setAddModal={setAddModal} isOpen={addmodal} toggle={addmodal} onclick={(e) => { toggleAdd(e) }} onchange={handleStateChange} statecity={stateCity} />
              {checkData(editPin) &&
              <EditPincode setLoader={setLoader} pincodeListing={pincodeListing} seteditModal={seteditModal} isOpen={editmodal} toggle={editmodal} onclick={(e) => { toggleEditclose(e) }} onchange={handleStateChange} editpin={editPin} statecity={stateCity} />              
            }
              </CardHeader>
                <Form onSubmit={handleSubmit(onSubmit)} className="cardFilter">
                  <Row className="mx-0 pt-1 justify-content-start justify-content-lg-center">
                    <Col sm={6} md={4} lg={3} xl={3}>
                      <FormGroup>
                          <input {...register("state")}  className="form-control-sm form-control" placeholder="Enter State" />
                        </FormGroup>
                      
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={3}>
                      <FormGroup>
                          <input {...register("city")}  className="form-control-sm form-control" placeholder="Enter City" />
                        </FormGroup>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={3}>
                        <FormGroup>
                          <input {...register("pincode")}  className="form-control-sm form-control" placeholder="Enter Pincode" />
                        </FormGroup>
                    </Col>
                    <Col sm={3} className="btn-container">
                      <Button className="ctm-btn btn-sm" color="primary">Search</Button>
                      <Button type="reset" className="ctm-btn btn-sm mx-2" color="primary" outline onClick={pincodeListing}>Reset</Button>
                    </Col>
                  </Row>
                </Form>
              <CardBody className="p-0 ">
                <div className="showing-count">
                  {checkData(pincode) && checkData(pincode.data) && checkUndeNullBlank(pincode.TotalRows) ?
                  <span className="small">Showing {currentCount} to {(currentCount-1)+(Object.entries(pincode.data).length)} of  {pincode.TotalRows}</span>: ''}
                </div>
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      <th>State</th>
                      <th>City</th>
                      <th>Pincode</th>
                      <th>Created</th>
                      <th style={{width:'11%'}}>Actions</th>
                    </tr>
                   </thead>
                  <tbody>
                  {checkData(pincode.data) ? pincode.data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.state || 'NA'}</td>
                      <td>{item.name || 'Na'}</td>
                      <td>{item.pincode}</td>
                      <td className="text-nowrap">{(item.created_at !== undefined && item.created_at !== null?changeIntoDate(item.created_at):'NA')}</td>
                      <td className="text-nowrap">
                        <span><Button className="ctm-btn btn-sm ml-2" color="info" outline onClick={() => toggleEdit(item.id)}>Edit</Button></span>
                        <span><Button outline className="ctm-btn btn-sm light ml-2" color="secondary" onClick={() => RemovePincode(item.id)}>Delete </Button></span>
                        </td>
                    </tr>
                    ))
                    :loadertable ? <tr><td colSpan="5"><LoaderDefault /></td></tr>:
                    <tr><td colSpan="5"><NodataFound /></td></tr>}
                    </tbody>
                </Table>
              </CardBody>
              {checkData(pincode) && checkData(pincode.data) && checkUndeNullBlank(pincode.TotalRows) ?
                <>
                {pincode.TotalRows > dropFilterCount ?
               <Row className="m-0 table-footer">
               <Col xs="6">               
                <Pagination aria-label="Page navigation example">
                    <PaginationItem className="prev">
                      <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink> 
                    </PaginationItem>
                    <PaginationItem className="next ml-3">
                      <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                    </PaginationItem>
                </Pagination>
                </Col>
                <Col xs="6">
                <Form>
                  <FormGroup>
                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                      <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                          <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          </select>
                  </FormGroup>
                </Form>
                </Col>
               </Row>:''}
               </>
               :''}
            </Card>
        </Card>
      </Col>
      
      </Row>
       </div>
  );
}

function mapDispatchToProps (state) {
  return{
    state:state.DataReducer
  }
}

export default connect(mapDispatchToProps)(Pincode);