import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, Input, FormGroup, Form, CardHeader, Table, UncontrolledTooltip, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faDownload, faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { DateRangePicker } from 'rsuite';
import { useForm } from "react-hook-form";
import { copyToClipBoard, firstDayOftheMonth, errorHandling, remExtraSpace } from '../../utlis';
import { Copy, ChevronLeft, ChevronRight } from 'react-feather';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment';
import axios from 'axios';
import Swal from 'sweetalert2';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { baseString } from '../../config';

const CreditNote = (props) => {

  const [excelmodal, setExcelmodal] = useState(false);
  const [excelFile, setExcelFile] = useState();
  const [creditNote, setCreditNote] = useState({});
  const [page, setPage] = useState(1)
  const [selectperpage, setSelectperpage] = useState('20');
  const [filterDate, setFilterDate] = useState([]);
  // const [loadertable, setLoadertable] = useState(false);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState({});
  const [merchants, setMerchants] = useState([]);
  const [headId, setHeadId] = useState([]);
  // const [loader, setLoader] = useState(false);
  const dropFilterCount = 20;
  const onDateChange = (e) => {
    setFilterDate(e.text);
  };
  let dateRangeRef = dateRange => dateRangeRef = dateRange;
  const excelUpload = () => {
    setExcelmodal(!excelmodal);
    resetCreditNotefile();
    if (!excelmodal) { setExcelFile(""); }
  }

  const handleFileInput = (e) => {
    setExcelFile(e.target.files[0]);
  }
  useEffect(() => {
    creditNoteListing();
    setMerchants([]);
    setHeadId([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const creditNoteListing = () => {
    // setLoadertable(true);
    setFilterDate([firstDayOftheMonth(), new Date()]);
    let data = { "start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), "end_date": moment().format('YYYY-MM-DD') }
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }

    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_CREDIT_NOTE_LIST}?page=${page}&per_page=${selectperpage}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      // setLoadertable(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          setCreditNote(res.data);
        }
      }
    }).catch((err) => {
      // setLoadertable(false);
      errorHandling(err, "reload");
    });
  }
  const { register: uploadCreditNote, handleSubmit: creditNoteSubmit, formState: { errors }, reset: resetCreditNotefile } = useForm();
  const onUploadCreditNote = () => {
  }


  const { register: filter, handleSubmit: filterCreditNoteSubmit } = useForm();

  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }

  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(creditNote.data)
      if (currentCount > Object.entries(creditNote.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }

  function nextPageData() {
    if (checkData(creditNote.data) && checkUndeNullBlank(creditNote.totalPage)) {
      if (creditNote.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(creditNote.data).length)
        setPage(page + 1)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }

  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };

  const onCreditNote = (data, pagePerpage) => {
    // setIsExport(true);
    pagePerpage.page = 1
    if (checkUndeNullBlank(data.credit_note_no) || checkData(merchants) || checkData(headId) || checkData(filterDate)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
      Swal.fire({ title: 'info', text: 'Please Choose Filter', icon: 'info', })
    }
  };

  const onCommonFilter = (data, pagePerpage) => {
    let byData = {}
    let merchantsId = [];
    let headIds = [];
    // if(checkData(merchants)){
    //   merchants.map((item) => (
    //     merchantsId.push(item.value)
    //   )); 
    // }
    if (checkData(headId)) {
      headId.map((item) => (
        headIds.push(item.value)
      ));
    }
    let mId = checkUndeNullBlank(merchantsId) ? merchantsId : '';
    let hId = checkUndeNullBlank(headIds) ? headIds : '';
    let sDate = checkData(filterDate) ? moment(filterDate[0]).format('YYYY-MM-DD') : '';
    let eDate = checkData(filterDate) ? moment(filterDate[1]).format('YYYY-MM-DD') : '';
    // setLoader(true)
    byData = { "credit_note_no": checkUndeNullBlank(data.credit_note_no) ? remExtraSpace(data.credit_note_no) : '', "merchant_ids": mId, "head_ids": hId, "start_date": sDate, "end_date": eDate, }
    setFilterInput(byData);

    if (props.user[0].user_type === "Super") {
      byData.admin_id = props.user[0].id
    } else {
      byData.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_CREDIT_NOTE_LIST}?page=${checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: byData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      // setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setCreditNote(res.data);
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "");
      // setLoader(false);
    });
  };


  //copy text
  const copied = (text) => {
    let decision = copyToClipBoard(text);
    decision.then(function (result) {
      if (result === true) {
        toast("Copied!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
  }

  return (
    <div className="app-content content overflow-hidden">
      <Row>
        <Col sm={12}>
          <Card className="p-0 my-3 CourierCreditNote shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex flex-wrap justify-content-between">
                <CardTitle className="page-title">Manage Courier Credit Note</CardTitle>
                <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                  <Link className="btn-text btn-light mx-2"><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Download Credit Note</span></Link>
                  <div className="btn-text btn-light" onClick={excelUpload} ><span className="icon mr-2"><FontAwesomeIcon icon={faArrowUp} /></span><span>Upload Credit Note</span></div>
                </div>
                <Modal isOpen={excelmodal} toggle={excelmodal} scrollable={true} >
                  <ModalHeader toggle={excelUpload}>Upload Credit Note</ModalHeader>
                  <ModalBody>
                    <Form onSubmit={creditNoteSubmit(onUploadCreditNote)}>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Upload all Your Credit Note via XLSX</p>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Please download below given template</p>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Remove sample data and upload your data</p>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Make sure you atleast fill mandatory fields.</p>

                      <Row className="m-0 pb-1">
                        <Col sm={12} className="p-0">
                          <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span> <Link target="_blank" download to="/format/courier_credit_note.xlsx" className=""><FontAwesomeIcon icon={faDownload} />  Download
                          </Link>
                            <span className="ml-2">Credit Format File</span></p>
                        </Col>
                        <Col sm={12} className="m-auto pt-1 p-0">
                          <FormGroup>
                            <input {...uploadCreditNote("excelUpload", {
                              required: 'Please Upload XLSX File',
                              validate: {
                                lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                                acceptedFormats: files =>
                                  ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                                    files[0]?.type
                                  ) || 'Only .xslx file allowed',
                              },
                            })} type="file" id="excelUpload" accept=".xlsx" className="custom-file-input" onChange={handleFileInput} />
                            <Label className="custom-file-label" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name : 'Choose file'}</Label>
                            {errors.excelUpload && <span className="text-danger d-block error">{errors.excelUpload.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12} className="text-right">
                          <Button className="ctm-btn btn-sm" color="primary">Upload</Button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
              </CardHeader>
              <Form onSubmit={filterCreditNoteSubmit(onCreditNote)} className="cardFilter">
                <Row className="mx-0 justify-content-start justify-content-md-center">
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <input {...filter("credit_note_no")} placeholder="Enter Credit Note No" className="form-control-sm" />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={2}>
                    <FormGroup>
                      <div className="position-relative">
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <Input type="select" name="select" className="form-control-sm">
                          <option>Select Category</option>
                          <option>Claim (As per liability)</option>
                          <option>Reversal (Weight & Freight issues)</option>
                        </Input>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <Input type="text" placeholder="Enter AWB No" className="form-control-sm" />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={5} lg={4} xl={3}>
                    <FormGroup>
                      <Input type="text" placeholder="Enter Courier Name" className="form-control-sm" />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} placeholder="Choose Tra. Date Range" placement="bottomEnd" format="dd-MM-yyyy" ref={dateRangeRef} change={onDateChange} />
                    </FormGroup>
                  </Col>
                  <Col sm={3} className="btn-container">
                    <Button className="ctm-btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="ctm-btn btn-sm mx-2" color="primary" outline>Reset</Button>
                  </Col>
                </Row>
              </Form>
              <CardBody className="p-0">
                <div className="showing-count">
                  {checkData(creditNote) && checkData(creditNote.data) && checkUndeNullBlank(creditNote.TotalRows) ?
                    <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(creditNote.data).length)} of {creditNote.TotalRows}</span> : ''}
                </div><Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      <th className="text-nowrap w-some">Category</th>
                      <th className="text-nowrap">Credit Note No.</th>
                      <th className="text-nowrap">AWB No.</th>
                      <th className="text-nowrap w-some">Courier</th>
                      <th className="text-nowrap">Merchant</th>
                      <th className="text-nowrap">Credit Amount</th>
                      <th className="text-nowrap w-some">Desc.</th>
                      <th className="text-nowrap">Transaction Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Reversal (Weight & Freight issues)</td>
                      <td><Link href="#">132111047780867</Link></td>
                      <td>
                        <div className="text-nowrap">3159414703355
                          <span onClick={() => copied(3159414703355)} className="ml-25 copy-btn" id={`copy1`}>
                            <Copy />
                            <UncontrolledTooltip placement="bottom" target={`copy1`}>
                              Copy
                            </UncontrolledTooltip>
                          </span>
                        </div>
                      </td>
                      <td>SS_Delhivery (Surface - 0.5KG)</td>
                      <td>MAASH STORE</td>
                      <td>22</td>
                      <td>Weight Dispute Cases</td>
                      <td className="text-nowrap">05-07-2021 || 18:15:16</td>
                    </tr>

                  </tbody>
                </Table>
                <ToastContainer style={{ width: "200px" }} />
                {checkData(creditNote) && checkData(creditNote.data) && checkUndeNullBlank(creditNote.TotalRows) ?
                  <>
                    {creditNote.TotalRows > dropFilterCount ?
                      <Row className="m-0 table-footer">
                        <Col xs="6">
                          <Pagination aria-label="Page navigation example align-items-center">
                            <PaginationItem className="prev">
                              <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                            </PaginationItem>
                            <PaginationItem className="next ml-3">
                              <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </Col>
                        <Col xs="6">
                          <Form>
                            <FormGroup>
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                                <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="250">250</option>
                              </select>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row> : ''}
                  </>
                  : ''}
              </CardBody>
            </Card>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default CreditNote;