import React,{useState, useEffect} from 'react';
import {Card,CardBody, CardText, CardSubtitle,Button, CardTitle} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes} from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom'

const PaymentMsg = (props) => {
    const [msgType, setMsgType] = useState(1);
  useEffect(() => {
    setMsgType(1)
  }, [])
    return (
        <div className="app-content m-0 p-0 content overflow-hidden paymentmsg">
            <div className="inner-paymentmsg d-flex justify-content-center align-items-center">
                <Card className="paymentmsg-box text-center mx-auto shadow">
                    <CardBody>
                        <div className="img-con">
                            <span className={`paymentmsg-icon ${msgType ===1 ? "success-icon" : "failed-icon"} mx-auto d-inline-flex justify-content-center align-items-center`}>
                                {msgType === 1 ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}
                            </span>
                        </div>
                        <CardTitle tag="h4" className="my-4">Payment {msgType ===1 ? "Complete":"Failed"}</CardTitle>
                        <CardText>
                            Thank you, Your payment has been successfull.                         
                        </CardText>
                        <CardSubtitle>Your Wallet Amount<br/>Update with <span className="amount font-weight-bold">Rs 500</span></CardSubtitle>
                        <div className="mt-4"><Link to="/login"><Button className="btn ctm-btn btn-sm" color="primary">Login</Button></Link></div>
                    </CardBody>
                </Card>   
            </div>        
        </div>
    )
}

export default PaymentMsg
 