import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, CardHeader, Button, CardSubtitle, CardText, Table, Spinner, ListGroup, ListGroupItem, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Form, FormGroup, Label } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { ArrowLeft, Copy } from 'react-feather';
import TrackOrder from "../Orders/TrackOrder";
import { useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Swal from 'sweetalert2';
import { delivery_type_id,movement_type_id, service, states, kyc_type_id, download_delay, zone_category_id, volumetricFormula, baseString } from '../../config';
import { changeIntoDate, changeIntoDateYmd, copyToClipBoard, errorHandling } from '../../utlis';
import { toast, ToastContainer } from 'react-toastify';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { faArrowDown, faCartPlus, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NodataFound from '../NodataFound';
import { DatePicker } from 'rsuite';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const OrderDetail = (props) => {
    const { orderId } = useParams();
    const [orderDetail, setOrderDetail] = useState({});
    const [walkInCustDetails, setWalkInCustDetails] = useState({});
    const [remittDetails, setRemittDetails] = useState({});
    const [freightDetails, setFreightDetails] = useState({});
    const [loader, setLoader] = useState(false);
    const [orderTracking, setOrderTracking] = useState({});
    const [adminRaiseModal, setAdminRaiseModal] = useState(false);
    const toggleAdminRaise = () => setAdminRaiseModal(!adminRaiseModal);
    const [merchantRaiseModal, setMerchantRaiseModal] = useState(false);
    const [addDisputDate, setAddDisputDate] = useState(new Date());
    const [editIcon,setEditIcon]=useState('')
    const toggleMerchantRaise = () => setMerchantRaiseModal(!merchantRaiseModal);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showDatePicker2, setShowDatePicker2] = useState(false);
    const [editingDate, setEditingDate] = useState(null);
    const [postDate,setPostDate]=useState()

    const [editIcon2,setEditIcon2]=useState('')
    const [editingDate2, setEditingDate2] = useState(null);
    const [editIcon3,setEditIcon3]=useState('')
    const [editingDate3, setEditingDate3] = useState(null);
    const [postDate2,setPostDate2]=useState()
    const [postDate3,setPostDate3]=useState()

        const shouldShowEditIcon =
        (props.user[0]?.user_type === "Super") &&
        (
          orderDetail[0]?.booking_date === null ||
          orderDetail[0]?.booking_date === '30-11-1899'||
          orderDetail[0]?.booking_date === '' ||
          orderDetail[0]?.booking_date === 'NA' ||
          orderDetail[0]?.booking_date === '1899-11-30T00:00:00.000Z'
        );
      
    const handleDateChange = (event) => {
        const selectedDate = event.target.value;
        setEditingDate(selectedDate);
        // alert(selectedDate)
      };
    
      const handleEditIconClick = () => {
        setShowDatePicker(true);
      };

      const editedDate = new Object();
      const handleEditDate=(e)=>{
        e.preventDefault();
        editedDate.awb_no=String(orderDetail[0].awb_no);
        editedDate.booking_date=String(editingDate);
        editedDate.id=String(orderDetail[0]?.id);
        editedDate.admin_id='1';
        setPostDate(editedDate);
        // console.log(editedDate)
        axios({
            method: "post",
            url: process.env.REACT_APP_CHANGE_DATE,
            data: postDate,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            setLoader(false);
            if (res) {
                Swal.fire({ title: 'success', text: res.msg, icon: 'success', })
                // console.log(res);
                 window.location.reload();
            }
            else {
                Swal.fire({ title: 'Try Again', text: res.msg, icon: 'error', })
            }
        }).catch((err) => {
            // errorHandling(err, "kmd,")
            setLoader(false);
        });
      }


      const shouldShowEditIcon2 =
        (props.user[0]?.user_type === "Super") &&
        (
          orderDetail[0]?.last_event_date === null ||
          orderDetail[0]?.last_event_date === '' ||
          orderDetail[0]?.last_event_date === 'NA' ||
          orderDetail[0]?.last_event_date === '30-11-1899'||
          orderDetail[0]?.last_event_date === '1899-11-30T00:00:00.000Z'
        );

        const handleDateChange2 = (event) => {
            const selectedDate = event.target.value;
            setEditingDate(selectedDate);
          };
      
          const handleEditIconClick2 = () => {
            setShowDatePicker2(true);
          };

          const editedDate2 = new Object();
      const handleEditDate2=(e)=>{
        e.preventDefault();
        editedDate2.awb_no=String(orderDetail[0].awb_no);
        editedDate2.booking_date=String(editingDate);
        editedDate2.id=String(orderDetail[0]?.id);
        editedDate2.admin_id='1';
        editedDate2.type='last_event_date';
        setPostDate2(editedDate2);
        axios({
            method: "post",
            url: process.env.REACT_APP_CHANGE_DATE,
            data: postDate2,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            setLoader(false);
            if (res) {
                Swal.fire({ title: 'success', text: res.msg, icon: 'success', })
                // console.log(res);
                 window.location.reload();
            }
            else {
                Swal.fire({ title: 'Try Again', text: res.msg, icon: 'error', })
            }
        }).catch((err) => {
            // errorHandling(err, "kmd,")
            setLoader(false);
        });
      }


      const shouldShowEditIcon3 =
        (props.user[0]?.user_type === "Super") &&
        (
          orderDetail[0]?.last_event_date === null ||
          orderDetail[0]?.last_event_date === '' ||
          orderDetail[0]?.last_event_date === 'NA' ||
          orderDetail[0]?.last_event_date === '30-11-1899'||
          orderDetail[0]?.last_event_date === '1899-11-30T00:00:00.000Z'
        );

        const handleDateChange3 = (event) => {
            const selectedDate = event.target.value;
            setEditingDate(selectedDate);
          };
      
          const handleEditIconClick3 = () => {
            setShowDatePicker2(true);
          };

          const editedDate3 = new Object();
      const handleEditDate3=(e)=>{
        e.preventDefault();
        editedDate3.awb_no=String(orderDetail[0].awb_no);
        editedDate3.booking_date=String(editingDate);
        editedDate3.id=String(orderDetail[0]?.id);
        editedDate3.admin_id='1';
        editedDate3.type='last_event_date';
        setPostDate3(editedDate3);
        axios({
            method: "post",
            url: process.env.REACT_APP_CHANGE_DATE,
            data: postDate3,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            setLoader(false);
            if (res) {
                Swal.fire({ title: 'success', text: res.msg, icon: 'success', })
                // console.log(res);
                 window.location.reload();
            }
            else {
                Swal.fire({ title: 'Try Again', text: res.msg, icon: 'error', })
            }
        }).catch((err) => {
            // errorHandling(err, "kmd,")
            setLoader(false);
        });
      }

          
      

    const orderTrack = () => {
        let data = { "order_id": orderId }
        axios({
            method: "post",
            url: process.env.REACT_APP_TRACK_ORDER,
            data: data,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            setLoader(false);
            if (res.data !== undefined && res.data.fatal !== true) {
                if (parseInt(res.data.errno) > 0) {
                    if (res.data.sqlMessage !== undefined) {
                        Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
                    }
                } else {
                    if (res.fatal !== true && res.error !== true) {
                        setOrderTracking(res.data.data)
                    }
                    else {
                        Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
                    }
                }
            }
        }).catch((err) => {
            errorHandling(err, "")
            setLoader(false);
        });
    }
    useEffect(() => {
        setLoader(true);
        orderTrack();
        let data = {}
        if (props.user[0].user_type === "Super") {
            data = { "order_id": orderId, "admin_id": 1 }
        } else {
            data = { "order_id": orderId, "merchant_id": props.user[0].id }
        }
        axios({
            method: "post",
            url: process.env.REACT_APP_ORDER_DETAILS,
            data: data,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            setLoader(false)
            if (res.data !== undefined && res.data.fatal !== true) {
                if (parseInt(res.data.errno) > 0) {
                    if (res.data.sqlMessage !== undefined) {
                        Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
                    }
                } else {
                    setOrderDetail(res.data.orderDetail);
                    setWalkInCustDetails(res.data.walkIngCustDetails[0]);
                    setRemittDetails(res.data.RemittDetails);
                    setFreightDetails(res.data.FreightDetails);
                }
            }
        }).catch((err) => {
            setLoader(false);
            errorHandling(err, "")
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [])

    let history = useHistory();
    const goToPreviousPath = () => {
        if (checkUndeNullBlank(history.location.key)) {
            history.goBack();
        } else {
            history.push('/shipments');
        }
    }

    // copy text
    const copied = (text) => {
        let decision = copyToClipBoard(text);
        decision.then(function (result) {
            if (result === true) {
                toast("Copied!", {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }
    const singleLableDownload = (data) => {
        data = {}
        data.awb_no = checkData(orderDetail) && checkUndeNullBlank(orderDetail[0].awb_no) ? orderDetail[0].awb_no : ''
        labelDownloadCommon(data);
    }
    const labelDownloadCommon = (data) => {
        setLoader(true);
        if (props.user[0].user_type === "Super") {
            data.admin_id = props.user[0].id
        } else {
            data.merchant_id = props.user[0].id
            data.logo = props.user[0].logo
        }
        axios({
            method: "post",
            url: `${process.env.REACT_APP_DOWNLOAD_ADDRESS_LABEL}`,
            data: data,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            if (res.data.is_generate === 1) {
                setTimeout(function () {
                    setLoader(false);
                    history.push("/my-downloads");
                }, download_delay);
            }
            else {
                setLoader(false);
                Swal.fire({ title: 'Error', text: res.msg, icon: 'error', });
            }
        }).catch((err) => {
            errorHandling(err, "")
            setLoader(false);
        });
    }
    const reCalculate = () => {
        setLoader(true);
        let data = {}

        data.order_ids = checkUndeNullBlank(orderId) ? orderId : "";

        axios({
            method: "post",
            url: `${process.env.REACT_APP_FREIGHT_RECALC}`,
            data: data,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            setLoader(false);
            if (res.data.error === false) {
                Swal.fire({ title: 'Success', text: res.data.msg, icon: 'success' })
            }
        }).catch((err) => {
            errorHandling(err, "")
            setLoader(false);
        });

    }
    const placeBulkOrder = () => {
        let orderIds = checkUndeNullBlank(orderId) ? [orderId] : '';
        let orderStatusId = (checkData(orderDetail) && checkUndeNullBlank(orderDetail[0].order_status_id)) ? orderDetail[0].order_status_id : ''
        let data = { "order_ids": orderIds, "order_status_id": orderStatusId }
        if (props.user[0].user_type === "Super") {
            data.admin_id = props.user[0].id
        } else {
            data.merchant_id = props.user[0].id
            data.merchant_wallet_balance = props.user[0].wallet_balance
        }
        if (checkUndeNullBlank(orderId)) {
            setLoader(true);
            axios({
                method: "post",
                url: `${process.env.REACT_APP_PLACE_BULK_ORDER}`,
                data: data,
                headers: { "token": window.btoa(baseString) }
            }).then((res) => {
                setLoader(false);
                if (res.data.error !== true && res.data.fatal !== true) {
                    Swal.fire({ icon: 'success', text: res.data.msg, title: 'Success', allowOutsideClick: false, allowEscapeKey: false }).then(okay => {
                        if (okay) {
                            history.push("/shipments");
                        }
                    });
                } else {
                    Swal.fire({ icon: 'error', text: res.data.msg, title: 'Error' });
                }
            }).catch((err) => {
                setLoader(false);
                errorHandling(err, "")
            })
        } else {
            Swal.fire({ icon: 'warning', title: 'Warning', text: 'Order Id Not Found' })
        }
    }
    let length = checkData(orderDetail) && checkUndeNullBlank(orderDetail[0].length_in_cms) ? orderDetail[0].length_in_cms : 0
    let breath = checkData(orderDetail) && checkUndeNullBlank(orderDetail[0].breadth_in_cms) ? orderDetail[0].breadth_in_cms : 0
    let height = checkData(orderDetail) && checkUndeNullBlank(orderDetail[0].height_in_cms) ? orderDetail[0].height_in_cms : 0
    let dimension = length * breath * height
    let volumetricWeight = dimension / volumetricFormula
    // let totalFreightAmount = []
    const totalFreightAmount = checkData(freightDetails) ? freightDetails.map(item => parseFloat(item.total_amt)).reduce((prev, curr) => prev + curr, 0) : 0;
    //const totalChargedWeight = checkData(freightDetails) ? freightDetails.map(item => parseFloat(item.charged_weight)).reduce((prev, curr) => prev + curr, 0) : 0;
    let totalChargedWeight = 0;
    if (checkData(freightDetails)) {
        freightDetails.map(item => {
            if (parseInt(item.is_suplimentary) === 1) {
                totalChargedWeight += parseFloat(item.charged_weight);
            } else {
                totalChargedWeight = parseFloat(item.charged_weight);
            }
            return true;
        })
    }

    const { register: adminRaise, handleSubmit: adminRaiseSubmit, formState: { errors: errors2 } } = useForm();
    const onAdminRaiseSubmit = (data) => {

    }
    const { register: merchantRaise, handleSubmit: merchantRaiseSubmit, formState: { errors: errors3 } } = useForm();
    const onMerchantRaiseSubmit = (data) => {
        if (checkUndeNullBlank(addDisputDate)) {
            alert("ok");
        }
    }


    return (
        <div className="app-content content overflow-hidden">
            {loader && <div className="formLoader"><Spinner /></div>}
            {checkData(orderDetail) ?

                <div className="OrderDetail content-wrapper ">
                    <Row>
                        <Col md={8}>
                            <Card className="p-0 my-3 shadow border-0">
                                <Card className="border-0">
                                    <CardHeader className="page-header d-flex justify-content-between flex-wrap">
                                        <CardTitle className="page-title">Order Detail</CardTitle>
                                        <div className="card-header-right d-flex flex-wrap">
                                            {checkUndeNullBlank(props.user[0].user_type) && props.user[0].user_type === "Merchant" && checkUndeNullBlank(props.user[0].ship_status) && parseInt(props.user[0].ship_status) === 1 && checkData(orderDetail) && !checkUndeNullBlank(orderDetail[0].awb_no) &&
                                                <>
                                                    <div className="btn-text btn-light mr-2" onClick={placeBulkOrder}><span className="icon mr-2"><FontAwesomeIcon icon={faCartPlus} /></span><span>Ship Orders</span></div>
                                                </>}
                                            <div className="btn-text btn-light d-none" onClick={() => { if (props.user[0].user_type === "Super") { toggleAdminRaise() } else { toggleMerchantRaise() } }}><span>Raise a Dispute</span></div>
                                            <div className="btn-text btn-light" onClick={() => singleLableDownload()}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Download Address Label</span></div>
                                            <div onClick={goToPreviousPath} className="btn-text btn-light"><span className="icon"><ArrowLeft /></span><span>Back</span></div>

                                            {orderDetail[0].order_status_id === 10 &&
                                            <>
                                             {/* <a target='_blank' href={`${process.env.REACT_APP_POD_BASE_URL}${orderDetail[0].awb_no}.pdf`} className="btn-text btn-light" >Download POD</a> */}
                                            </>}
                                        </div>

                                        <Modal isOpen={adminRaiseModal} toggle={toggleAdminRaise} scrollable={true}>
                                            <ModalHeader toggle={toggleAdminRaise}>Raise a Dispute</ModalHeader>
                                            <ModalBody>
                                                <Form onSubmit={adminRaiseSubmit(onAdminRaiseSubmit)}>
                                                    <Row className="mx-0 justify-content-start addModal">
                                                        <Col sm={12}>
                                                            <FormGroup>
                                                                <Label for="dispute_type">Dispute Type<span className="text-danger">*</span></Label>
                                                                <div className="position-relative">
                                                                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                                                    <select {...adminRaise("dispute_type", { required: 'Please Select Dispute Type' })} className="form-control-sm form-control" id="dispute_type">
                                                                        <option value="">Select Dispute Type</option>
                                                                        <option value="Refund">Refund</option>
                                                                    </select>
                                                                </div>
                                                                {errors2.dispute_type && <span className="text-danger d-block error">{errors2.dispute_type.message}</span>}
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={12}>
                                                            <FormGroup>
                                                                <Label for="remark">Remark<span className="text-danger">*</span></Label>
                                                                <textarea rows={3} {...adminRaise("remark", { required: 'Please Enter Remark', maxLength: { value: 255, message: "Please Enter Maximum 255 characters" } })} className="form-control-sm form-control" id="remark" />
                                                                {errors2.remark && <span className="text-danger d-block error">{errors2.remark.message}</span>}
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={12} className="text-right">
                                                            <Button className="ctm-btn btn-sm" color="primary">Save</Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </ModalBody>
                                        </Modal>
                                        <Modal isOpen={merchantRaiseModal} toggle={toggleMerchantRaise} scrollable={true}>
                                            <ModalHeader toggle={toggleMerchantRaise}>Raise a Dispute</ModalHeader>
                                            <ModalBody>
                                                <Form onSubmit={merchantRaiseSubmit(onMerchantRaiseSubmit)}>
                                                    <Row className="mx-0 justify-content-start addModal">
                                                        <Col sm={12}>
                                                            <FormGroup>
                                                                <Label for="payment_method">Payment Method<span className="text-danger">*</span></Label>
                                                                <div className="position-relative">
                                                                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                                                    <select {...merchantRaise("payment_method", { required: 'Please Select Payment Method' })} className="form-control-sm form-control" id="payment_method">
                                                                        <option value="">Select Payment Method</option>
                                                                    </select>
                                                                </div>
                                                                {errors3.payment_method && <span className="text-danger d-block error">{errors3.payment_method.message}</span>}
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={12}>
                                                            <FormGroup>
                                                                <Label for="type">Type<span className="text-danger">*</span></Label>
                                                                <div className="position-relative">
                                                                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                                                    <select {...merchantRaise("type", { required: 'Please Select Type' })} className="form-control-sm form-control" id="type">
                                                                        <option value="">Select Type</option>
                                                                        <option value="Refund">Refund</option>
                                                                    </select>
                                                                </div>
                                                                {errors3.type && <span className="text-danger d-block error">{errors3.type.message}</span>}
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={12}>
                                                            <FormGroup>
                                                                <Label for="bank_ref_no">Bank Ref. No.<span className="text-danger">*</span></Label>
                                                                <input {...merchantRaise("bank_ref_no", {
                                                                    required: 'Please Enter Bank Ref. No',
                                                                    pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Bank Ref. No' }
                                                                })} className="form-control-sm form-control" id="bank_ref_no" />
                                                                {errors3.bank_ref_no && <span className="text-danger d-block error">{errors3.bank_ref_no.message}</span>}
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={12}>
                                                            <FormGroup>
                                                                <Label for="trans_amount">Transaction Amount<span className="text-danger">*</span></Label>
                                                                <input {...merchantRaise("trans_amount", {
                                                                    required: 'Please Enter Transaction Amount',
                                                                    pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Transaction Amount' }
                                                                })} className="form-control-sm form-control" id="trans_amount" />
                                                                {errors3.trans_amount && <span className="text-danger d-block error">{errors3.trans_amount.message}</span>}
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={12}>
                                                            <FormGroup>
                                                                <Label for="trans_date">Transaction Date<span className="text-danger">*</span></Label>
                                                                <DatePicker defaultValue={addDisputDate} placeholder="Date" placement="topStart" onChange={date => { setAddDisputDate(date) }} format="dd-MM-yyyy" />
                                                                {addDisputDate == null && <span className="text-danger d-block error">Please Select Date</span>}
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={12}>
                                                            <FormGroup>
                                                                <Label for="remark">Remark<span className="text-danger">*</span></Label>
                                                                <textarea rows={3} {...merchantRaise("remark", { required: 'Please Enter Remark', maxLength: { value: 255, message: "Please Enter Maximum 255 characters" } })} className="form-control-sm form-control" id="remark" />
                                                                {errors3.remark && <span className="text-danger d-block error">{errors3.remark.message}</span>}
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={12} className="text-right">
                                                            <Button className="ctm-btn btn-sm" color="primary">Save</Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </ModalBody>
                                        </Modal>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="d-flex justify-content-between flex-wrap mb-4">
                                            <div>
                                                {orderDetail[0].awb_no &&
                                                    <>
                                                        <CardTitle className="mb-0">AWB No - <span className="font-weight-500">{orderDetail[0].awb_no ? orderDetail[0].awb_no : "NA"}</span>
                                                            <span onClick={() => copied(orderDetail[0].awb_no ? orderDetail[0].awb_no : "NA")} className="ml-1 icon-sm copy-btn" id={`copy${orderDetail[0].id}`}>
                                                                <Copy />
                                                                <UncontrolledTooltip placement="bottom" target={`copy${orderDetail[0].id}`}>
                                                                    Copy
                                                                </UncontrolledTooltip>
                                                            </span>
                                                        </CardTitle>
                                                    </>}
                                            </div>
                                            <ToastContainer style={{ width: "200px" }} />
                                            <div>
                                                <CardTitle className="mb-0">Order No - <span className="font-weight-500">{orderDetail[0].order_no ? orderDetail[0].order_no : "NA"}</span></CardTitle>
                                            </div>
                                        </div>
                                        <Row>
                                            <Col sm={6} className="mb-4 mb-sm-0">
                                                <CardHeader className="mb-2 bg-card-header">
                                                    <CardTitle className="mb-0"> From </CardTitle>
                                                </CardHeader>
                                                <div>
                                                    <CardSubtitle tag="h6" className="mb-0">
                                                      {((orderDetail[0].movement_type_id) == 1) && <>
                                                            {orderDetail[0].MerchantPickupPointName}
                                                          </>
                                                        }

                                                        {((orderDetail[0].movement_type_id) == 2) && <>
                                                            {orderDetail[0].customer_name}
                                                          </>
                                                        }      
                                                    </CardSubtitle>
                                                    <p className="my-0 card-text d-inline-block">
                                                        
                                                    {((orderDetail[0].movement_type_id) == 1) && <>
                                                        <span>{orderDetail[0].pickup_address_1}</span><br />
                                                        <span>{orderDetail[0].pickup_city_name} </span>,
                                                        <span>{states[orderDetail[0].pickup_state_id]}</span>,
                                                        <span>{orderDetail[0].pickup_pincode}</span>
                                                        </>
                                                    }

                                                    {((orderDetail[0].movement_type_id) == 2) && <>
                                                        <span>{orderDetail[0].customer_mobile}</span><br />
                                                        <span>{orderDetail[0].customer_address_1}</span><br />
                                                        {checkUndeNullBlank(orderDetail[0].customer_address_2) && <>
                                                            <span>{orderDetail[0].customer_address_2}</span><br /></>}
                                                        <span>{orderDetail[0].customer_city}, </span>
                                                        <span>{orderDetail[0].customer_state},</span>
                                                        <span>{orderDetail[0].destination_pincode}</span>
                                                        </>
                                                    }

                                                    </p>
                                                </div>
                                            </Col>
                                            <Col sm={6}>
                                                <CardHeader className="mb-2 bg-card-header">
                                                    <CardTitle className="mb-0">To</CardTitle>
                                                </CardHeader>
                                                <div>
                                                    <div className="d-flex align-items-center flex-wrap">
                                                        <CardSubtitle tag="h6" className="mb-0">
                                                        {((orderDetail[0].movement_type_id) == 2) && <>
                                                            {orderDetail[0].MerchantPickupPointName}
                                                          </>
                                                        }

                                                        {((orderDetail[0].movement_type_id) == 1) && <>
                                                            {orderDetail[0].customer_name}
                                                          </>
                                                        }  
                                                        </CardSubtitle>,</div>
                                                    <p className="my-0 card-text">

                                                    {((orderDetail[0].movement_type_id) == 2) && <>
                                                        <span>{orderDetail[0].pickup_address_1}</span><br />
                                                        <span>{orderDetail[0].pickup_city_name} </span>,
                                                        <span>{states[orderDetail[0].pickup_state_id]}</span>,
                                                        <span>{orderDetail[0].pickup_pincode}</span>
                                                        </>
                                                    }

                                                    {((orderDetail[0].movement_type_id) == 1) && <>
                                                        <span>{orderDetail[0].customer_mobile}</span><br />
                                                        <span>{orderDetail[0].customer_address_1}</span><br />
                                                        {checkUndeNullBlank(orderDetail[0].customer_address_2) && <>
                                                            <span>{orderDetail[0].customer_address_2}</span><br /></>}
                                                        <span>{orderDetail[0].customer_city}, </span>
                                                        <span>{orderDetail[0].customer_state},</span>
                                                        <span>{orderDetail[0].destination_pincode}</span>
                                                        </>
                                                    }

                                                        
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col sm={6} className="mb-4 mb-sm-0">
                                                <CardHeader className="mb-2 bg-card-header">
                                                    <CardTitle className="mb-0">Shipping Details</CardTitle>
                                                </CardHeader>
                                                <Row className="align-items-center mb-1">
                                                    <Col xs={6} sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Courier</CardSubtitle>
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        <div className="d-flex align-items-center">
                                                            {checkUndeNullBlank(orderDetail[0].CourierLogo) ?
                                                                <img className="img-fluid" src={process.env.REACT_APP_IMAGE_COURIER + orderDetail[0].CourierLogo} alt={orderDetail[0].CourierName} width="60" />
                                                                : 'NA'}
                                                            <p className="card-text mb-0">{orderDetail[0].CourierName}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center mb-1">
                                                    <Col xs={6} sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Declared Weight</CardSubtitle>
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        <p className="card-text mb-0">{orderDetail[0].product_weight_in_kgs + ' Kg'}</p>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center mb-1">
                                                    <Col xs={6} sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Dimension</CardSubtitle>
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        <p className="card-text mb-0">{orderDetail[0].length_in_cms + ' cm'} X {orderDetail[0].breadth_in_cms + ' cm'} X {orderDetail[0].height_in_cms + ' cm'}</p>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center mb-1">
                                                    <Col xs={6} sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Volumetric Weight</CardSubtitle>
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        <p className="card-text mb-0">{checkUndeNullBlank(orderDetail[0].product_vol_weight_in_kgs) ? orderDetail[0].product_vol_weight_in_kgs + ' Kg' : volumetricWeight + ' Kg'}</p>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center mb-1">
                                                    <Col xs={6} sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Delivery Type</CardSubtitle>
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        <p className="card-text mb-0">{delivery_type_id[orderDetail[0].delivery_type_id]} - {orderDetail[0].delivery_type_id === 1 && '₹ ' + orderDetail[0].product_cod_value}</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={6}>
                                                <CardHeader className="mb-2 bg-card-header">
                                                    <CardTitle className="mb-0">Order Details</CardTitle>
                                                </CardHeader>
                                                <Row className="align-items-center mb-1">
                                                    <Col xs={6} sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Order Date</CardSubtitle>
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        <p className="card-text mb-0">{changeIntoDate(orderDetail[0].created_at)}</p>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center mb-1">
                                                    <Col xs={6} sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Booking Date</CardSubtitle>
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        {/* {console.log(orderDetail[0]?.booking_date , 'msn')} */}
                                                        <p className="card-text mb-0">{orderDetail[0]?.booking_date !== null && orderDetail[0]?.booking_date !== undefined ? changeIntoDate(orderDetail[0].booking_date) : 'NA'}{shouldShowEditIcon &&(
                                                                <FontAwesomeIcon style={{cursor:'pointer'}} className='ml-3' icon={faEdit} onClick={handleEditIconClick} />
                                                            )}
                                                        </p>
                                                            {showDatePicker && (
                                                            <Form className='d-flex align-items-center'>
                                                                <input className='form-control mt-1' type="date" value={editingDate} onChange={handleDateChange} />
                                                                <button onClick={handleEditDate} style={{padding:'4px 10px'}} className="btn btn-primary ml-2 mt-1" color="primary">Save</button>
                                                            </Form>
                                                            )}
                                                    </Col>
                                                </Row>
                                                {checkUndeNullBlank(orderDetail[0].merchantBrandName) &&
                                                    <Row className="align-items-center mb-1">
                                                        <Col xs={6} sm={6}>
                                                            <CardSubtitle tag="h6" className="mb-0">Brand Name</CardSubtitle>
                                                        </Col>
                                                        <Col xs={6} sm={6}>
                                                            <p className="card-text mb-0">{orderDetail[0].merchantBrandName}</p>
                                                        </Col>
                                                    </Row>
                                                }
                                                {orderDetail[0].order_status_id === 10 ? <Row className="align-items-center mb-1">
                                                    <Col xs={6} sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Delivered Date</CardSubtitle>
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        <p className="card-text mb-0">{orderDetail[0].last_event_date !== null && orderDetail[0].last_event_date !== undefined ? changeIntoDate(orderDetail[0].last_event_date) : 'NA'}{shouldShowEditIcon2 &&(
                                                                <FontAwesomeIcon style={{cursor:'pointer'}} className='ml-3' icon={faEdit} onClick={handleEditIconClick2} />
                                                            )}
                                                        </p>
                                                        {showDatePicker2 && (
                                                            <Form className='d-flex align-items-center'>
                                                                <input className='form-control mt-1' type="date" value={editingDate} onChange={handleDateChange2} />
                                                                <button onClick={handleEditDate2} style={{padding:'4px 10px'}} className="btn btn-primary ml-2 mt-1" color="primary">Save</button>
                                                            </Form>
                                                            )}
                                                    </Col>
                                                </Row> : orderDetail[0].order_status_id === 13 ? <Row className="align-items-center mb-1">
                                                    <Col xs={6} sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">{props.user[0].user_type === "Super" && orderDetail[0].order_status_id === 13 && orderDetail[0].is_lost === 1 ? 'Lost Date' : 'RTO Date'}
                                                        </CardSubtitle>
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        <p className="card-text mb-0">{orderDetail[0].last_event_date !== null && orderDetail[0].last_event_date !== undefined ? changeIntoDate(orderDetail[0].last_event_date) : 'NA'}
                                                        {shouldShowEditIcon2 &&(
                                                                <FontAwesomeIcon style={{cursor:'pointer'}} className='ml-3' icon={faEdit} onClick={handleEditIconClick2} />
                                                            )}
                                                        {showDatePicker2 && (
                                                            <Form className='d-flex align-items-center'>
                                                                <input className='form-control mt-1' type="date" value={editingDate} onChange={handleDateChange2} />
                                                                <button onClick={handleEditDate2} style={{padding:'4px 10px'}} className="btn btn-primary ml-2 mt-1" color="primary">Save</button>
                                                            </Form>
                                                            )}
                                                            
                                                        </p>
                                                    </Col>
                                                </Row> : <Row className="align-items-center mb-1">
                                                    <Col xs={6} sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Last Event Date</CardSubtitle>
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        <p className="card-text mb-0">{orderDetail[0].last_event_date !== null && orderDetail[0].last_event_date !== undefined ? changeIntoDate(orderDetail[0].last_event_date) : 'NA'}</p>
                                                    </Col>
                                                </Row>}
                                                
                                                    <Row className="align-items-center mb-1">
                                                        <Col xs={6} sm={6}>
                                                            <CardSubtitle tag="h6" className="mb-0">Service</CardSubtitle>
                                                        </Col>
                                                        <Col xs={6} sm={6}>
                                                            <p className="card-text mb-0">{service[orderDetail[0].service_id]}</p>
                                                        </Col>
                                                    </Row>
                                                
                                                 <Row className="align-items-center mb-1">
                                                        <Col xs={6} sm={6}>
                                                            <CardSubtitle tag="h6" className="mb-0">Movement</CardSubtitle>
                                                        </Col>
                                                        <Col xs={6} sm={6}>
                                                            <p className="card-text mb-0">{movement_type_id[orderDetail[0].movement_type_id]}</p>
                                                        </Col>
                                                    </Row>


                                                <Row className="align-items-center mb-1">
                                                    <Col xs={6} sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">System Order Number</CardSubtitle>
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        <p className="card-text mb-0">{orderDetail[0].system_order_no}</p>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center mb-1">
                                                    <Col xs={6} sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Eway Bill Number</CardSubtitle>
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        <p className="card-text mb-0">{orderDetail[0].ewayNumber ? orderDetail[0].ewayNumber : 'NA'}</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Table responsive className="text-left custom-table">
                                            <thead>
                                                <tr>
                                                    <th className="text-nowrap">Product</th>
                                                    <th className="text-nowrap">Quantity</th>
                                                    <th className="text-nowrap">Invoice Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="col-6">
                                                        <div className="" id={`product${orderDetail[0].id}`}>{orderDetail[0].product_name}</div>
                                                        <UncontrolledTooltip placement="top" target={`product${orderDetail[0].id}`}>
                                                            {orderDetail[0].product_name}
                                                        </UncontrolledTooltip>
                                                    </td>
                                                    <td>{orderDetail[0].product_qty}</td>
                                                    <td>₹{orderDetail[0].product_invoice_value}</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                    </CardBody>

                                </Card>
                            </Card>

                            <Row>
                                {walkInCustDetails !== undefined && walkInCustDetails !== null && Object.entries(walkInCustDetails).length > 0 ?
                                    <Col sm={12}>
                                        <Card className="my-3 shadow border-0">
                                            <CardBody>
                                                <CardHeader className="px-0"><CardTitle>Walk In Customer Details</CardTitle></CardHeader>
                                                <Row>
                                                    <Col sm={6}>
                                                        <Row className="align-items-center mb-1">
                                                            <Col sm={6}>
                                                                <CardSubtitle tag="h6" className="mb-0">Mobile Number</CardSubtitle>
                                                            </Col>
                                                            <Col sm={6}>
                                                                <p className="card-text mb-0">{checkData(walkInCustDetails) ? walkInCustDetails.mobile : 'NA'}</p>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <Row className="align-items-center mb-1">
                                                            <Col sm={6}>
                                                                <CardSubtitle tag="h6" className="mb-0">Full Name</CardSubtitle>
                                                            </Col>
                                                            <Col sm={6}>
                                                                <p className="card-text mb-0">{checkData(walkInCustDetails) ? walkInCustDetails.name : 'NA'}</p>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <Row className="align-items-center mb-1">
                                                            <Col sm={6}>
                                                                <CardSubtitle tag="h6" className="mb-0">Email</CardSubtitle>
                                                            </Col>
                                                            <Col sm={6}>
                                                                <p className="card-text mb-0">{checkData(walkInCustDetails) ? walkInCustDetails.email : 'NA'}</p>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <Row className="align-items-center mb-1">
                                                            <Col sm={6}>
                                                                <CardSubtitle tag="h6" className="mb-0">Kyc ID</CardSubtitle>
                                                            </Col>
                                                            <Col sm={6}>
                                                                <p className="card-text mb-0">{kyc_type_id[checkData(walkInCustDetails) ? walkInCustDetails.kyc_type_id : 'NA']}</p>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <Row className="align-items-center mb-1">
                                                            <Col sm={6}>
                                                                <CardSubtitle tag="h6" className="mb-0">Kyc Number</CardSubtitle>
                                                            </Col>
                                                            <Col sm={6}>
                                                                <p className="card-text mb-0">{checkData(walkInCustDetails) ? walkInCustDetails.kyc_no : 'NA'}</p>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col> : ""}

                                {checkData(remittDetails) &&
                                    <Col sm={12}>
                                        <Card className="my-3 shadow border-0 remittance-detail">
                                            <CardBody>
                                                <CardHeader className="px-0 d-flex justify-content-between">
                                                    <CardTitle>Remittance Details</CardTitle>
                                                    <CardTitle tag="h4">₹{checkData(orderDetail) && checkUndeNullBlank(orderDetail[0].product_cod_value) ? orderDetail[0].product_cod_value : 'NA'}</CardTitle>
                                                </CardHeader>
                                                <ListGroup>
                                                    {remittDetails.map((item, index) => {
                                                        let dateTitle = checkUndeNullBlank(item.adjust_date) ? 'Adjust Date' : 'Trans. Date'
                                                        let date = checkUndeNullBlank(item.adjust_date) ? changeIntoDateYmd(item.adjust_date) : changeIntoDateYmd(item.trans_date)
                                                        let refTitle = checkUndeNullBlank(item.adjust_ref_no) ? 'Adjust ID' : 'Trans. ID'
                                                        let refNo = checkUndeNullBlank(item.adjust_ref_no) ? item.adjust_ref_no : item.bank_ref_no
                                                        let amountTitle = checkUndeNullBlank(item.adjust_amt) ? 'Adjust Amount' : 'Trans. Amount'
                                                        let amount = checkUndeNullBlank(item.adjust_amt) ? item.adjust_amt : item.trans_amt
                                                        return (
                                                            <ListGroupItem className={`border-0 border-bottom px-0 mb-2 ${item.cod_value < 0 ? 'text-danger' : ''}`}>
                                                                <Row>
                                                                    <Col sm={2}><CardSubtitle tag="h6">{dateTitle}</CardSubtitle></Col>
                                                                    <Col sm={2}>{checkUndeNullBlank(date) ? date : 'NA'}</Col>
                                                                    <Col sm={2}><CardSubtitle tag="h6">{refTitle}</CardSubtitle></Col>
                                                                    <Col sm={2}>{checkUndeNullBlank(refNo) ? refNo : 'NA'}</Col>
                                                                    <Col sm={2} className="px-0"><CardSubtitle tag="h6">{item.cod_value < 0 ? 'Reversal Amount' : amountTitle}</CardSubtitle></Col>
                                                                    <Col sm={2}>{checkUndeNullBlank(amount) && item.cod_value >= 0 ? amount : item.cod_value}</Col>
                                                                </Row>
                                                            </ListGroupItem>
                                                        )
                                                    })}
                                                </ListGroup>
                                            </CardBody>
                                        </Card>
                                    </Col>}
                                {checkData(freightDetails) &&
                                    <Col sm={12}>
                                        <Card className="my-3 shadow border-0 freight-detail">
                                            <CardBody>
                                                <CardHeader className="px-0 d-flex justify-content-between">
                                                    <CardTitle>Freight Details</CardTitle>
                                                    <CardTitle tag="h4">₹ {checkUndeNullBlank(totalFreightAmount) ? totalFreightAmount.toFixed(2) : 0}</CardTitle>
                                                </CardHeader>
                                                {freightDetails.map((item, index) => {
                                                    return (
                                                        <ListGroup>
                                                            <ListGroupItem className="border-0 border-bottom px-0 mb-2">
                                                                <Row>
                                                                    <Col sm={3}>
                                                                        <div className="d-flex align-items-center justify-content-between">
                                                                            <div><CardSubtitle tag="h6">Forward</CardSubtitle></div>
                                                                            <div>₹ {item.forward_charge || '0'}</div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm={3} className="border-left border-right">
                                                                        <div className="d-flex align-items-center justify-content-between">
                                                                            <div><CardSubtitle tag="h6">RTO</CardSubtitle></div>
                                                                            <div>₹ {item.rto_charge || '0'}</div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm={3} className="border-left border-right">
                                                                        <div className="d-flex align-items-center justify-content-between">
                                                                            <div><CardSubtitle tag="h6">COD</CardSubtitle></div>
                                                                            <div>₹ {item.cod_charge || '0'}</div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm={3}>
                                                                        <div className="d-flex align-items-center justify-content-between">
                                                                            <div><CardSubtitle tag="h6">Tax</CardSubtitle></div>
                                                                            <div>₹ {item.tax_amt || '0'}</div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </ListGroupItem>
                                                            <ListGroupItem className="border-0 px-0 mb-2">
                                                                <Row className="align-items-center">
                                                                    <Col sm={2} className="pr-0"><CardSubtitle tag="h6">Zone</CardSubtitle></Col>
                                                                    <Col sm={2} className="border-right">{checkUndeNullBlank(item) && zone_category_id[item.zone_category_id]}</Col>
                                                                    <Col sm={2} className="pr-0"><CardSubtitle tag="h6">{checkUndeNullBlank(item.is_suplimentary) ? 'Excess Weight' : 'Charged Weight'}</CardSubtitle></Col>
                                                                    <Col sm={2} className="border-right">{item.charged_weight} Kg[s]</Col>
                                                                    <Col sm={2} className="pr-0"><CardSubtitle tag="h6">Charged On</CardSubtitle></Col>
                                                                    <Col sm={2}>{changeIntoDate(item.created_at)}</Col>
                                                                </Row>
                                                            </ListGroupItem>
                                                        </ListGroup>
                                                    )
                                                })}
                                                <div className="d-flex justify-content-between border-top pt-2">
                                                    {props.user[0].user_type === "Super" &&
                                                        <div>
                                                            {/* <Button className="ctm-btn btn-sm mx-2" color="primary" onClick={reCalculate}>Freight Recalculate</Button> */}
                                                        </div>}
                                                    {checkUndeNullBlank(totalChargedWeight) && <CardText tag="p" className="text-right ml-auto"><span>Total Charged Weight : </span> <span class="font-weight-bold">{orderDetail[0].chargeable_weight_in_kgs} Kg[s]</span></CardText>}
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>}
                            </Row>

                        </Col>
                        <Col md={4}>
                            <Card className="p-0 my-3 shadow border-0">
                                <TrackOrder orderTrack={orderTrack} orderTracking={orderTracking} lastStatus={checkData(orderDetail) && checkUndeNullBlank(orderDetail[0].order_status_id) && orderDetail[0].order_status_id} statusRecheckOrderId={[orderId]} user={props.user} />
                            </Card>
                        </Col>
                    </Row>
                </div>
                : loader ? <div className="w-100 text-center"><Spinner /></div> : <NodataFound />}
        </div>
    );
}

export default OrderDetail;