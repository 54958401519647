import React, { useState, useEffect } from 'react';
import { Row, Col, Label, Form, FormGroup, Input, CardTitle, Collapse, Card, CardBody, Button } from 'reactstrap';
import { faWallet, faTimes, faBars, faDownload, faHistory, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import customIcon from '../../../assets/img/custom-icon.png';
import economicalIcon from '../../../assets/img/economical-icon.png';
import performanceIcon from '../../../assets/img/performance-icon.png';
import CourierPriority from '../CourierPriority';
import axios from 'axios';
import Swal from 'sweetalert2';
import { errorHandling } from '../../../utlis';
import { baseString } from '../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CourierSetting = (props) => {

  const [isCustom, setIsCustom] = useState(true);
  const [serviceType, setServiceType] = useState("1,7");
  const [shipMethod, setShipMethod] = useState(1);
  const toggleCutom = () => setIsCustom(!isCustom);
  useEffect(() => {
    let data = { "merchant_id": props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ? props.user[0].id : '', "setting_id": '2', }
   
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_COURIERSETTING_VIEW}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          if (res.data.error === false) {
            setShipMethod(res.data[0].setting_value)
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "reload");
    });
  })

  const [selectedValue, setSelectedValue] = useState(""); // State to store selected value

  // Function to handle change in select value
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const submitCourierSetting = () => {
    // props.setLoader(true);
    // let data = {"merchant_id":props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ?props.user[0].id:'', "setting_id":'2', "setting_value" : shipMethod}
    //     axios.post(`${process.env.REACT_APP_MERCHANT_UPDATE_COURIERPRIORITY}`, data)
    //     .then((res) => {
    //       props.setLoader(false);
    //       if(res.data !== undefined && res.data.fatal !== true){
    //         if(parseInt(res.data.errno)>0){
    //           if(res.data.sqlMessage!==undefined){
    //             Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
    //           }
    //         }else{
    //           if(res.data.error === false){
    //             Swal.fire({title:'Successful', text:res.data.msg, icon:'success'})
    //           }else{
    //             Swal.fire({title:'Error', text:res.data.msg, icon:'error'})
    //           }
    //         }
    //       }
    //     }).catch((err) => {
    //       props.setLoader(false);
    //       Swal.fire({icon:'error',text:err,title:'Error'});
    //     });
  }
  return (
    <Form>
      <div className="sub-page-header">
        <CardTitle tag="h4" className="sub-page-title">Courier Setting</CardTitle>
      </div>
      <Row>
        <Col sm={12}>
          <Row>
            <Col sm={4} className="mb-4 mb-sm-0">
              <Card className="font-weight-500">
                <CardBody className="text-center">
                  <div className="pb-2"><img src={customIcon} style={{ height: "100px" }} alt="custom" /></div>
                  {/* <FormGroup check >
                                <Label check className="font-weight-500">
                                    <Input type="radio" name="cep_name" onClick={() => {toggleCutom(); setShipMethod(1);}} defaultChecked/>
                                    Custom
                                </Label>
                          </FormGroup> */}
                  <FormGroup className="my-2">
                    <div className="form-check2">
                      <Input type="radio" id="custom" name="cep_name" onClick={() => { toggleCutom(); setShipMethod(1); }} defaultChecked />
                      <Label check for="custom">
                        Custom
                      </Label>
                    </div>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
            <Col sm={4} className="mb-4 mb-sm-0">
              <Card className="font-weight-500">
                <CardBody className="text-center">
                  <div className="pb-2"><img src={economicalIcon} style={{ height: "100px" }} alt="economical" /></div>
                  <FormGroup className="my-2">
                    <div className="form-check2">
                      <Input type="radio" id="eco" name="cep_name" onClick={() => { setIsCustom(false); setShipMethod('2') }} />
                      <Label check for="eco">
                        Economical
                      </Label>
                    </div>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
            <Col sm={4}>
              <Card className="font-weight-500">
                <CardBody className="text-center">
                  <div className="pb-2"><img src={performanceIcon} style={{ height: "100px" }} alt="performance" /></div>
                  <FormGroup className="my-2">
                    <div className="form-check2">
                      <Input type="radio" id="perform" name="cep_name" onClick={() => { setIsCustom(false); setShipMethod('3') }} />
                      <Label check for="perform">
                        Performance
                      </Label>
                    </div>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
        <Collapse isOpen={isCustom}>
          <div className="d-flex justify-content-center flex-wrap mt-3 py-2">
            <FormGroup className="mr-4">
              <div className="form-check2">
                <Input type="radio" id="dlvry_all" name="courier_type" onClick={() => setServiceType("1,7")} defaultChecked />
                <Label for="dlvry_all">All</Label>
              </div>
            </FormGroup>
            <FormGroup className="mr-4">
              <div className="form-check2">
                <Input type="radio" id="dlvry_Express" name="courier_type" onClick={() => setServiceType(1)} />
                <Label for="dlvry_Express">Express</Label>
              </div>
            </FormGroup>
            <FormGroup className="mr-4">
              <div className="form-check2">
                <Input type="radio" id="dlvry_Surface" name="courier_type" onClick={() => setServiceType(7)} />
                <Label for="dlvry_Surface">Surface</Label>
              </div>
            </FormGroup>
          </div>
          <FormGroup className="position-relative mb-0 col-sm-4 pl-0 mb-3">
          <Label>Weight Category (In Kgs.)</Label>
          <span className="arrowdown" style={{right: '1.5rem',top: '2rem'}}><FontAwesomeIcon icon={faChevronDown} /></span>
                  <select type="select" className='form-control'
                  value={selectedValue} // Set the value of select element
                  onChange={handleChange} 
                  >
                      <option value="">Upto</option>
                      <option value="0.5">0.5</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="5">5</option>
                      <option value="7">7</option>
                      <option value="10">10</option>
                  </select>
          </FormGroup>
          {shipMethod === 1 &&
            <CourierPriority selectedValue={selectedValue} serviceType={serviceType} shipMethod={shipMethod} user={props.user} />
          }
        </Collapse>
        {shipMethod !== 1 &&
          <Col sm={12} className="text-right mt-4">
            <Button className="btn btn-sm ctm-btn" color="primary" onClick={submitCourierSetting}>Save</Button>
          </Col>
        }
      </Row>
    </Form>
  );
}

export default CourierSetting;