module.exports = {
    account_type: { 1: 'Postpaid', 2: 'Prepaid', },
    yes_no: { 0: 'No', 1: 'Yes' },
    status: { 0: 'InActive', 1: 'Active' },
    display: { 0: 'Display Hide', 1: 'Display Show' },
    merchant_status: { 0: 'Pending', 1: 'Updated' },
    service: { 1: 'Express', 6: 'Reverse', 7: 'Surface' },
    delivery_type_id: { 1: 'COD', 3: 'Prepaid' },
    movement_type_id: { 1: 'Forward', 2: 'Reverse' },
    order_status: {
        1: 'Uploaded', 2: 'Assigned', 3: 'Failed', 4: 'Pickup Pending', 5: 'Picked', 6: 'Intransit', 7: 'Out for Delivery',
        8: 'Order Cancelled', 9: 'Shipment Cancelled', 10: 'Delivered', 11: 'Delivery Failed', 12: 'RTO Pending',
        13: 'RTO Delivered', 14: 'Shipment Heldup', 15: 'Shipment Lost', 16: 'Shipment Damaged', 17: 'Contact Customer Care', 18: 'Arrived at Destination',
        19: 'Cancellation Requested', 20: 'RTO Undelivered', 21: 'Pickup Cancelled by Merchant', 22: 'Cancelled by Courier',
        23: 'Pickup Failed', 24: 'Auto Cancelled', 25: 'Processing'
    },
    dash_card_order_status: {
        1: 'Uploaded', 2: 'Assigned', 3: 'Failed', 4: 'Pickup Pending', 5: 'Picked', 6: 'Intransit', 7: 'Out for Delivery',
        10: 'Delivered', 11: 'Delivery Failed', 12: 'RTO Pending',
        13: 'RTO Delivered', 14: 'Shipment Heldup', 15: 'Shipment Lost', 16: 'Shipment Damaged', 17: 'Contact Customer Care', 18: 'Arrived at Destination',
        20: 'Shipment RTO Undelivered',
    },
    order_status_badge: {
        1: 'light', 2: 'light', 3: 'danger', 4: 'secondary', 5: 'light', 6: 'light', 7: 'light', 8: 'danger', 9: 'danger', 10: 'success', 11: 'warning', 12: 'warning',
        13: 'danger', 14: 'warning', 15: 'warning', 16: 'warning', 17: 'light', 18: 'light', 19: 'light', 20: 'light', 21: 'danger', 22: 'warning', 23: 'danger', 24: 'danger', 25: 'warning'
    },
    states: {
        62: 'Arunachal Pradesh', 63: 'Assam', 64: 'Manipur', 65: 'Meghalaya', 66: 'Sikkim', 68: 'Nagaland', 69: 'Tripura', 70: 'Mizoram',
        72: 'Haryana', 73: 'Punjab', 75: 'Uttarakhand', 76: 'Himachal Pradesh', 77: 'Jammu and Kashmir', 78: 'Chandigarh',
        79: 'Rajasthan', 81: 'Kerala', 82: 'Tamil Nadu', 83: 'Telangana', 84: 'Karnataka', 85: 'Andhra Pradesh', 86: 'Delhi', 87: 'Madhya Pradesh', 88: 'Uttar Pradesh', 89: 'Chhattisgarh', 90: 'Andaman and Nicobar',
        91: 'Lakshadweep', 92: 'Bihar', 93: 'Goa', 94: 'Gujarat', 95: 'Jharkhand', 96: 'Orissa', 97: 'West Bengal', 98: 'Maharashtra',
        111: '', 112: '', 113: '', 114: 'Uttaranchal', 115: 'Pondicherry', 116: 'ODISHA', 117: 'KHARSIA', 118: 'Dadra and Nagar Haveli', 119: 'Daman and Diu'
    },
    ship_method: {
        1: 'Custom', 2: 'Economical', 3: 'Performance'
    },
    weight: { 1: '0.100', 2: '0.250', 3: '0.500', 4: '1.000', 5: '2.000', 6: '2.500', 7: '5.000', 8: '10.000', 9: '15.000', 10: '20.000', 11: '25.000' },
    movement_type: { 1: 'Forward', 2: 'Reverse' },
    data_type_id: { 1: 'Courier Serviceability', 2: 'Address Labels', 3: 'Manifest', 4: 'Shipment Data', 5: 'Order Data', 6: 'NDR Data', 7: 'Channel Order', 8: 'Remittance Data', 9: 'Freight Data', 10: 'SMS Log', 11: 'Courier Remittance', 12: 'Invoice', 13: 'Credit Notes', 14: 'Debit Notes', 15: 'Utility Data', 16: 'Lost Shipments', 17: 'Weight Recon.',18:"Passbook" },
    wallet_head: { 1: 'Wallet Recharge', 2: 'Shipped Order', 3: 'SMS', 4: 'VAS', 5: 'Monthly Rental', 6: 'Invoice Payment', 7: 'charge back',8:'Add Postpaid Limit',9:"Credit Note"},
    pay_status: { 0: 'Unpaid', 1: 'Paid' },
    zone_id: { 1: 'East', 2: 'West', 3: 'North', 4: 'South', 5: 'Central', 6: 'North East' },
    creditNote_head_id: { 1: 'Shipment Lost', 2: 'Excess Weight Charge(c) (With GST)', 3: 'Twice Freight Charge (With GST)', 4: 'Incorrect Freight Charge (With GST)' },
    debitNote_head_id: { 1: 'Status Reversal', 2: 'Legal Complained' },
    kyc_type_id: { 1: 'Adhar Card', 2: 'Voter ID', 3: 'Driving License' },
    company_type_id: { 1: 'Adhar Card', 2: 'Voter ID', 3: 'Driving License' },
    order_ndrs: {
        1: 'Premise Lock', 2: 'Customer Not Available', 3: 'Customer Refused', 4: 'Cash Not Ready', 5: 'Customer Out of Station', 6: 'Wrong Address/Pin Code', 7: 'Address Correct but No Such Customer',
        8: 'Requested Delay Delivery', 9: 'Restricted Entry', 10: 'Customer Wants Open Delivery', 11: 'Customer Requested Self Collection', 13: 'Out of Delivery Area (ODA)'
    },
    channel_order_status: { 1: 'In Process', 2: 'Cancel', 3: 'Shipped' },
    ndr_actions: { 1: 'Reattempt', 2: 'RTO' },
    pending_order_status_list: {
        4: 'Pickup Pending', 5: 'Picked', 6: 'Intransit', 7: 'Out for Delivery', 11: 'Delivery Failed', 12: 'RTO Pending', 14: 'Shipment Heldup', 15: 'Shipment Lost', 16: 'Shipment Damaged',
        17: 'Contact Customer Care', 18: 'Arrived at Destination',
    },
    
    pending_order_status: [5, 6, 7, 11, 12, 14, 15, 16, 17, 18, 20],
    courier_overview_pending_order_status: [5, 6, 7, 11, 14, 15, 16, 17, 18, 20],
    performance_overview_pending_order_status: [5, 6, 7, 11, 14, 15, 16, 17, 18, 20],
    block_merchant_id: [51900, 47700,],
    pendency_pending_order_status: [5, 6, 7, 11, 12, 14, 16, 17, 18, 20],
    overall_pending_order_status: [4, 5, 6, 7, 11, 12, 14, 15, 16, 17, 18, 20],
    pending_order_status_pie_chart: [5, 6, 7, 11, 12, 14, 16, 17, 18, 20],
    rto_order_status: [12, 13],
    delivered_order_status: [10],
    failed_order_status: [11],
    cancel_order_status: [8, 9, 21, 22, 24],
    ledger_heads: { 1: 'Remittance Adjust against Freight', 2: 'Remittance Paid', 3: 'Freight Paid', 4: 'Invoice Payment Received' },
    zone_category_id: { 1: 'Metro', 2: 'ROI', 3: 'Within City', 4: 'Within Zone', 5: 'Special Destination', 6: 'Extended', 7: 'Within State' },
    download_delay: 5000,
    volumetricFormula: 5000,
    sms_log_status: { 0: 'Not Verified', 1: 'Verified' },
    weight_mismatch_status: { 1: 'Discrepency', 2: 'Dispute Raised', 3: 'Accepted by Courier', 4: 'Rejected by Courier', 5: 'Auto Accepted', 6: 'Accepted by Merchant' },
    courier_ledger_heads: { 1: 'Remittance Received', 2: 'Freight Paid', 3: 'Debit Note', 4: 'Credit Note' },
    merchant_ledger_heads: { 1: 'Remittance Paid', 2: 'Remittance Adjust', 3: 'Invoice Adjust' },
    lost_resion: { 1: 'POD Not Provided', 2: 'Due To High Ageing', 3: 'TAT Breach', 4: 'Missing/Damaged/Empty/Open Shipment', 5: 'Cancelled Shipment (Only For Prepaid)', 6: 'Pickup Not Done', 7: 'Shipment Not Connected', 8: 'Declare Lost By Courier', 10: 'Unable To Provide POD', 11: 'Shipment Snatched/Dispute' },
    baseString: 'b2cfeee7-f777-4855-9e17-b0cd637448ce',
    7:'WhatsAPP_ON_OFF',8:"awb_assigned_whatsapp_template",9:"Delivery_failed_whatsapp_template"

}