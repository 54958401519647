import React, { useState, useRef } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Form, FormGroup, Label, Button, InputGroup, InputGroupAddon, InputGroupText, Spinner } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { Eye, EyeOff } from 'react-feather';
import RegisterBg from "../assets/img/register-bg.svg";
import { SignupMerchant } from '../actions';
import { connect, useDispatch } from "react-redux";
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router';
import { checkData, checkUndeNullBlank } from '../ObjectExist';
import { makePaymentpayu } from './payu/Payu'
import { baseString } from '../config';

const Register = (props) => {
    const [passwordType, setPasswordType] = useState(true);
    const [passwordType2, setPasswordType2] = useState(true);
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const history = useHistory();
    const { register: signupInput, handleSubmit: signupSubmit, watch, formState: { errors } } = useForm();
    const password = useRef({});
    password.current = watch("password");

    const onMerchantSignup = (data) => {
        data.admin_id = process.env.REACT_APP_ADMIN_ID
        data.refer_by = location.pathname === "/ship2bharat" ? 'Ship2Bharat' : 'web'
        setLoader(true);
        dispatch(SignupMerchant(data)).then(response => {
            setLoader(false)
            if (Object.entries(response).length > 0) {
                if (response.error === false) {
                    Swal.fire({ icon: 'success', text: response.msg, title: 'Success', allowOutsideClick: false, allowEscapeKey: false })
                        .then(okay => {
                            if (okay) {
                                // history.push("/login");
                                if (location.pathname === "/ship2bharat") {
                                    onPaymentSubmit(data, response.data.insertId);
                                } else {
                                    history.push("/login");
                                }
                            }
                        });
                } else {
                    Swal.fire({ icon: 'error', text: response.msg, title: 'Error' });
                }
            } else {
                Swal.fire({ icon: 'error', text: response.msg, title: 'Error' });
            }
        }).catch(function (response) {
            setLoader(false)
            Swal.fire({ title: 'Error', icon: 'error', text: response })
        });
    };
    const onPaymentSubmit = (data, Id) => {
        let callbackURLPAYU = process.env.REACT_APP_PAYU_CALLBACK_SEND_URL
        fetch(process.env.REACT_APP_PAYU_HASH, {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json',
                "Accept": 'application/json',
                "token": window.btoa(baseString)
            },
            body: JSON.stringify({ merchant_amt: 1, merchant_email: checkData(data) && checkUndeNullBlank(data.email) ? data.email : '', merchant_id: checkUndeNullBlank(Id) ? Id : '', merchant_name: checkData(data) && checkUndeNullBlank(data.company_name) ? data.company_name : '', })
        }).then((resp) => {
            resp.json().then((result) => {
                if (result.error === false) {
                    let param = {
                        key: result.key,
                        hash: result.hash,
                        txnid: result.txnid,
                        amount: 1,
                        productinfo: result.productinfo,
                        country: 'India',
                        firstname: checkData(data) && checkUndeNullBlank(data.company_name) ? data.company_name : '',
                        udf1: checkUndeNullBlank(Id) ? Id : '',
                        phone: checkData(data) && checkUndeNullBlank(data.mobile) ? data.mobile : '',
                        surl: callbackURLPAYU,
                        furl: callbackURLPAYU,
                        email: checkData(data) && checkUndeNullBlank(data.email) ? data.email : '',
                        service_provider: 'payu_paisa'
                    }
                    makePaymentpayu(param);
                }
            });
        })
    };
    const location = useLocation();
    return (
        <div className="app-content m-0 p-0 content overflow-hidden">
            {loader && <div className="formLoader"><Spinner /></div>}
            <Row>
                <Col sm={12}>
                    <div className="p-0 RegisterPage content-wrapper">
                        <Row>
                            <Link to="#" className="brand-logo text-center py-3 py-lg-0 d-none" ><img src={`./logo${process.env.REACT_APP_ADMIN_ID}.png`} alt="logo" style={{ maxWidth: '200px' }} /></Link>
                            <Col sm={12} lg={7} className="d-none d-lg-flex align-items-center p-5">
                                <div className="d-lg-flex justify-content-center align-items-center px-5 w-100">
                                    <img className="img-fluid" src={RegisterBg} alt="img" />
                                </div>
                            </Col>
                            <Col sm={12} lg={5} className="d-flex align-items-center px-2 p-lg-5 pt-lg-3 pt-3 bg-white">
                                <Card className="border-0 px-xl-3 mx-auto w-100">
                                    <div className="d-flex justify-content-center align-items-center flex-wrap px-2 px-md-0 mb-2 mt-3 mt-lg-0">
                                        <Link to="#" className="brand-logo2 text-center d-inline-block py-3 py-lg-0 mr-2" ><img src={location.pathname === "/ship2bharat" ? './ship2bharat.jfif' : `./logo${process.env.REACT_APP_ADMIN_ID}.png`} alt="logo" style={{ maxWidth: location.pathname === "/ship2bharat" ? '70px' : '200px' }} /></Link>
                                        {location.pathname === "/ship2bharat" &&
                                            <Link to="#" className="brand-logo2 text-center d-inline-block py-3 py-lg-0 border-left border-secondary pl-3 ml-3" >
                                                <span><img src="./logo1.png" alt="logo" style={{ maxWidth: '150px' }} /></span>
                                                <span className="font-weight-500 d-block" style={{ fontSize: "0.6rem", color: "#333" }}>Preferred Courier Partner</span>
                                            </Link>}
                                    </div>
                                    <CardBody className="align-items-center">
                                        <CardTitle tag="h5" className="mb-3 text-center" onClick={onPaymentSubmit}>Merchant Registration Form</CardTitle>
                                        {/* <CardTitle tag="h3">{location.pathname === "/ship2bharat" ? 'Ship2Bharat' : process.env.REACT_APP_NAME} starts here</CardTitle>
                              <p className="card-text">Please register to your account and start the {location.pathname === "/ship2bharat" ? 'Ship2Bharat' : process.env.REACT_APP_NAME}</p> */}
                                        <Form onSubmit={signupSubmit(onMerchantSignup)}>
                                            <FormGroup>
                                                <Label for="brand_name">Brand Name <small>(amazon, flipkart)</small><span className="text-danger">*</span></Label>
                                                <input {...signupInput("brand_name", { required: 'Please Enter Brand Name' })} className="form-control" placeholder="Please Enter Brand Name" id="brand_name" />
                                                {errors.brand_name && <span className="text-danger d-block error">{errors.brand_name.message}</span>}
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="company_name">Registered Company Name<span className="text-danger">*</span></Label>
                                                <input {...signupInput("company_name", { required: 'Please Enter Company Name' })} className="form-control" placeholder="Please Enter Company Name" id="company_name" />
                                                {errors.company_name && <span className="text-danger d-block error">{errors.company_name.message}</span>}
                                            </FormGroup>
                                            {/* <FormGroup>
                                    <Label for="company_type">Company Type *</Label>
                                    <input {...signupInput("company_type_id",{ required: 'Please Enter Company Type'})} className="form-control" placeholder="Please Enter Company Type" id="company_type_id" />
                                    {errors.company_type_id && <span className="text-danger d-block error">{errors.company_type_id.message}</span>}
                                </FormGroup>  */}
                                            <FormGroup>
                                                <Label for="email">Email<span className="text-danger">*</span></Label>
                                                <input {...signupInput("email", { required: 'Please Enter Email' })} type="email" className="form-control" placeholder="Please Enter Email" id="email" />
                                                {errors.email && <span className="text-danger d-block error">{errors.email.message}</span>}
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="mobile">Mobile<span className="text-danger">*</span></Label>
                                                <input {...signupInput("mobile", {
                                                    required: 'Please Enter Mobile No.',
                                                    minLength: { value: 10, message: "Please Enter 10 Digit Mobile Number" },
                                                    maxLength: { value: 10, message: "Please Enter 10 Digit Mobile Number" },
                                                    pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Mobile Number' }
                                                })} type="tel" className="form-control" placeholder="Please Enter Mobile No." id="mobile" />
                                                {errors.mobile && <span className="text-danger d-block error">{errors.mobile.message}</span>}
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="password">Password<span className="text-danger">*</span></Label>
                                                <InputGroup>
                                                    <input {...signupInput("password", {
                                                        required: 'Please Enter Password',
                                                        minLength: { value: 6, message: "Password must be at least 6 characters" }
                                                    })} type={passwordType ? "password" : "text"} placeholder="Please Enter Password" className="form-control" id="password" />
                                                    <InputGroupAddon addonType="append" onClick={() => setPasswordType(!passwordType)}>
                                                        <InputGroupText className="py-0">{passwordType ? <Eye /> : <EyeOff />}</InputGroupText>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                                {errors.password && <span className="text-danger d-block error">{errors.password.message}</span>}
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="confirm_password">Confirm Password<span className="text-danger">*</span></Label>
                                                <InputGroup>
                                                    <input {...signupInput("confirm_password", { required: 'Confirm Password is required', validate: value => value === password.current || "The Passwords do not match" })} type={passwordType2 ? "password" : "text"} placeholder="Please Enter Confirm Password" className="form-control" id="confirm_password" />
                                                    <InputGroupAddon addonType="append" onClick={() => setPasswordType2(!passwordType2)}>
                                                        <InputGroupText className="py-0">{passwordType2 ? <Eye /> : <EyeOff />}</InputGroupText>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                                {errors.confirm_password && <span className="text-danger d-block error">{errors.confirm_password.message}</span>}
                                            </FormGroup>
                                            <FormGroup>
                                                <FormGroup check className="pl-0">
                                                    <Label check><input {...signupInput("privacy_policy", { required: 'Please Accept the Policy' })} type="checkbox" value="accepted" className="mr-1" />I agree to <Link href="#">privacy policy & terms</Link> </Label>
                                                    {errors.privacy_policy && <span className="text-danger d-block error">{errors.privacy_policy.message}</span>}
                                                </FormGroup>
                                            </FormGroup>
                                            <FormGroup>
                                                <Button className="btn-sm ctm-btn w-100 py-3 signup-btn" color="primary">{location.pathname === "/ship2bharat" ? 'Recharge ₹ 500 to Sign Up ' : 'Sign Up'}</Button>
                                            </FormGroup>
                                            <FormGroup className='text-center'>
                                                <small>{location.pathname === "/ship2bharat" && 'Recharge amount will be use in your shipping charges'}</small>
                                            </FormGroup>

                                            <p className="text-center">
                                                <span>Already have an account? </span>
                                                <Link to="/login">Sign in instead</Link>
                                            </p>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </div>
                </Col>
            </Row>
        </div>
    );
}
function mapStateToProps(state) {
    return {
        state: state.DataReducer
    }
}
export default connect(mapStateToProps)(Register);