import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, FormGroup, Form, InputGroup, InputGroupAddon, InputGroupText, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, Label, Spinner, UncontrolledTooltip, CardSubtitle, ButtonGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faArrowDown, faArrowUp, faChevronRight, faDownload, faImage, faBalanceScale } from '@fortawesome/free-solid-svg-icons';
import { MultiSelect } from "react-multi-select-component";
import { DateRangePicker } from 'rsuite';
import { connect } from 'react-redux';
import { ChevronLeft, ChevronRight, Copy } from 'react-feather';
import { toast } from 'react-toastify';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import LoaderDefault from '../LoaderDefault';
import NodataFound from '../NodataFound';
import { useForm } from "react-hook-form";
import { useHistory, Link } from 'react-router-dom';
import { errorHandling, remExtraSpace, changeIntoDateTime, firstDayOftheMonth, copyToClipBoard, moreText } from '../../utlis';
import { baseString, download_delay, weight_mismatch_status } from '../../config';
import UrlImageDownloader from "react-url-image-downloader";
import axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment';

const WeightReconcilation = (props) => {
  const [merchants, setMerchants] = useState([]);
  const [Status, setStatus] = useState([]);
  const [difference, setDifference] = useState([]);
  const [searchValue, setSearchValue] = useState(' ');
  const [weightMismatch, setWeightMismatch] = useState({});
  const [loadertable, setLoadertable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState({ "end_date": moment().format('YYYY-MM-DD'), "start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), });
  const [filterDate, setFilterDate] = useState([new Date(), new Date()]);
  const [excelFile, setExcelFile] = useState();
  const [excelmodal, setExcelmodal] = useState(false);
  const [autoAcceptModal, setAutoAcceptModal] = useState(false);
  const [raiseDisputeModal, setRaiseDisputeModal] = useState(false);
  const [viewRaiseDisputeModal, setViewRaiseDisputeModal] = useState(false);
  const [viewRaiseDisputeData, setViewRaiseDisputeData] = useState({});
  const [viewHCreateDate, setViewHCreateDate] = useState('');
  const [imgLength, setImgLength] = useState();
  const [imgBreadth, setImgBreadth] = useState();
  const [imgHeight, setImgHeight] = useState();
  const [imgWeight, setImgWeight] = useState();
  const [viewHNextDate, setViewHNextDate] = useState('');
  const [viewHStatus, setViewHStatus] = useState('');
  const dropFilterCount = 20;
  const history = useHistory();
  let [weightReconId, setWeightReconId] = useState('');
  let [autoAcptMsg, setAutoAcptMsg] = useState(false);

  const excelUpload = () => {
    setExcelmodal(!excelmodal);
    resetWghtMtchfile();
    if (!excelmodal) { setExcelFile(""); }
  }
  const handleFileInput = (e) => {
    setExcelFile(e.target.files[0]);
  }

  var opt_merchants = [];
  if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)) {
    Object.keys(props.state.shipmentMerchantCourier.merchants).map(function (key) {
      let merchantName = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchantName) && props.state.shipmentMerchantCourier.merchants[key].merchantName
      let merchantId = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchant_id) && props.state.shipmentMerchantCourier.merchants[key].merchant_id
      let tempObj = {}
      if (merchantName && merchantId) {
        tempObj = { label: merchantName, value: merchantId }
        opt_merchants.push(tempObj)
      }
      return true;
    });
  }
  const merchantsMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Merchant"
  }

  const opt_Status = [
    { label: "Discrepency", value: 1 },
    { label: "Dispute Raised", value: 2 },
    { label: "Accepted by Courier", value: 3 },
    { label: "Rejected by Courier", value: 4 },
    { label: "Auto Accepted", value: 5 },
    { label: "Accepted by Merchant", value: 6 },
  ];
  const StatusMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Status"
  }
  const opt_difference = [
    { label: "0-0.5 Kg[s]", value: "0-0.5" },
    { label: "1-2 Kg[s]", value: "1-2" },
    { label: "2-5 Kg[s]", value: "2-5" },
  ];
  const DifferenceMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Difference"
  }

  useEffect(() => {
    mismatchWeightListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const mismatchWeightListing = () => {
    setFilterInput({ "end_date": moment().format('YYYY-MM-DD'), "start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), })
    setCurrentCount(1)
    setPage(1);
    setFilterDate([firstDayOftheMonth(), new Date()]); setMerchants([]); setSearchValue(' ');
    reset();
    let data = { "start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), "end_date": moment().format('YYYY-MM-DD') }
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }

    setLoadertable(true);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_WEIGHT_MISMATCH_LIST}?page=${page}&per_page=${selectperpage}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoadertable(false)
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          setWeightMismatch(res.data);
        }
      }
    }).catch((err) => {
      setLoadertable(false)
      errorHandling(err, "reload");
    });
  }


  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoadertable(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }
  function nextPageData() {
    if (checkData(weightMismatch.data) && checkUndeNullBlank(weightMismatch.totalPage)) {
      if (weightMismatch.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(weightMismatch.data).length)
        setPage(page + 1)
        setLoadertable(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(weightMismatch.data)
      if (currentCount > Object.entries(weightMismatch.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }
  const { register: filter, handleSubmit: filterWeightMismatchSubmit, reset, } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };
  const onFilterWeightMismatch = (data, pagePerpage) => {
    pagePerpage.page = 1
    if ((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value)) || checkData(Status) || checkData(difference) || checkData(merchants) || checkData(filterDate)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
      Swal.fire({ title: 'info', text: 'Please Choose Filter', icon: 'info', })
    }
  };
  const onCommonFilter = (data, pagePerpage) => {
    let byData = {}
    let statusId = [];
    let differnece = [];
    let merchantsId = [];
    if (checkData(Status)) {
      Status.map((item) => (
        statusId.push(item.value)
      ));
    }
    if (checkData(difference)) {
      difference.map((item) => (
        differnece.push(item.value)
      ));
    }
    if (checkData(merchants)) {
      merchants.map((item) => (
        merchantsId.push(item.value)
      ));
    }
    setLoader(true)

    let searchVal = checkUndeNullBlank(data.search_value) ? remExtraSpace(data.search_value) : '';
    let sId = checkUndeNullBlank(statusId) ? statusId : '';
    let mId = checkUndeNullBlank(merchantsId) ? merchantsId : '';
    let sDate = checkData(filterDate) ? moment(filterDate[0]).format('YYYY-MM-DD') : '';
    let eDate = checkData(filterDate) ? moment(filterDate[1]).format('YYYY-MM-DD') : '';
    let pageNumber = checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page
    let perPageCount = checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage

    byData = { "search_field": checkUndeNullBlank(data.search_field) ? data.search_field : '', "search_value": searchVal, "status_ids": sId, "merchant_ids": mId, "start_date": sDate, "end_date": eDate, "weight_difference": differnece }

    setFilterInput(byData);
    if (props.user[0].user_type === "Super") {
      byData.admin_id = props.user[0].id
    } else {
      byData.merchant_id = props.user[0].id
    }

    axios({
      method: "post",
      url: `${process.env.REACT_APP_WEIGHT_MISMATCH_LIST}?page=${pageNumber}&per_page=${perPageCount}`,
      data: byData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data.error === false) {
        setWeightMismatch(res.data);
      }
      else {
        Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
      }
    }).catch((err) => {
      errorHandling(err, "")
      setLoader(false);
    });
  };

  const { register: uploadWeightMismatch, handleSubmit: weightMismatchUploadSubmit, formState: { errors }, reset: resetWghtMtchfile } = useForm();
  const onWeightMismatchSubmit = (data) => {
    let formData = new FormData();
    formData.append("file", excelFile);
    formData.append("admin_id", checkData(props.user) ? props.user[0].id : '');
    setLoader(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_WEIGHT_MISMATCH_UPLOAD}`,
      data: formData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      let errorFile = '';
      if (res.data.errorfile !== undefined) {
        errorFile = checkUndeNullBlank(res.data.errorfile) ? process.env.REACT_APP_BASE_URL + res.data.errorfile : '';
      }
      if (res.data.error === false) {
        Swal.fire({
          title: 'Success',
          html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Uploaded</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td>${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' ? `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>` : ''}</tbody></Table>`,
          text: res.data.msg,
          icon: 'success',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(okay => {
          if (okay) {
            setExcelFile(false);
            mismatchWeightListing();
          }
        });
      } else {
        Swal.fire({
          title: 'Error',
          text: res.data.msg,
          html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Uploaded</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td style="padding: 0.25rem 0;">${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' && `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`}</tbody></Table>`,
          icon: 'error',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(okay => {
          if (okay) {
            setExcelFile(false);
            mismatchWeightListing();
          }
        });
      }
    })
      .catch((err) => {
        setLoader(false);
        errorHandling(err, "");
      });
  }

  const exportWeightMM = () => {
    if (checkData(filterInput)) {
      let data = filterInput
      if (props.user[0].user_type === "Super") {
        data.admin_id = props.user[0].id
      } else {
        data.merchant_id = props.user[0].id
      }
      data.is_export = 1
      setLoader(true);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_WEIGHT_MISMATCH_LIST}`,
        data: data,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
        if (res.data !== undefined && res.data.fatal !== true && res.data !== true) {
          if (parseInt(res.data.errno) > 0) {
            setLoader(false)
            if (res.data.sqlMessage !== undefined) {
              Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
            }
          } else {
            if (res.data.is_export === 1) {
              setTimeout(function () {
                setLoader(false);
                history.push("/my-downloads");
              }, download_delay);
            }
          }
        }
      }).catch((err) => {
        setLoader(false)
        errorHandling(err, "")
      });
    } else {
      Swal.fire({ icon: 'error', title: 'Error', text: 'Please Choose Filter' })
    }
  }


  const after48hours = (date) => {
    return moment(date).add(48, 'hours').toISOString();
  }

  const toggleAutoAcceptClose = () => {
    setAutoAcceptModal(!autoAcceptModal);
  }
  const toggleAutoAccept = (createDate, status) => {
    setAutoAcceptModal(!autoAcceptModal);
    let curdate = moment(createDate);
    let nextDate = after48hours(createDate);
    setViewHStatus(status);
    setViewHCreateDate(changeIntoDateTime(curdate));
    setViewHNextDate(changeIntoDateTime(nextDate));

    if (moment().toISOString() >= after48hours(createDate)) {
      setAutoAcptMsg(true)
    } else {
      setAutoAcptMsg(false)
    }
    ///test perpose
    // let timeofCreateDate = new Date(createDate).toLocaleTimeString('en',
    // { timeStyle: 'long', hour12: false, timeZone: 'UTC' })

    // let after48H = moment(createDate).add(1, 'seconds').toISOString();
    // let timeafter48H = new Date(after48H).toLocaleTimeString('en',
    // { timeStyle: 'long', hour12: false, timeZone: 'UTC' })
  }

  const toggleRaiseDispute = () => {
    setRaiseDisputeModal(!raiseDisputeModal);
  }
  const toggleRaiseDisputeClose = () => {
    setRaiseDisputeModal(!raiseDisputeModal);
  }
  const viewRaiseDisputeClose = () => {
    setViewRaiseDisputeModal(false);
  }

  const handleImgLength = (e) => {
    setImgLength(e.target.files[0]);
  }
  const handleImgBreadth = (e) => {
    setImgBreadth(e.target.files[0]);
  }
  const handleImgHeight = (e) => {
    setImgHeight(e.target.files[0]);
  }
  const handleImgWeight = (e) => {
    setImgWeight(e.target.files[0]);
  }

  const { register: addRaise, handleSubmit: raiseDisputeSubmit, formState: { errors: errors2 } } = useForm();
  const onAddRaiseDispute = (data) => {
    setLoader(true);
    let formData = new FormData();
    if (props.user[0].user_type !== "Super") {
      formData.append("merchant_id", props.user[0].id);
    }
    formData.append("weight_recon_id", weightReconId);
    formData.append("product_category", data.prod_category);
    formData.append("l_img", imgLength);
    formData.append("length_in_cms", data.image_length);
    formData.append("b_img", imgBreadth);
    formData.append("breadth_in_cms", data.image_breadth);
    formData.append("h_img", imgHeight);
    formData.append("height_in_cms", data.image_height);
    formData.append("weight_img", imgWeight);
    formData.append("merchant_weight", data.image_weight);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_WEIGHT_MISMATCH_RAISE_DISPUTE}`,
      data: formData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data.error === false) {
        setRaiseDisputeModal(false);
        Swal.fire({ title: 'Success', text: res.data.msg, icon: 'success', })
      }
    })
      .catch(function (err) {
        setLoader(false);
        errorHandling(err, "");
      });
  }

  const viewRaiseDispute = (id) => {

    setLoader(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_WEIGHT_MISMATCH_DISPUTE_HISTORY}`,
      data: { "weight_recon_id": id },
      headers: { "token": window.btoa(baseString) }
    })
      .then(function (response) {
        setLoader(false);
        if (response.data.error === false) {
          setViewRaiseDisputeData(response.data);
          setViewRaiseDisputeModal(true);
        }
      })
      .catch(function (err) {
        setLoader(false);
        errorHandling(err, "reload");
      });
  }

  const rejectDispute = (mid, sid, wrid) => {
    let data = { "merchant_id": mid, "status_id": sid, "weight_recon_id": wrid }
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    }
    Swal.fire({
      title: 'Are You Sure?',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      showLoaderOnConfirm: true,
      preConfirm: (remarks) => {
        data.remarks = remarks
        return axios({
          method: "post",
          url: `${process.env.REACT_APP_WEIGHT_MISMATCH_STATUS_UPDATE}`,
          data: data,
          headers: { "token": window.btoa(baseString) }
        }).then(function (response) {
          setLoader(false);
          if (response.data.error === false) {
            setViewRaiseDisputeData(response.data);
            setViewRaiseDisputeModal(true);
          }
        }).catch(function (err) {
          setLoader(false);
          errorHandling(err, "reload");
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire({
        //   title: `${result.value.login}'s avatar`,
        //   imageUrl: result.value.avatar_url
        // })
      }
    })
  }

  // copy text
  const copied = (text) => {
    let decision = copyToClipBoard(text);
    decision.then(function (result) {
      if (result === true) {
        toast("Copied!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
  }
  let summaryData = {}
  if (checkData(weightMismatch) && checkData(weightMismatch.summaryDataArr) && checkData(weightMismatch.summaryDataArr[0])) {
    summaryData = weightMismatch.summaryDataArr[0]
  }


  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
        <Col sm={12}>
          <Card className="p-0 my-3 weightMismatch shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex flex-wrap justify-content-between">
                <CardTitle className="page-title">Manage Weight Reconcilation </CardTitle>
                <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                  <div className="btn-text btn-light mx-2" onClick={exportWeightMM}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Download Data</span></div>
                  {props.user[0].user_type === "Super" &&
                    <div className="btn-text btn-light" onClick={excelUpload}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowUp} /></span><span>Upload Weight Mismatch File</span></div>
                  }
                  <div className="btn-text btn-light d-none"><span className="icon mr-2"><FontAwesomeIcon icon={faArrowUp} /></span><span>Upload Status</span></div>
                </div>
                <Modal isOpen={excelmodal} toggle={excelmodal} scrollable={true} >
                  <ModalHeader toggle={excelUpload}>Upload Weight Mismatch</ModalHeader>
                  <ModalBody>
                    <Form onSubmit={weightMismatchUploadSubmit(onWeightMismatchSubmit)}>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Upload all Your Weight Mismatch File via XLSX</p>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Please download below given template</p>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Remove sample data and upload your data</p>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Make sure you atleast fill mandatory fields.</p>

                      <Row className="m-0 pb-1">
                        <Col sm={12} className="p-0">
                          <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span> <Link target="_blank" download to="/format/weight_mismatch.xlsx" className=""><FontAwesomeIcon icon={faDownload} />  Download
                          </Link>
                            <span className="ml-2">Upload Weight Mismatch Format File</span></p>
                        </Col>
                        <Col sm={12} className="m-auto pt-1 p-0">
                          <FormGroup>
                            <input {...uploadWeightMismatch("excelUpload", {
                              required: 'Please Upload XLSX File',
                              validate: {
                                lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                                acceptedFormats: files =>
                                  ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                                    files[0]?.type
                                  ) || 'Only .xslx file allowed',
                              },
                            })} type="file" id="excelUpload" accept=".xlsx" className="custom-file-input" onChange={handleFileInput} />
                            <Label className="custom-file-label" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name : 'Choose file'}</Label>
                            {errors.excelUpload && <span className="text-danger d-block error">{errors.excelUpload.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12} className="text-right">
                          <Button className="btn ctm-btn btn-sm" color="primary">Upload</Button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
                <Modal isOpen={autoAcceptModal} toggle={autoAcceptModal} scrollable={true} size="lg">
                  <ModalHeader toggle={toggleAutoAcceptClose}>{autoAcptMsg ? "Auto Accepted" : "View History"}</ModalHeader>
                  <ModalBody>
                    <Row className="mx-0 mb-2">
                      <Col sm={4}>
                        <span>{viewHCreateDate}</span>
                      </Col>
                      <Col sm={4}>
                        <span>{viewHStatus}</span>
                      </Col>
                    </Row>
                    {autoAcptMsg &&
                      <Row className="mx-0">
                        <Col sm={4}>
                          <span>{viewHNextDate}</span>
                        </Col>
                        <Col sm={4}>
                          <span>Auto Accepted</span>
                        </Col>
                      </Row>}
                  </ModalBody>
                </Modal>
                <Modal isOpen={raiseDisputeModal} toggle={raiseDisputeModal} scrollable={true} size="lg" className="weightMismatchModal">
                  <ModalHeader toggle={toggleRaiseDisputeClose}>Raise a Dispute</ModalHeader>
                  <ModalBody>
                    <Form onSubmit={raiseDisputeSubmit(onAddRaiseDispute)}>
                      <Row className="mb-2 formGroupSec">
                        <Col sm={4} className="formGroupTitleSec">
                          <FormGroup>
                            <Label for="pro_ctgry">Product Category<span className="text-danger">*</span></Label>
                          </FormGroup>
                        </Col>
                        <Col sm={8} className="formGroupFieldSec">
                          <Row>
                            <Col sm={6}>
                              <FormGroup>
                                <input {...addRaise("prod_category", { required: 'Please Enter Product Category' })} className="form-control-sm form-control" placeholder="Please Enter Product Category" />
                                {errors2.prod_category && <span className="text-danger d-block error">{errors2.prod_category.message}</span>}
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col sm={12}>
                          <CardSubtitle tag="h6" className="text-center"><span class="mr-2"><FontAwesomeIcon icon={faImage} /></span>Add Images</CardSubtitle>
                        </Col>
                      </Row>
                      <Row className="formGroupSec">
                        <Col sm={4} className="formGroupTitleSec">
                          <FormGroup>
                            <Label for="">Upload Image<span className="text-danger">*</span><br /><span>(500px X 500px)</span></Label>
                          </FormGroup>
                        </Col>
                        <Col sm={8} className="formGroupFieldSec">
                          <Row>
                            <Col sm={6}>
                              <FormGroup className="uploadFilecon">
                                <input {...addRaise("upload_length", { required: checkUndeNullBlank(imgLength) ? false : true && 'Please Upload Image Length' })} type="file" id="lengthUpload" className="custom-file-input" onChange={handleImgLength} />
                                <Label className="custom-file-label">{checkUndeNullBlank(imgLength) ? imgLength.name : 'Image (Length)'}</Label>
                                {errors2.upload_length && <span className="text-danger d-block error">{errors2.upload_length.message}</span>}
                              </FormGroup>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <input {...addRaise("image_length", {
                                  required: 'Please Enter Length',
                                  pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Length' }
                                })} className="form-control-sm form-control" placeholder="Enter Length (cm)" />
                                {errors2.image_length && <span className="text-danger d-block error">{errors2.image_length.message}</span>}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <FormGroup className="uploadFilecon">
                                <input {...addRaise("upload_breadth", { required: checkUndeNullBlank(imgBreadth) ? false : true && 'Please Upload Image Breadth' })} type="file" id="breadthUpload" className="custom-file-input" onChange={handleImgBreadth} />
                                <Label className="custom-file-label">{checkUndeNullBlank(imgBreadth) ? imgBreadth.name : 'Image (Breadth)'}</Label>
                                {errors2.upload_breadth && <span className="text-danger d-block error">{errors2.upload_breadth.message}</span>}
                              </FormGroup>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <input {...addRaise("image_breadth", {
                                  required: 'Please Enter Breadth',
                                  pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Breadth' }
                                })} className="form-control-sm form-control" placeholder="Enter Breadth (cm)" />
                                {errors2.image_breadth && <span className="text-danger d-block error">{errors2.image_breadth.message}</span>}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <FormGroup className="uploadFilecon">
                                <input {...addRaise("upload_height", { required: checkUndeNullBlank(imgHeight) ? false : true && 'Please Upload Image Height' })} type="file" id="heightUpload" className="custom-file-input" onChange={handleImgHeight} />
                                <Label className="custom-file-label">{checkUndeNullBlank(imgHeight) ? imgHeight.name : 'Image (Height)'}</Label>
                                {errors2.upload_height && <span className="text-danger d-block error">{errors2.upload_height.message}</span>}
                              </FormGroup>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <input {...addRaise("image_height", {
                                  required: 'Please Enter Height',
                                  pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Height' }
                                })} className="form-control-sm form-control" placeholder="Enter Height (cm)" />
                                {errors2.image_height && <span className="text-danger d-block error">{errors2.image_height.message}</span>}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <FormGroup className="uploadFilecon">
                                <input {...addRaise("upload_weight", { required: checkUndeNullBlank(imgWeight) ? false : true && 'Please Upload Image Weight' })} type="file" id="weightUpload" className="custom-file-input" onChange={handleImgWeight} />
                                <Label className="custom-file-label">{checkUndeNullBlank(imgWeight) ? imgWeight.name : 'Image (Weight)'}</Label>
                                {errors2.upload_weight && <span className="text-danger d-block error">{errors2.upload_weight.message}</span>}
                              </FormGroup>
                            </Col>
                            <Col sm={6}>
                              <FormGroup>
                                <input {...addRaise("image_weight", {
                                  required: 'Please Enter Weight',
                                  pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Weight' }
                                })} className="form-control-sm form-control" placeholder="Enter Weight (Kgs.)" />
                                {errors2.image_weight && <span className="text-danger d-block error">{errors2.image_weight.message}</span>}
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} className="text-right">
                          <Button className="btn ctm-btn btn-sm" color="primary">Save</Button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
                <Modal isOpen={viewRaiseDisputeModal} toggle={viewRaiseDisputeModal} scrollable={true} size="md" className="weightMismatchModal">
                  <ModalHeader toggle={viewRaiseDisputeClose}>View Raise a Dispute</ModalHeader>
                  {checkData(viewRaiseDisputeData) && checkData(viewRaiseDisputeData.data) && <>
                    {viewRaiseDisputeData.data.map((item, index) => {
                      let productCategory = item.product_category;
                      let lengthInCms = item.length_in_cms;
                      let Limg = item.l_img;
                      let breadthInCms = item.breadth_in_cms;
                      let Bimg = item.b_img;
                      let heightInCms = item.height_in_cms;
                      let Himg = item.h_img;
                      let merchanWeight = item.merchant_weight;
                      let Weightimg = item.weight_img;
                      let path = "/" + viewRaiseDisputeData.path + "/";

                      return (
                        <ModalBody>
                          <Row className="mb-2 formGroupSec">
                            <Col sm={4} className="formGroupTitleSec">
                              <FormGroup>
                                <Label for="pro_ctgry">Product Category<span className="text-danger">*</span></Label>
                              </FormGroup>
                            </Col>
                            <Col sm={8} className="formGroupFieldSec">
                              <Row>
                                <Col sm={6}>
                                  <FormGroup>
                                    <Label>{productCategory}</Label>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row className="formGroupSec">
                            <Col sm={4} className="formGroupTitleSec">
                              <FormGroup>
                                <Label for="">Upload Image<span className="text-danger">*</span><br /><span>(500px X 500px)</span><br /><Link className="d-none">Download Zip<br /><small>(All Images)</small></Link></Label>
                              </FormGroup>
                            </Col>
                            <Col sm={8} className="formGroupFieldSec">
                              <Row>
                                <Col sm={6}>
                                  <FormGroup>
                                    <Label>Image (Length)</Label>
                                    <div className="download-img-con">
                                      <UrlImageDownloader imageUrl={process.env.REACT_APP_IMAGE_BASE_URL + path + Limg} />
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col sm={6}>
                                  <FormGroup>
                                    <div>
                                      <Label className="size-title d-inline-flex justify-content-between mr-1">Length <span>:</span> </Label>
                                      <Label>{lengthInCms}</Label>
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm={6}>
                                  <FormGroup>
                                    <Label>Image (Breadth)</Label>
                                    <div className="download-img-con">
                                      <UrlImageDownloader imageUrl={process.env.REACT_APP_IMAGE_BASE_URL + path + Bimg} />
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col sm={6}>
                                  <FormGroup>
                                    <div>
                                      <Label className="size-title d-inline-flex justify-content-between mr-1">Breadth <span>:</span> </Label>
                                      <Label>{breadthInCms}</Label>
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm={6}>
                                  <FormGroup>
                                    <Label>Image (Height)</Label>
                                    <div className="download-img-con">
                                      <UrlImageDownloader imageUrl={process.env.REACT_APP_IMAGE_BASE_URL + path + Himg} />
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col sm={6}>
                                  <FormGroup>
                                    <div>
                                      <Label className="size-title d-inline-flex justify-content-between mr-1">Height <span>:</span> </Label>
                                      <Label>{heightInCms}</Label>
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm={6}>
                                  <FormGroup>
                                    <Label>Image (Weight)</Label>
                                    <div className="download-img-con">
                                      <UrlImageDownloader imageUrl={process.env.REACT_APP_IMAGE_BASE_URL + path + Weightimg} />
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col sm={6}>
                                  <FormGroup>
                                    <div>
                                      <Label className="size-title d-inline-flex justify-content-between mr-1">Weight <span>:</span> </Label>
                                      <Label>{merchanWeight}</Label>
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </ModalBody>)
                    })}</>}
                </Modal>
              </CardHeader>
              <Form onSubmit={filterWeightMismatchSubmit(onFilterWeightMismatch)} className="cardFilter">
                <Row className="mx-0 mb-3 mb-sm-0 justify-content-start justify-content-md-center">
                  <Col sm={6} md={5} lg={4} xl={3}>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="position-relative p-0">
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select {...filter("search_field")} className="form-control-sm form-control" onChange={(e) => { if (e.target.selectedOptions[0].text !== "Search By") { setSearchValue("Enter " + e.target.selectedOptions[0].text); } else { setSearchValue('') } }}>
                              <option value="Search By">Search By</option>
                              <option value="product_name">Product Name</option>
                              <option value="awb_number">AWB Number</option>
                              <option value="order_no">Order No</option>
                            </select>
                          </InputGroupText>
                        </InputGroupAddon>
                        <input {...filter("search_value")} type="text" className="form-control-sm form-control" placeholder={searchValue} />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} placeholder="Choose Upload Date" showOneCalendar={props.oneCalendar} format="dd-MM-yyyy" />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <MultiSelect
                        options={opt_Status}
                        value={Status}
                        onChange={setStatus}
                        labelledBy="Select Status"
                        overrideStrings={StatusMultiSelectSettings}
                        className={checkData(Status) && "active"}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <MultiSelect
                        options={opt_difference}
                        value={difference}
                        onChange={setDifference}
                        labelledBy="Select Status"
                        overrideStrings={DifferenceMultiSelectSettings}
                        className={checkData(difference) && "active"}
                      />
                    </FormGroup>
                  </Col>

                  {props.user[0].user_type === "Super" &&
                    <Col sm={6} md={4} lg={3} xl={2}>
                      <FormGroup>
                        <MultiSelect
                          options={opt_merchants}
                          value={merchants}
                          onChange={setMerchants}
                          labelledBy="Select Merchant"
                          overrideStrings={merchantsMultiSelectSettings}
                          className={checkData(merchants) && "active"}
                        />
                      </FormGroup>
                    </Col>}

                  <Col sm={3} className="btn-container">
                    <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" outline onClick={mismatchWeightListing}>Reset</Button>
                  </Col>
                </Row>
              </Form>
              {checkData(summaryData) &&
                <div className="statistics my-4">
                  <Row className="mx-auto" style={{ maxWidth: "950px" }}>
                    <Col>
                      <div className="media my-1">
                        <div className="avatar mr-3">
                          <span className="d-flex align-items-center justify-content-center avatar-content badge badge-info rounded-circle">
                            <FontAwesomeIcon icon={faBalanceScale} />
                          </span>
                        </div>
                        <div className="my-auto media-body">
                          <h6 className="font-weight-bolder mb-0 text-nowrap">{checkData(summaryData) && checkUndeNullBlank(summaryData.totalCount) ? <> {summaryData.totalCount} </> : ''}</h6>
                          <p className="font-small-3 mb-0 card-text fsc-3">Total Mismatch Cases</p>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="media my-1">
                        <div className="avatar mr-3">
                          <span className="d-flex align-items-center justify-content-center avatar-content badge badge-primary rounded-circle">
                            <FontAwesomeIcon icon={faBalanceScale} />
                          </span>
                        </div>
                        <div className="my-auto media-body">
                          <h6 className="font-weight-bolder mb-0 text-nowrap">{checkData(summaryData) && checkUndeNullBlank(summaryData.TotalChargedWeight) ? <> {summaryData.TotalChargedWeight} kg[s] </> : '0.00 kg[s]'}</h6>
                          <p className="font-small-3 mb-0 card-text fsc-3">Total Charged Weight</p>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="media my-1">
                        <div className="avatar mr-3">
                          <span className="d-flex align-items-center justify-content-center avatar-content badge badge-warning rounded-circle">
                            <FontAwesomeIcon icon={faBalanceScale} />
                          </span>
                        </div>
                        <div className="my-auto media-body">
                          <h6 className="font-weight-bolder mb-0 text-nowrap">{checkUndeNullBlank(summaryData.courierWeight) ? <> {summaryData.courierWeight} kg[s] </> : '0.00 kg[s]'}</h6>
                          <p className="font-small-3 mb-0 card-text fsc-3">Courier Weight</p>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="media my-1">
                        <div className="avatar mr-3">
                          <span className="d-flex align-items-center justify-content-center avatar-content badge badge-danger rounded-circle">
                            <FontAwesomeIcon icon={faBalanceScale} />
                          </span>
                        </div>
                        <div className="my-auto media-body">
                          <h6 className="font-weight-bolder mb-0 text-nowrap">{checkUndeNullBlank(summaryData.TotalChargedWeight) && checkUndeNullBlank(summaryData.courierWeight) ? <> {parseInt(summaryData.courierWeight) - parseInt(summaryData.TotalChargedWeight)} kg[s] </> : '0.00 kg[s]'}</h6>
                          <p className="font-small-3 mb-0 card-text fsc-3">Difference</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              }
              <CardBody className="p-0">
                <div className="showing-count">
                  {checkData(weightMismatch) && checkData(weightMismatch.data) && checkUndeNullBlank(weightMismatch.TotalRows) ?
                    <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(weightMismatch.data).length)} of {weightMismatch.TotalRows}</span> : ''}
                </div>
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      {props.user[0].user_type === "Super" &&
                        <th>Merchant</th>
                      }
                      <th>Shipment</th>
                      <th><div>Product</div></th>
                      <th>Courier<br />Disputed Weight</th>
                      <th>Courier<br />Final Weight</th>
                      <th>Difference</th>
                      <th>Date</th>
                      <th>Status</th>
                      {props.user[0].user_type === "Super" &&
                        <th>Processing Status</th>}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className='d-none'>
                    {checkData(weightMismatch.data) ? weightMismatch.data.map((item, index) => (
                      <tr key={index}>
                        {props.user[0].user_type === "Super" &&
                          <td className="w-some">{item.merchantName}</td>}
                        <td>
                          <div className="list-group-item border-0 p-0 d-inline-flex">
                            <div className="list-icon">
                              <div className="avatar">
                                {item.logo !== null && item.logo !== undefined ?
                                  <img className="img-fluid" src={process.env.REACT_APP_IMAGE_COURIER + item.logo} alt="logo" width="60" />
                                  : <span className="no-img d-flex align-items-center justify-content-center rounded-circle border font-weight-500"></span>}
                              </div>
                            </div>
                            <div className="list-content text-left">
                              <div className="">
                                <p className="mb-0">{(item.courierName)}</p>
                              </div>
                              <div className="d-flex">
                                <span className="mb-1 d-block text-primary text-left text-nowrap">AWB - {item.awb_no}</span>
                                <span onClick={() => copied(item.awb_no)} className="ml-1 copy-btn" id={`copy${index}`}>
                                  <Copy />
                                  <UncontrolledTooltip placement="bottom" target={`copy${index}`}>
                                    Copy
                                  </UncontrolledTooltip>
                                </span>
                              </div>
                              <div className="d-flex">
                                <span className="mb-1 d-block text-left text-nowrap">Order No - <Link target="_blank" to={`/order-detail/${item.order_id}`}>{item.order_no}</Link></span>
                                <span onClick={() => copied(item.order_no)} className="ml-1 copy-btn" id={`copyawb${index}`}>
                                  <Copy />
                                  <UncontrolledTooltip placement="bottom" target={`copyawb${index}`}>
                                    Copy
                                  </UncontrolledTooltip>
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div><div className="w-some" id={`MoreProduct${index}`}>{moreText(item.product_name)}</div>
                            <UncontrolledTooltip placement="top" target={`MoreProduct${index}`}>
                              {item.product_name}
                            </UncontrolledTooltip>
                          </div>
                          <div>Product Category : {item.product_category}</div>
                          <div>QTY : {item.product_qty}</div>
                        </td>
                        <td>{checkUndeNullBlank(item.charged_weight) ? item.charged_weight + 'kg' : ''}</td>
                        <td>{checkUndeNullBlank(item.courier_weight) ? item.courier_weight + 'Kg' : ''}</td>
                        <td className="text-nowrap">{(parseFloat(item.courier_weight - item.charged_weight).toFixed(2) + 'Kg')}</td>
                        <td className="text-nowrap">{changeIntoDateTime(item.created_at)}</td>
                        <td>
                          <div>
                            <span className="text-nowrap">{weight_mismatch_status[item.status]}</span><br />
                            {props.user[0].user_type === "Merchant" && <>
                              {moment().toISOString() >= after48hours(item.created_at) && checkUndeNullBlank(item.dispute_raised_on) !== true ?
                                <span className="text-nowrap">Auto Accepted</span> :
                                checkUndeNullBlank(item.dispute_raised_on) !== true && <span><Button outline className="ctm-btn btn-sm text-nowrap" color="primary" onClick={() => { toggleRaiseDispute(); setWeightReconId(item.id); }}>Raise A Dispute</Button></span>
                              }
                              {/* {checkUndeNullBlank(item.dispute_raised_on) &&
                          <span>Disputed</span>
                          } */}
                            </>}
                            {props.user[0].user_type === "Super" && checkUndeNullBlank(item.dispute_raised_on) &&
                              <ButtonGroup><Button outline className="ctm-btn btn-sm" color="success" onClick={() => rejectDispute(item.merchant_id, item.id, 3)}>Accept</Button>
                                <Button outline className="ctm-btn btn-sm" color="danger" onClick={() => rejectDispute(item.merchant_id, item.id, 4)}>Reject</Button></ButtonGroup>
                            }

                          </div>
                        </td>
                        {props.user[0].user_type === "Super" &&
                          <td>{checkUndeNullBlank(item.is_process) ? item.is_process === 1 ? "Processing..." : item.is_process === 0 ? "Completed" : '' : ''}</td>
                        }
                        <td>
                          <div className="btn-group-ctm text-nowrap">
                            {/* <span><Button className="ctm-btn btn-sm ml-2" color="info" outline onClick={() => toggleView(item.created_at,weight_mismatch_status[item.status])}>View History</Button></span> */}
                            {checkUndeNullBlank(item.dispute_raised_on) ? <span><Button className="ctm-btn btn-sm ml-2" color="info" outline onClick={() => viewRaiseDispute(item.id)}>View History</Button></span> :
                              <span><Button className="ctm-btn btn-sm ml-2" color="info" outline onClick={() => toggleAutoAccept(item.created_at, weight_mismatch_status[item.status])}>View History</Button></span>}
                          </div>
                        </td>
                      </tr>
                    )) : loadertable ? <tr><td colSpan="8" className="text-center"><LoaderDefault /></td></tr>
                      : <tr><td colSpan="8" className="text-center"><NodataFound /></td></tr>
                    }
                  </tbody>
                </Table>

                {checkData(weightMismatch) && checkData(weightMismatch.data) && checkUndeNullBlank(weightMismatch.TotalRows) ?
                  <>
                    {weightMismatch.TotalRows > dropFilterCount ?
                      <Row className="m-0 table-footer">
                        <Col xs="6">
                          <Pagination aria-label="Page navigation example align-items-center">
                            <PaginationItem className="prev">
                              <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                            </PaginationItem>
                            <PaginationItem className="next ml-3">
                              <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </Col>
                        <Col xs="6">
                          <Form>
                            <FormGroup>
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                                <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="250">250</option>
                              </select>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row> : ''}
                  </>
                  : ''}
              </CardBody>
            </Card>
          </Card>
        </Col>

      </Row>
    </div>
  );
}

function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}

export default connect(mapDispatchToProps)(WeightReconcilation);