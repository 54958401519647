import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseString } from '../config.js';
import Swal from 'sweetalert2';
import { CustomInput, Button, CardTitle, CardBody, Table } from 'reactstrap';


function SmsService(props) {
  const [allBrand, setAllBrand] = useState([]);
  const [brandState, setBrandState] = useState({});


  const fetchBrand = async () => {
    try {
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_MERCHANT_BRANDS}`,
        data: { merchant_id: props.merchantId },
        headers: { token: window.btoa(baseString) },
      });

      if (response.data.data) {
        const brands = response.data.data;
        setAllBrand(brands);

       
        const initialState = brands.reduce((acc, brand) => {
          acc[brand.id] = { orderPlaced: 0, shortAddress: 0 };
          return acc;
        }, {});
        setBrandState(initialState);
      }
    } catch (err) {
      Swal.fire({
        title: 'Error',
        text: err?.message || 'Something went wrong while fetching brands',
        icon: 'error',
      });
    }
  };

 
  const fetchSavedSettings = async () => {
    try { 
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_MERCHANT_GET_MERCHANT_SETTING}`,
        data: { merchant_id: props.merchantId },
      });

      if (response.data.data) {
        const savedSettings = response.data.data.reduce((acc, setting) => {
          acc[setting.brand_id] = {
            orderPlaced: setting.orderPlaced_status,
            shortAddress: setting.shortAddress_status,
          };
          return acc;
        }, {});

        
        setBrandState((prevState) => ({
          ...prevState,
          ...savedSettings,
        }));
      }
    } catch (err) {
      Swal.fire({
        title: 'Error',
        text: err?.message || 'Error fetching settings',
        icon: 'error',
      });
    }
  };


  useEffect(() => {
    fetchBrand();
    fetchSavedSettings();
  }, []);

 
  const handlePlaceOrder = (brandId) => {
    setBrandState((prevState) => ({
      ...prevState,
      [brandId]: {
        ...prevState[brandId],
        orderPlaced: prevState[brandId].orderPlaced === 0 ? 1 : 0,
      },
    }));
  };

  const handleShortAddress = (brandId) => {
    setBrandState((prevState) => ({
      ...prevState,
      [brandId]: {
        ...prevState[brandId],
        shortAddress: prevState[brandId].shortAddress === 0 ? 1 : 0,
      },
    }));
  };

  const handleSave = async (brandId) => {
    const data1 = {
      brand_id: brandId,
      orderPlaced_status: brandState[brandId].orderPlaced,
      shortAddress_status: brandState[brandId].shortAddress,
    };

    const formData = {
      merchant_id: props.merchantId,
      setting_id: '6',
      brand_settings: [data1],
    };

    try {
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_MERCHANT_MERCHANT_SMS_SETTING}`,
        data: formData,
        headers: { token: window.btoa(baseString) },
      });

      if (response.data.error === false) {
        Swal.fire({
          title: 'Success',
          text: response.data.msg || 'Data Saved Successfully',
          icon: 'success',
        });
        fetchSavedSettings();
      } else {
        Swal.fire({
          title: 'Error',
          text: response.data.msg || 'Something went wrong',
          icon: 'error',
        });
      }
    } catch (err) {
      Swal.fire({
        title: 'Error',
        text: err?.message || 'Something went wrong',
        icon: 'error',
      });
    }
  };

  return (
    <CardBody className="p-0">
      <div className="sub-page-header">
        <CardTitle tag="h4" className="sub-page-title">
          SMS Service
        </CardTitle>
      </div>
      <Table responsive className="custom-table">
        <thead className="text-center" style={{ backgroundColor: 'rgba(27, 90, 143, 0.1)' }}>
          <tr>
            <th className="text-nowrap">Brand ID</th>
            <th className="text-nowrap">Brand Name</th>
            <th className="text-nowrap">Order Placed</th>
            <th className="text-nowrap">Short Address</th>
            <th className="text-nowrap">Action</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {allBrand?.map((brand) => (
            <tr key={brand.id}>
              <td>{brand.id}</td>
              <td>{brand.brand_name}</td>
              <td>
                <CustomInput
                  type="checkbox"
                  id={`orderPlaced${brand.id}`}
                  name={`orderPlaced${brand.id}`}
                  checked={brandState[brand.id]?.orderPlaced === 1}
                  onChange={() => handlePlaceOrder(brand.id)}
                />
              </td>
              <td>
                <CustomInput
                  type="checkbox"
                  id={`shortAddress${brand.id}`}
                  name={`shortAddress${brand.id}`}
                  checked={brandState[brand.id]?.shortAddress === 1}
                  onChange={() => handleShortAddress(brand.id)}
                />
              </td>
              <td>
                <Button
                  className="btn-sm btn btn-primary"
                  onClick={() => handleSave(brand.id)}
                >
                  Submit
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </CardBody>
  );
}

export default SmsService;
