import React, { useState, useEffect } from 'react';
import { Row, Col, Button, CardTitle, FormGroup, Form, Label, Collapse, Spinner } from 'reactstrap';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Swal from 'sweetalert2';
import UrlImageDownloader from "react-url-image-downloader";
import { extension, errorHandling } from '../../utlis';
import { checkUndeNullBlank } from '../../ObjectExist';
import { baseString } from '../../config';


const KycDetails = (props) => {

    const [kycDetails, setKycDetails] = useState();
    const [loader, setLoader] = useState(false);

    const [gstCopy, setGstCopy] = useState('');
    const [panCopy, setPanCopy] = useState('');
    const [directorpanCopy, setDirectorPanCopy] = useState('');
    const [adharCopy, setAdharCopy] = useState('');
    const { register: kycInput, handleSubmit: kycSubmit, formState: { errors: errorskyc }, reset: resetKyc } = useForm();


    const [isIndividual, setIsIndividual] = useState(false);
    const [isCompany, setIsCompany] = useState(false);

    const toggleIndividual = () => {
        setIsIndividual(true);
        if (isCompany) {
            setIsCompany(false);
        }
    };
    const toggleCompany = () => {
        setIsCompany(true);
        if (isIndividual) {
            setIsIndividual(false);
        }
    };

    useEffect(() => {
        viewRegulatoryDetails();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetKyc])

    const viewRegulatoryDetails = () => {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_MERCHANT_REGULATRYDETAILS}/view`,
            data: { "merchant_id": props.merchantId },
            headers: { "token": window.btoa(baseString) }
        })
            .then(function (response) {
                let myResponse = response.data[0];
                setKycDetails(myResponse);
                checkUndeNullBlank(props.setKycDetails) && props.setKycDetails(myResponse)
                if (myResponse !== undefined) {

                    myResponse.merchant_type = String(myResponse.merchant_type)
                    resetKyc(myResponse);

                    if (myResponse.merchant_type === 1 || myResponse.merchant_type === "1") {

                        myResponse.adhar_card_no_individual = myResponse.adhar_card_no;
                        myResponse.pan_card_no_individual = myResponse.pan_card_no;
                        myResponse.gst_number_individual = myResponse.gst_number;

                        resetKyc(myResponse);
                        toggleIndividual();

                    } else {
                        myResponse.gst_number_company = myResponse.gst_number;
                        myResponse.pan_card_no_company = myResponse.pan_card_no;
                        myResponse.pan_card_no_director_company = myResponse.pan_card_no_director;

                        resetKyc(myResponse);
                        toggleCompany();
                    }
                }

            })
            .catch(function (err) {
                errorHandling(err, "reload");
            });
    }

    const handleFileInputAdhar = (e) => {
        setAdharCopy(e.target.files[0])
    }
    const handleFileInputpan = (e) => {
        setPanCopy(e.target.files[0])
    }
    const handleFileInputpandirector = (e) => {
        setDirectorPanCopy(e.target.files[0])
    }
    const handleFileInputgst = (e) => {
        setGstCopy(e.target.files[0])
    }
    const onKyc = (data) => {
        setLoader(true);
        let formData = new FormData();
        formData.append("merchant_id", props.merchantId);
        if (data.merchant_type === '2') {
            formData.append("pan_card_no", data.pan_card_no_company);
            formData.append("pan_card_no_director", data.pan_card_no_director_company);
            formData.append("pan_card", panCopy !== '' ? panCopy : kycDetails !== undefined && kycDetails.pan_card !== undefined && kycDetails.pan_card !== "" ? kycDetails.pan_card : 'NA');
            formData.append("pan_card_director", directorpanCopy !== '' ? directorpanCopy : kycDetails !== undefined && kycDetails.pan_card_director !== undefined && kycDetails.pan_card_director !== "" ? kycDetails.pan_card_director : 'NA');
            formData.append("gst_number", data.gst_number_company);
            formData.append("gst_file", gstCopy !== '' ? gstCopy : kycDetails !== undefined && kycDetails.gst_file !== undefined && kycDetails.gst_file !== "" ? kycDetails.gst_file : 'NA');
        } else {
            formData.append("adhar_card_no", data.adhar_card_no_individual);
            formData.append("adhar_card_file", adharCopy !== '' ? adharCopy : kycDetails !== undefined && kycDetails.adhar_card_file !== undefined && kycDetails.adhar_card_file !== "" ? kycDetails.adhar_card_file : 'NA');
            formData.append("gst_number", data.gst_number_individual);
            formData.append("gst_file", gstCopy !== '' ? gstCopy : kycDetails !== undefined && kycDetails.gst_file !== undefined && kycDetails.gst_file !== "" ? kycDetails.gst_file : 'NA');
            formData.append("pan_card_no", data.pan_card_no_individual);
            formData.append("pan_card", panCopy !== '' ? panCopy : kycDetails !== undefined && kycDetails.pan_card !== undefined && kycDetails.pan_card !== "" ? kycDetails.pan_card : 'NA');
        }
        formData.append("merchant_type", data.merchant_type);
        axios({
            method: "post",
            url: process.env.REACT_APP_MERCHANT_REGULATRYDETAILS,
            data: formData,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            setLoader(false);
            if (res.data.error === false) {
                Swal.fire({
                    title: 'Success',
                    text: res.data.msg,
                    icon: 'success',
                })
                viewRegulatoryDetails();
            }
            if (res.data.error === true) {
                Swal.fire({
                    title: 'Error',
                    text: res.data.msg,
                    icon: 'error',
                })
            }
        })
            .catch((err) => {
                setLoader(false);
                errorHandling(err, "");
            });
    };


    return (
        <>
            {loader && <div className="formLoader"><Spinner /></div>}
            <Form onSubmit={kycSubmit(onKyc)}>
                <div className="sub-page-header">
                    <CardTitle tag="h4" className="sub-page-title">KYC Details</CardTitle>
                </div>
                <Row>
                    <Col sm={12}>
                        <FormGroup>
                            <div className="d-flex">
                                <span className="form-check2 mr-4">
                                    <input type="radio" {...kycInput("merchant_type", { required: 'Please Select Company Type' })} value="1" className="mx-2" onClick={toggleIndividual} id="individual" />
                                    <Label for="individual">Individual</Label>
                                </span>
                                <span className="form-check2">
                                    <input type="radio" {...kycInput("merchant_type", { required: 'Please Select Company Type' })} value="2" className="mx-2" onClick={toggleCompany} id="company" />
                                    <Label for="company">Company</Label>
                                </span>
                            </div>
                            {errorskyc.merchant_type && <span className="text-danger d-block error">{errorskyc.merchant_type.message}</span>}
                        </FormGroup>
                    </Col>
                    <Collapse isOpen={isCompany}>
                        {isCompany &&
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label for="gst_no">GST Number<span className="text-danger">*</span></Label>
                                        <input {...kycInput("gst_number_company", { required: 'Please Enter GST Number' })} className="form-control" placeholder="GST Number" id="gst_no" />
                                        {errorskyc.gst_number_company && <span className="text-danger d-block error">{errorskyc.gst_number_company.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label for="gst_img">Upload GST image<span className="text-danger">*</span></Label>
                                        <input {...kycInput("gst_files_company", { required: kycDetails !== undefined && kycDetails.gst_file !== undefined && kycDetails.gst_file !== "" && kycDetails.merchant_type === "2" ? false : true && 'Please Add GST Copy' })} type="file" onChange={handleFileInputgst} className="form-control-sm form-control" id="uploadgst" />
                                        {kycDetails !== undefined && kycDetails.gst_file !== undefined && kycDetails.gst_file !== null && kycDetails.gst_file !== "" && kycDetails.merchant_type === "2" &&
                                            <div className={`edit-brand-logo download-img-con ${extension(kycDetails.gst_file) === "pdf" && 'pdf-con'} mt-2`}>
                                                {extension(kycDetails.gst_file) === "pdf" && <span className="pdf-file">{kycDetails.gst_file}</span>}
                                                <UrlImageDownloader imageUrl={process.env.REACT_APP_IMAGE_MERCHANT + kycDetails.gst_file} />
                                            </div>}
                                        {errorskyc.gst_files_company && <span className="text-danger d-block error">{errorskyc.gst_files_company.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label for="pan_no">Pan Card No. of Business<span className="text-danger">*</span></Label>
                                        <input {...kycInput("pan_card_no_company", { required: 'Please Enter Pan Card Number' })} className="form-control" placeholder="Pan Card No. of Business" id="pan_no" />
                                        {errorskyc.pan_card_no_company && <span className="text-danger d-block error">{errorskyc.pan_card_no_company.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label for="gst_img">Upload Pan Card of Business<span className="text-danger">*</span></Label>
                                        <input {...kycInput("pan_card_file_company", { required: kycDetails !== undefined && kycDetails.pan_card !== undefined && kycDetails.pan_card !== "" && kycDetails.merchant_type === "2" ? false : true && 'Please Add Pan Card Copy' })} type="file" onChange={handleFileInputpan} className="form-control-sm form-control" id="uploadPan" />
                                        {kycDetails !== undefined && kycDetails.pan_card !== undefined && kycDetails.pan_card !== null && kycDetails.pan_card !== "" && kycDetails.merchant_type === "2" &&
                                            <div className={`edit-brand-logo download-img-con ${extension(kycDetails.pan_card) === "pdf" && 'pdf-con'} mt-2`}>
                                                {extension(kycDetails.pan_card) === "pdf" && <span className="pdf-file">{kycDetails.pan_card}</span>}
                                                <UrlImageDownloader imageUrl={process.env.REACT_APP_IMAGE_MERCHANT + kycDetails.pan_card} />
                                            </div>}
                                        {errorskyc.pan_card_file_company && <span className="text-danger d-block error">{errorskyc.pan_card_file_company.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label for="pan_no_director">Pan Card No. / Adhar Card No.<span className="text-danger">*</span></Label>
                                        <input {...kycInput("pan_card_no_director_company", { required: 'Please Enter Pan Card Number' })} className="form-control" placeholder="Pan Card No. / Adhar Card No." id="pan_no_director" />
                                        {errorskyc.pan_card_no_director_company && <span className="text-danger d-block error">{errorskyc.pan_card_no_director_company.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label for="pan_img_director">Upload Pan Card / Upload Adhar Card<span className="text-danger">*</span></Label>
                                        <input {...kycInput("pan_card_file_director_company", { required: kycDetails !== undefined && kycDetails.pan_card_director !== undefined && kycDetails.pan_card_director !== "" && kycDetails.merchant_type === "2" ? false : true && 'Please Add Pan Card Copy' })} type="file" onChange={handleFileInputpandirector} className="form-control-sm form-control" id="pan_img_director" />
                                        {kycDetails !== undefined && kycDetails.pan_card_director !== undefined && kycDetails.pan_card_director !== null && kycDetails.pan_card_director !== "" && kycDetails.merchant_type === "2" &&
                                            <div className={`edit-brand-logo download-img-con ${extension(kycDetails.pan_card_director) === "pdf" && 'pdf-con'} mt-2`}>
                                                {extension(kycDetails.pan_card_director) === "pdf" && <span className="pdf-file">{kycDetails.pan_card_director}</span>}
                                                <UrlImageDownloader imageUrl={process.env.REACT_APP_IMAGE_MERCHANT + kycDetails.pan_card_director} />
                                            </div>}
                                        {errorskyc.pan_card_file_director_company && <span className="text-danger d-block error">{errorskyc.pan_card_file_director_company.message}</span>}
                                    </FormGroup>
                                </Col>
                            </Row>
                        }
                    </Collapse>
                    <Collapse isOpen={isIndividual}>
                        {isIndividual &&
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label for="adhar_card_no">Aadhar Card No.<span className="text-danger">*</span></Label>
                                        <input {...kycInput("adhar_card_no_individual", {
                                            required: 'Please Enter Aadhar Card No.',
                                            pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Aadhar Card No.' }
                                        })} className="form-control" placeholder="Aadhar Card No." id="adhar_card_no" />
                                        {errorskyc.adhar_card_no_individual && <span className="text-danger d-block error">{errorskyc.adhar_card_no_individual.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label for="adhar_file">Upload Aadhar Card<span className="text-danger">*</span></Label>
                                        <input {...kycInput("adhar_file_individual", { required: kycDetails !== undefined && kycDetails.adhar_card_file !== undefined && kycDetails.adhar_card_file !== "" && kycDetails.merchant_type === "1" ? false : true && 'Please Add Aadhar Card Copy' })} type="file" className="form-control-sm form-control" id="uploadAdhar" onChange={handleFileInputAdhar} />
                                        {kycDetails !== undefined && kycDetails.adhar_card_file !== undefined && kycDetails.adhar_card_file !== null && kycDetails.adhar_card_file !== "" && kycDetails.merchant_type === "1" &&
                                            <div className={`edit-brand-logo download-img-con ${extension(kycDetails.adhar_card_file) === "pdf" && 'pdf-con'} mt-2`}>
                                                {extension(kycDetails.adhar_card_file) === "pdf" && <span className="pdf-file">{kycDetails.adhar_card_file}</span>}
                                                <UrlImageDownloader imageUrl={process.env.REACT_APP_IMAGE_MERCHANT + kycDetails.adhar_card_file} />
                                            </div>}
                                        {errorskyc.adhar_file_individual && <span className="text-danger d-block error">{errorskyc.adhar_file_individual.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label for="gst_no">GST Number</Label>
                                        <input {...kycInput("gst_number_individual", { required: false })} className="form-control" placeholder="GST Number" id="gst_no" />
                                        {/* {errorskyc.gst_number_individual && <span className="text-danger d-block error">{errorskyc.gst_number_individual.message}</span>} */}
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label for="gst_file">Upload GST Or Principal Place of Business<span className="text-danger">*</span></Label>
                                        <input {...kycInput("gst_files_individual", { required: kycDetails !== undefined && kycDetails.gst_file !== undefined && kycDetails.gst_file !== "" && kycDetails.merchant_type === "1" ? false : true && 'Please Upload GST Or Principal Place of Business Copy' })} type="file" className="form-control-sm form-control" id="gst_files" onChange={handleFileInputgst} />
                                        {kycDetails !== undefined && kycDetails.gst_file !== undefined && kycDetails.gst_file !== null && kycDetails.gst_file !== "" && kycDetails.merchant_type === "1" &&
                                            <div className={`edit-brand-logo download-img-con ${extension(kycDetails.gst_file) === "pdf" && 'pdf-con'} mt-2`}>
                                                {extension(kycDetails.gst_file) === "pdf" && <span className="pdf-file">{kycDetails.gst_file}</span>}
                                                <UrlImageDownloader imageUrl={process.env.REACT_APP_IMAGE_MERCHANT + kycDetails.gst_file} />
                                            </div>}
                                        {errorskyc.gst_files_individual && <span className="text-danger d-block error">{errorskyc.gst_files_individual.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label for="pan_no">Pan Card No.<span className="text-danger">*</span></Label>
                                        <input {...kycInput("pan_card_no_individual", { required: 'Please Enter Pan Card Number' })} className="form-control" placeholder="Pan Card No." id="pan_no" />
                                        {errorskyc.pan_card_no_individual && <span className="text-danger d-block error">{errorskyc.pan_card_no_individual.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label for="gst_img">Upload Pan Card<span className="text-danger">*</span></Label>
                                        <input {...kycInput("pan_card_file_individual", { required: kycDetails !== undefined && kycDetails.pan_card !== undefined && kycDetails.pan_card !== "" && kycDetails.merchant_type === "1" ? false : true && 'Please Add Pan Card Copy' })} type="file" onChange={handleFileInputpan} className="form-control-sm form-control" id="uploadPan" />
                                        {kycDetails !== undefined && kycDetails.pan_card !== undefined && kycDetails.pan_card !== null && kycDetails.pan_card !== "" && kycDetails.merchant_type === "1" &&
                                            <div className={`edit-brand-logo download-img-con ${extension(kycDetails.pan_card) === "pdf" && 'pdf-con'} mt-2`}>
                                                {extension(kycDetails.pan_card) === "pdf" && <span className="pdf-file">{kycDetails.pan_card}</span>}
                                                <UrlImageDownloader imageUrl={process.env.REACT_APP_IMAGE_MERCHANT + kycDetails.pan_card} />
                                            </div>}
                                        {errorskyc.pan_card_file_individual && <span className="text-danger d-block error">{errorskyc.pan_card_file_individual.message}</span>}
                                    </FormGroup>
                                </Col>
                            </Row>
                        }
                    </Collapse>
                    <Col sm={12} className="mt-2 text-right">
                        <Button className="ctm-btn btn-sm" color="primary"> Save</Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

export default KycDetails;
