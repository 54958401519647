import React, { useState,useEffect } from 'react';
import { Row, Col, Label, Form, FormGroup, CardSubtitle, CardTitle, Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import axios from 'axios';
import { checkData, checkUndeNullBlank } from '../../../ObjectExist';
import { errorHandling } from '../../../utlis';
 import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { baseString } from '../../../config';
 
const AgrementDetail = (props) => {
    const [Agrementdetail, setAgrementdetail] = useState({});
    let history = useHistory();

     
     useEffect(() => {
        let data = { "merchant_id": props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ? props.user[0].id : '', }
       
        axios({
          method: "post",
          url: `${process.env.REACT_APP_MERCHANT_AGREMENT_SETTINGS_VIEW}`,
          data: data,
          headers: { "token": window.btoa(baseString) }
        }).then((res) => {
          if (res.data !== undefined && res.data.fatal !== true) {
            if (parseInt(res.data.errno) > 0) {
              if (res.data.sqlMessage !== undefined) {
                Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
              }
            } else {
              if (res.data.error === false) {
                // console.log(res.data.data[0]);

                if(res.data!=undefined && res.data.data!=undefined && res.data.data[0]!=undefined){
                    setAgrementdetail(res.data.data[0]);
                }
                
              }
            }
          }
        }).catch((err) => {
          errorHandling(err, "reload");
        });

 
      },[])
   
    
    return (
        <div>
            <div className="sub-page-header">
                <CardTitle tag="h4" className="sub-page-title">Agrement Status</CardTitle>
            </div>
            <Row className="pt-1">
                <Col sm={6}>
                    <FormGroup>
                        <CardSubtitle tag="h6" className="mb-2">Agrement Status</CardSubtitle>
                        <Label for="full_name">{(Agrementdetail!=undefined &&  Agrementdetail.status!=undefined && Agrementdetail.status == 1)?'Accepted':'Not Accept' }</Label>
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <CardSubtitle tag="h6" className="mb-2">Accepted On</CardSubtitle>
                        <Label for="full_name">{props.user[0].email || 'NAN'}</Label>
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <CardSubtitle tag="h6" className="mb-2">Agrement Link</CardSubtitle>
                        <Label for="full_name">{props.user[0].mobile || 'NAN'}</Label>
                    </FormGroup>
                </Col>
                
                <Col sm={12} className="mt-2">
                    <Button className="btn-sm ctm-btn" color="primary">Accept Agrement</Button>
                </Col>
            </Row>

        </div>
    );
}

export default AgrementDetail;