import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, Input, FormGroup, Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Spinner } from 'reactstrap';
import { DateRangePicker } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { ChevronLeft, ChevronRight } from 'react-feather';
import axios from 'axios';
import Swal from 'sweetalert2';
import LoaderDefault from '../LoaderDefault';
import NodataFound from '../NodataFound';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { changeIntoDate, errorHandling, remExtraSpace } from '../../utlis';
import { useHistory } from 'react-router-dom';
import { baseString, download_delay } from '../../config';
import moment from 'moment';

const RemittanceTransactions = (props) => {

  const [merchants, setMerchants] = useState([]);
  const [transactions, setTransactions] = useState({});
  const [loader, setLoader] = useState(false);
  const [loadertable, setLoadertable] = useState(false);
  const [filterDate, setFilterDate] = useState([]);
  const [selectperpage, setSelectperpage] = useState(20);
  const [page, setPage] = useState(1);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState('');

  const dropFilterCount = 20
  const history = useHistory();

  let dateRangeRef = dateRange => dateRangeRef = dateRange;
  const onDateChange = (e) => {
    setFilterDate(e.text);
  };

  var opt_merchants = [];
  if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)) {
    Object.keys(props.state.shipmentMerchantCourier.merchants).map(function (key) {
      let merchantName = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchantName) && props.state.shipmentMerchantCourier.merchants[key].merchantName
      let merchantId = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchant_id) && props.state.shipmentMerchantCourier.merchants[key].merchant_id
      let tempObj = {}
      if (merchantName && merchantId) {
        tempObj = { label: merchantName, value: merchantId }
        opt_merchants.push(tempObj)
      }
      return true;
    });
  }

  const transactiosLIsting = () => {
    setMerchants([]);
    setFilterDate([]);
    resetFilter();

    let data = {}

    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    setLoadertable(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_REMITTANCE_TRANSACTIONS}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoadertable(false)
      if (res.data.error === false) {
        setTransactions(res.data)
      }
    }).catch((err) => {
      setLoadertable(false)
      errorHandling(err, "reload");
    });
  }

  useEffect(() => {
    transactiosLIsting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }

  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(transactions.data)
      if (currentCount > Object.entries(transactions.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }

  function nextPageData() {
    if (checkData(transactions.data) && checkUndeNullBlank(transactions.totalPage)) {
      if (transactions.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(transactions.data).length)
        setPage(page + 1)
        setLoader(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }

  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };

  // filter remittance transactions
  const { register: filter, handleSubmit, reset: resetFilter } = useForm();

  const onSubmit = (data, pagePerpage) => {
    pagePerpage.page = 1
    if (checkData(filterDate) || checkData(merchants) || checkUndeNullBlank(data.transaction_id)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
      Swal.fire({ title: 'info', text: 'Please Choose Filter', icon: 'info', })
    }

  };

  const onCommonFilter = (data, pagePerpage) => {
    setLoader(true)
    let byData = {}
    let merchantsId = [];

    if (checkData(merchants)) {
      merchants.map((item) => (
        merchantsId.push(item.value)
      ));
    }

    byData = { "ref_no": checkUndeNullBlank(data.transaction_id) ? remExtraSpace(data.transaction_id) : '', "merchant_ids": checkUndeNullBlank(merchantsId) ? merchantsId : '', "start_date": checkData(filterDate) ? moment(filterDate[0]).format('YYYY-MM-DD') : '', "end_date": checkData(filterDate) ? moment(filterDate[1]).format('YYYY-MM-DD') : '' }
    setFilterInput(byData);
    if (props.user[0].user_type === "Super") {
      byData.admin_id = 1
    } else {
      byData.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_REMITTANCE_TRANSACTIONS}?page=${checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: byData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setTransactions(res.data);
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "");
      setLoader(false);
    });
  };


  const exportRemmitance = (date, tran_id) => {
    var dates = date.split("-");
    var dates2 = dates[2] + "-" + dates[1] + "-" + dates[0];

    let data = { "pay_status": 1, "trans_date": checkUndeNullBlank(date) ? dates2 : '' }

    data.ref_no = checkUndeNullBlank(tran_id) ? tran_id : '';


    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }

    data.is_export = 1
    setLoader(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_REMITTANCE_LIST}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      if (res.data !== undefined && res.data.fatal !== true && res.data !== true) {
        if (parseInt(res.data.errno) > 0) {
          setLoader(false);
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          if (res.data.is_export === 1) {
            setTimeout(function () {
              setLoader(false);
              history.push("/my-downloads");
            }, download_delay);
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "")
    });

  }

  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
        <Col sm={12}>
          <Card className="p-0 my-3 CourierRemittance shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex justify-content-between flex-wrap">
                <CardTitle className="page-title">Remittance Paid</CardTitle>
              </CardHeader>
              <Form onSubmit={handleSubmit(onSubmit)} className="cardFilter">
                <Row className="mx-0 mb-3 mb-sm-0 justify-content-start">
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} format="dd-MM-yyyy" showOneCalendar={props.oneCalendar} placement="bottomStart" ref={dateRangeRef} change={onDateChange} placeholder="Choose Tran. Date Range" />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <input {...filter("transaction_id")} type="text" className="form-control-sm form-control" placeholder="Enter Transaction ID" />
                    </FormGroup>
                  </Col>
                  <Col sm={3} className="btn-container">
                    <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" outline onClick={transactiosLIsting}>Reset</Button>
                  </Col>
                </Row>
              </Form>
              <CardBody className="p-0">
                <div className="showing-count">
                  {checkData(transactions) && checkData(transactions.data) && checkUndeNullBlank(transactions.TotalRows) ?
                    <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(transactions.data).length)} of {transactions.TotalRows}</span> : ''}
                </div>
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      <th className="text-nowrap">Transaction ID</th>
                      <th className="text-nowrap">Transaction Date</th>
                      <th className="text-nowrap">Remittance Amount</th>
                      <th className="text-nowrap">Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkData(transactions.data) ? transactions.data.map((item, index) => (
                      <tr key={index}>
                        <td>{item.ref_no}</td>
                        <td>{changeIntoDate(item.trans_date)}</td>
                        <td>{item.credit_amt}</td>
                        <td><Button className="btn btn-sm text-nowrap" color="info" outline onClick={() => { exportRemmitance(changeIntoDate(item.trans_date), item.ref_no) }}>Download Excel</Button></td>
                      </tr>
                    )) :
                      loadertable ? <tr><td colSpan="8" className="text-center"><LoaderDefault /></td></tr>
                        : <tr><td colSpan="8" className="text-center"><NodataFound /></td></tr>}
                  </tbody>
                </Table>
                {checkData(transactions) && checkData(transactions.data) && checkUndeNullBlank(transactions.TotalRows) ?
                  <>
                    {transactions.TotalRows > dropFilterCount ?
                      <Row className="m-0 table-footer">
                        <Col xs="6">
                          <Pagination>
                            <PaginationItem className="prev">
                              <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                            </PaginationItem>
                            <PaginationItem className="next ml-3">
                              <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </Col>
                        <Col xs="6">
                          <Form>
                            <FormGroup>
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <Input type="select" name="select" value={selectperpage} onChange={handleSelectChange} className="form-control-sm">
                                <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </Input>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row> : ''}
                  </>
                  : ''}
              </CardBody>
            </Card>
          </Card>
        </Col>

      </Row>
    </div>
  );
}

function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}

export default connect(mapDispatchToProps)(RemittanceTransactions);