import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, CardHeader, CardSubtitle, Table, Spinner, UncontrolledTooltip, Badge } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { delivery_type_id, channel_order_status, baseString } from '../../config';
import { changeIntoDate, changeIntoDateTime, errorHandling } from '../../utlis';
import { ToastContainer } from 'react-toastify';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import NodataFound from '../NodataFound';
import LoaderDefault from '../LoaderDefault';

const ChannelOrderDetail = (props) => {
    const { orderId } = useParams();
    const [orderDetail, setOrderDetail] = useState({});
    const [orderStatusLogs, setOrderStatusLogs] = useState({});
    const [multipleProductDetails, setmultipleProductDetails] = useState({});
    const [loader, setLoader] = useState(false);
    const [orderLogLoader, setOrderLogLoader] = useState(false);
    const statusLog = () => {
        setOrderLogLoader(true);
        let data = {}
        data = { "order_id": orderId }
        axios({
            method: "post",
            url: `${process.env.REACT_APP_CHANNEL_ORDER_STATUS_LOG}`,
            data: data,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            setOrderLogLoader(false);
            if (res.data.error === false) {
                setOrderStatusLogs(res.data.data);
            } else {
                Swal.fire({ icon: 'error', text: res.data.msg, title: 'Error' });

            }
        }).catch((err) => {
            setOrderLogLoader(false);
            errorHandling(err, "")
        });
    }
    useEffect(() => {
        setLoader(true);
        let data = {}
        data = { "order_id": orderId }
        axios({
            method: "post",
            url: `${process.env.REACT_APP_CHANNEL_ORDER_DETAIL}`,
            data: data,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            setLoader(false);
            if (res.data.error === false) {
                setOrderDetail(res.data.data.orderDetail);
                let multipleProducts=(res.data.data.orderDetail!==undefined && res.data.data.orderDetail.product_category!==undefined)?res.data.data.orderDetail.product_category:"";
                //console.log("multipleProducts",multipleProducts);
              
                if(multipleProducts!=="" && multipleProducts!=="NA" && multipleProducts!=="-"){
                    setmultipleProductDetails(JSON.parse(multipleProducts));
                }
                

            } else {
                Swal.fire({ icon: 'error', text: res.data.msg, title: 'Error' });

            }
        }).catch((err) => {
            setLoader(false);
            errorHandling(err, "")
        });
        statusLog();
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [])

    let history = useHistory();
    const goToPreviousPath = () => {
        if (checkUndeNullBlank(history.location.key)) {
            history.goBack();
        } else {
            history.push('/channel-orders');
        }
    }


    return (
        <div className="app-content content overflow-hidden">
            <div className="OrderDetail content-wrapper">
                <Row>
                    <Col md={12}>
                        <Card className="p-0 my-3 shadow border-0">
                            <Card className="border-0">
                                <CardHeader className="page-header d-flex justify-content-between flex-wrap">
                                    <CardTitle className="page-title">Order Detail</CardTitle>
                                    <div className="card-header-right d-flex flex-wrap">
                                        <div onClick={goToPreviousPath} className="btn-text btn-light"><span className="icon"><ArrowLeft /></span><span>Back</span></div>
                                    </div>
                                </CardHeader>
                                {checkData(orderDetail) ?
                                    <CardBody>
                                        <div className="d-flex justify-content-between flex-wrap mb-4">
                                            <div>
                                                {checkUndeNullBlank(orderDetail.MerchantPickupPointName) &&
                                                    <CardTitle className="mb-0"><span className="font-weight-500"> Pickup Point Name - {orderDetail.MerchantPickupPointName}</span>
                                                    </CardTitle>
                                                }
                                            </div>
                                            <ToastContainer style={{ width: "200px" }} />
                                            <div>
                                                <CardTitle className="mb-0">ORDER NO. - <span className="font-weight-500">{orderDetail.order_no ? orderDetail.order_no : "NA"}</span></CardTitle>
                                            </div>
                                        </div>
                                        <Row>
                                            <Col sm={6} className="mb-4 mb-sm-0">
                                                <CardHeader className="mb-2 bg-card-header">
                                                    <CardTitle className="mb-0">Customer Detail</CardTitle>
                                                </CardHeader>
                                                <Row className="align-items-center mb-1">
                                                    <Col xs={6} sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Mobile No.</CardSubtitle>
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        <p className="card-text mb-0">{orderDetail.customer_mobile}</p>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center mb-1">
                                                    <Col xs={6} sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Email ID</CardSubtitle>
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        <p className="card-text mb-0">{orderDetail.customer_email}</p>
                                                    </Col>
                                                </Row>
                                                {checkUndeNullBlank(orderDetail.customer_alt_no) && <Row className="align-items-center mb-1">
                                                    <Col xs={6} sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Alternate No.</CardSubtitle>
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        <p className="card-text mb-0">{orderDetail.customer_alt_no}</p>
                                                    </Col>
                                                </Row>}
                                                <Row className="align-items-center mb-1">
                                                    <Col xs={6} sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Address</CardSubtitle>
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        <p className="card-text mb-0">{orderDetail.customer_address_1} {orderDetail.customer_address_2}</p>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center mb-1">
                                                    <Col xs={6} sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">City</CardSubtitle>
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        <p className="card-text mb-0">{orderDetail.customer_city}</p>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center mb-1">
                                                    <Col xs={6} sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">State</CardSubtitle>
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        <p className="card-text mb-0">{orderDetail.customer_state}</p>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center mb-1">
                                                    <Col xs={6} sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Pincode</CardSubtitle>
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        <p className="card-text mb-0">{orderDetail.customer_pincode}</p>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center mb-1">
                                                    <Col xs={6} sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">IP Address</CardSubtitle>
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        <p className="card-text mb-0">{orderDetail.customer_ip}</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={6} className="mb-4 mb-sm-0">
                                                <CardHeader className="mb-2 bg-card-header">
                                                    <CardTitle className="mb-0">Order Details</CardTitle>
                                                </CardHeader>
                                                <Row className="align-items-center mb-1">
                                                    <Col xs={6} sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Order Date</CardSubtitle>
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        <p className="card-text mb-0">{changeIntoDate(orderDetail.created_at)}</p>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center mb-1">
                                                    <Col xs={6} sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Declared Weight</CardSubtitle>
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        <p className="card-text mb-0">{orderDetail.product_weight_in_kgs + ' Kg'}</p>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center mb-1">
                                                    <Col xs={6} sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Dimension</CardSubtitle>
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        <p className="card-text mb-0">{orderDetail.length_in_cms + ' cm'} X {orderDetail.breadth_in_cms + ' cm'} X {orderDetail.height_in_cms + ' cm'}</p>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center mb-1">
                                                    <Col xs={6} sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Volumetric Weight</CardSubtitle>
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        <p className="card-text mb-0">{orderDetail.product_vol_weight_in_kgs + ' Kg'}</p>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center mb-1">
                                                    <Col xs={6} sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Delivery Type</CardSubtitle>
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        <p className="card-text mb-0">{delivery_type_id[orderDetail.delivery_type_id]} - {orderDetail.delivery_type_id === 1 && '₹ ' + orderDetail.product_cod_value}</p>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center mb-1">
                                                    <Col xs={6} sm={6}>
                                                        <CardSubtitle tag="h6" className="mb-0">Source</CardSubtitle>
                                                    </Col>
                                                    <Col xs={6} sm={6}>
                                                        <p className="card-text mb-0">{orderDetail.source}</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col sm={6} className="mb-4 mb-sm-0">
                                                <CardHeader className="mb-2 bg-card-header">
                                                    <CardTitle className="mb-0">System Tags</CardTitle>
                                                </CardHeader>
                                                <Row className="align-items-center mb-1">
                                                    <Col xs={12} sm={12}>
                                                        {checkUndeNullBlank(orderDetail.system_tags) ? orderDetail.system_tags.split(',').map((step) => <Badge color='warning' pill className='mt-2 mr-1' >{step}{"\n"}</Badge>) : 'NA'}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={6} className="mb-4 mb-sm-0">
                                                <CardHeader className="mb-2 bg-card-header">
                                                    <CardTitle className="mb-0">Merchant Tags</CardTitle>
                                                </CardHeader>
                                                <Row className="align-items-center mb-1">
                                                    <Col xs={12} sm={12}>
                                                        {checkUndeNullBlank(orderDetail.merchant_tags) ? orderDetail.merchant_tags.split(',').map((step) => <Badge color='warning' pill className='mt-2 mr-1' >{step}{"\n"}</Badge>) : 'NA'}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Table responsive className="text-left custom-table">
                                            <thead>
                                                <tr>
                                                    <th className="text-nowrap">Product</th>
                                                    <th className="text-nowrap">Quantity</th>
                                                    <th className="text-nowrap">Invoice Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {checkData(multipleProductDetails) ? multipleProductDetails.map((item, index) => (

                                                <tr>
                                                    <td className="col-6">
                                                        <div className="" id={`product${orderDetail.id}`}>{item.name}</div>
                                                        <UncontrolledTooltip placement="top" target={`product${orderDetail.id}`}>
                                                            {item.name}
                                                        </UncontrolledTooltip>
                                                        <button type="button"  class="ctm-btn btn-sm py-1 btn btn-outline-primary" >Split Order</button>
                                                    </td>
                                                    <td>{item.qty}</td>
                                                    <td>₹{item.price}</td>
                                                </tr>
                                                )) :  <tr>
                                                        <td className="col-6">
                                                            <div className="" id={`product${orderDetail.id}`}>{orderDetail.product_name}</div>
                                                            <UncontrolledTooltip placement="top" target={`product${orderDetail.id}`}>
                                                                {orderDetail.product_name}
                                                            </UncontrolledTooltip>
                                                        </td>
                                                        <td>{orderDetail.product_qty}</td>
                                                        <td>₹{orderDetail.product_invoice_value}</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>

                                    </CardBody>
                                    : loader ? <div className="w-100 text-center"><Spinner /></div> : ''}
                            </Card>
                            {checkData(orderStatusLogs) &&
                                <Col sm={12}>
                                    <Table responsive className="text-center custom-table">
                                        <thead>
                                            <tr>
                                                <th>User</th>
                                                <th>Status</th>
                                                <th>Remarks</th>
                                                <th>Date-Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {checkData(orderStatusLogs) ? orderStatusLogs.map((item, index) => (
                                                <tr>
                                                    <td>{item.submit_by}</td>
                                                    <td>{channel_order_status[item.order_status_id]}</td>
                                                    <td>{item.remarks}</td>
                                                    <td>{changeIntoDateTime(item.created_at)}</td>
                                                </tr>
                                            )) : orderLogLoader ? <tr><td colSpan="5"><LoaderDefault /></td></tr> : <tr><td colSpan="5"><NodataFound /></td></tr>}
                                        </tbody>
                                    </Table>
                                </Col>
                            }
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default ChannelOrderDetail;