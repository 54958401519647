import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, ListGroup,Form, ListGroupItem,FormGroup,Button, Spinner, CardHeader, CardTitle } from 'reactstrap';
// import axios from 'axios';
import { checkData, checkUndeNullBlank } from '../../../../ObjectExist';
import { changeIntoDateYmd, errorHandling } from '../../../../utlis';
import { connect, useDispatch } from 'react-redux'
import { citysStates } from '../../../../actions';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoaderDefault from '../../../LoaderDefault';
import NodataFound from '../../../NodataFound';
import { DateRangePicker } from 'rsuite';
import moment from 'moment';

const CityState = (props) => {
  const [cityState, setCityState] = useState({});
  const [loader, setLoader] = useState(false);
  const [filterDate, setFilterDate] = useState([new Date(moment().subtract(30, 'days')), new Date()]);
  const dispatch = useDispatch();
  const listingSummary = () => {
    setCityState({});
    var date = new Date();
    setLoader(true);
    let mypost = { "merchant_id": props.user[0].id, "start_date":checkData(filterDate) && filterDate.length > 0 ? moment(filterDate[0]).format('YYYY-MM-DD') : '',"end_date":checkData(filterDate) && filterDate.length > 0 ? moment(filterDate[1]).format('YYYY-MM-DD') : '' }
    let myget = {}
    dispatch(citysStates(mypost, myget)).then((response) => {
      if (response.error === false) {
        setCityState(response);
      }
      setLoader(false)
    }).catch(function (response) {
      setLoader(false);
      errorHandling(response, "reload");
    });
  }
  useEffect(() => {
    if (checkData(props.state) && checkData(props.state.viewCityState)) {
      setCityState(props.state.viewCityState);
    } else {
      listingSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sumallCity = checkData(cityState) && checkData(cityState.top_city) ? cityState.top_city.map(item => item.totalCity).reduce((prev, curr) => prev + curr, 0) : 0;
  const sumallState = checkData(cityState) && checkData(cityState.top_state) ? cityState.top_state.map(item => item.totalState).reduce((prev, curr) => prev + curr, 0) : 0;

  function findCityDeliveredCount(city) {
    let deliveredTotal = '';
    if (checkData(cityState) && checkData(cityState.top_del_city)) {
      cityState.top_del_city.forEach(item => {
        if (item.customer_city !== undefined && item.customer_city.toUpperCase() === city.toUpperCase()) {
          deliveredTotal = item.total !== undefined ? item.total : '';
        }
      });
    }
    return deliveredTotal;
  }
  function findStateDeliveredCount(state) {
    let deliveredTotal = '';
    if (checkData(cityState) && checkData(cityState.top_del_state)) {
      cityState.top_del_state.forEach(item => {
        if (item.customer_state !== undefined && item.customer_state.toLowerCase() === state.toLowerCase()) {
          deliveredTotal = item.total !== undefined ? item.total : '';
        }
      });
    }
    return deliveredTotal;
  }

  return (
    <Card className="overview-card shadow border-0 w-100">
      <Row>
        {/* {loader && <div className="formLoader"><Spinner /></div>} */}
        <Col sm={12} className="mb-4 mb-xl-0">
          <Card className="overview-card border-0">
            <div className="page-header content-header d-flex flex-wrap justify-content-between align-items-center">
              <div className="page-title mb-3 mb-xl-0">Top 10</div>
              <Form className="cardFilterSummary">
                <Row className="mx-sm-0 justify-content-end">
                  <Col sm={9}>
                    <FormGroup className='mb-sm-0'>
                      <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} placement="bottomEnd" showOneCalendar={props.oneCalendar} placeholder="Choose Date Range" format="dd-MM-yyyy" />
                    </FormGroup>
                  </Col>
                  <Col sm={3} className="">
                    <Button className="ctm-btn btn-sm" color="primary" onClick={listingSummary}>Search</Button>
                  </Col>
                </Row>
              </Form>
            </div>
            <Row className="m-0">
              <Col sm={6}>
                <CardHeader className="mb-2 bg-card-header">
                  <CardTitle className="mb-0 font-weight-600">Top City</CardTitle>
                </CardHeader>
                <CardBody className="p-0">
                  <ListGroup>
                    {checkData(cityState) && checkData(cityState.top_city) ? cityState.top_city.map((item, index) => {
                      let cityName = checkUndeNullBlank(item.customer_city) ? item.customer_city : 'NA'
                      let totalCount = checkUndeNullBlank(item.totalCity) ? item.totalCity : 0
                      let cityPercent = (totalCount / sumallCity) * 100
                      let dCount = findCityDeliveredCount(cityName)
                      return (
                        <ListGroupItem key={index} className="justify-content-between d-flex flex-wrap border-0 px-0"><span className="w-40 text-uppercase">{cityName}</span>
                          <span className={`w-20 fsc-2 ${dCount <= 0 && 'opacity-0'}`}><small className="text-success mx-1"><FontAwesomeIcon icon={faArrowUp} /></small>({dCount})</span>

                          <small className="font-weight-600 text-primary w-40">{totalCount} ({cityPercent.toFixed(2)} %)</small></ListGroupItem>
                      )
                    }) : loader ? <LoaderDefault /> : <NodataFound />}
                  </ListGroup>
                </CardBody>
              </Col>
              <Col sm={6} className="mt-3 mt-sm-0">
                <div>
                  <CardHeader className="mb-2 bg-card-header">
                    <CardTitle className="mb-0 font-weight-600">Top State</CardTitle>
                  </CardHeader>
                  <CardBody className="p-0">
                    <ListGroup>
                      {checkData(cityState) && checkData(cityState.top_state) ? cityState.top_state.map((item, index) => {
                        let stateName = checkUndeNullBlank(item.customer_state) ? item.customer_state : 'NA'
                        let totalCount = checkUndeNullBlank(item.totalState) ? item.totalState : 0
                        let statePercent = (totalCount / sumallState) * 100
                        let dCount = findStateDeliveredCount(stateName)
                        return (
                          <ListGroupItem key={index} className="justify-content-between d-flex flex-wrap border-0 px-0"><span className="w-40 text-uppercase">{stateName}</span>
                            <span className={`w-20 fsc-2 ${dCount <= 0 && 'opacity-0'}`}><small className="text-success mx-1"><FontAwesomeIcon icon={faArrowUp} /></small>({dCount})</span>
                            <small className="font-weight-600 text-primary w-40">{totalCount} ({statePercent.toFixed(2)} %)</small></ListGroupItem>
                        )
                      }) : loader ? <LoaderDefault /> : <NodataFound />}
                    </ListGroup>
                  </CardBody>
                </div>
              </Col>
            </Row>

          </Card>
        </Col>
      </Row>
    </Card>
  );
}
function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}
export default connect(mapDispatchToProps)(CityState);