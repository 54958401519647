import React, { useEffect, useState } from 'react';
import {Row,Table, Col, Button, Form, FormGroup, Label, Input, CustomInput } from 'reactstrap';
import {
  errorHandling,
} from "../../utlis";
import {
  baseString,
} from "../../config";
import { checkData, checkUndeNullBlank } from "../../ObjectExist";
import Swal from "sweetalert2";
import axios from "axios";
import { useHistory} from "react-router-dom";


const UserPermissionEdit = (props) => {
  const [loader, setLoader] = useState(false);
  const [userList,setUserList]=useState([]);
  const history = useHistory();

  useEffect(()=>{
    axios({
        method: "get",
        url: 'https://api.logistify.in/api/v1/users/list',
      })
        .then(function (response) {
            // console.log(response.data.data);
          setLoader(false);
          setUserList(response.data.data)
          if (response.data.error === false) {
            
          } else {
            Swal.fire({
              title: "Error",
              text: response.data.msg
                ? response.data.msg
                : "Something went wrong",
              icon: "error",
            });
          }
        })
        .catch(function (err) {
          setLoader(false);
          errorHandling(err, "reload");
        });
  },[])

  const editUserPermit=(e,user)=>{
    e.preventDefault();
    history.push({
        pathname: '/edit-permission',
        state: { user: user }
    });
//     const formEditData={ "admin_id":"1",
//     "name":user.name,
//     "email":user.email,
//     "password":user.password,
//     "user_type":user.user_type,
//     "status":user.status,
//     "permissions":{}
// }

    // axios({
    //     method: "post",
    //     url: `http://dev.logistify.in/api/v1/users/edit/${user.id}`,
    //     headers: { token: window.btoa(baseString) },
    //     data: formEditData,
    //   }).then(function (response) {
    //     setLoader(false);
    //     // alert(22)
    //     if (response.data.error === false) {
    //         alert(21)
    //     //   Swal.fire({
    //     //     title: "Success",
    //     //     text: response.data.msg
    //     //       ? response.data.msg
    //     //       : "Data Saved Successfully",
    //     //     icon: "success",
    //     //   });
    //     } else {
    //       Swal.fire({
    //         title: "Error",
    //         text: response.data.msg
    //           : "Something went wrong",
    //         icon: "error",
    //       });
    //     }
    //   })
    //   .catch(function (err) {
    //     setLoader(false);
    //     errorHandling(err, "reload");
    //   });
  }
      
    
    
    // Handle form submission, e.g., send data to the server
    // console.log(formData);


  return (
    <div className="app-content content overflow-hidden">
      <div className='card'>
        <div className='card-body'>
        <Row>
        <h6 className='pb-3 pt-3'>List Of Permitted Users</h6>
        <Table striped bordered hover>
            <thead>
              <tr>
                <th>User ID</th>
                <th>Name</th>
                <th>Email</th>
                <th style={{width:'15%'}}>Action</th>
              </tr>
            </thead>
            <tbody>
              {userList.map((user) => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>
                    <div className="btn-group-ctm">
                        {(user.id===1)?null:<span className="mr-2"><Button onClick={(e) => editUserPermit(e, user)} color="secondary" className="ctm-btn btn-sm ml-2" outline>Edit</Button></span>}
                        {(user.id===1)?null:<span><Button className="ctm-btn btn-sm ml-2" color="info" outline>Delete</Button></span>}
                    </div> 
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Row>
    </div>
    </div>
    </div>
  );
};

export default UserPermissionEdit;
