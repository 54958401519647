import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Button, InputGroup, InputGroupAddon, InputGroupText, FormGroup, Form, Modal, ModalHeader, ModalBody, Label } from 'reactstrap';
import { Eye, EyeOff } from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { errorHandling } from '../../utlis';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Swal from 'sweetalert2'
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import UrlImageDownloader from "react-url-image-downloader";
import { baseString } from '../../config';

const EditMerchant = (props) => {
  const [logo, setLogo] = useState("");
  const [removeImg, setRemoveImg] = useState("");
  const [passwordTypeView, setPasswordTypeView] = useState(true);
  const toggleClose = () => {
    props.setEditmodal(false);
    setRemoveImg("");
  }
  const removeConfirm = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to delete this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#1b5a8f',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setRemoveImg("d-none");
      }
    })
  }

  const handleFileInput = (e) => {
    setLogo(e.target.files[0])
  }
  useEffect(() => {
    let myResponse = props.editMerchant[0];
    if (myResponse !== undefined) {
      if (myResponse.billing !== undefined) {
        myResponse.billing = String(myResponse.billing);
      }
      if (myResponse.freight_type !== undefined) {
        myResponse.freight_type = String(myResponse.freight_type);
      }
      if (myResponse.status !== undefined) {
        myResponse.status = String(myResponse.status);
      }
      if (myResponse.ship_status !== undefined) {
        myResponse.ship_status = String(myResponse.ship_status);
      }
      if (myResponse.document_status !== undefined) {
        myResponse.document_status = String(myResponse.document_status);
      }
    }
    reset2(myResponse);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { register: editMerchantdata, handleSubmit: merchantEditSubmit, watch: watchEditpswd, formState: { errors: errors2 }, reset: reset2 } = useForm();
  const passwordOfEdit = useRef({});
  passwordOfEdit.current = watchEditpswd("password", "");
  const onEditMMerchantSubmit = (data) => {
    props.setLoader(true);
    let formData = new FormData();
    formData.append("admin_id", checkData(props.user[0]) ? props.user[0].id : '');
    formData.append("company_name", data.company_name);
    formData.append("brand_name", data.brand_name);
    formData.append("email", data.email);
    formData.append("billing", data.billing);
    formData.append("freight_type", data.freight_type);
    formData.append("ship_status", data.ship_status);
    formData.append("document_status", data.document_status);
    formData.append("status", data.status);
    formData.append("mobile", data.mobile);
    formData.append("wallet_balance", data.wallet_balance);
    formData.append("max_liability_per_shipment", data.max_liability_per_shipment);
    formData.append("max_cod_value_per_order", data.max_cod_value_per_order);
    formData.append("remittance_tat_period", data.remittance_tat_period);
    formData.append("refer_by", data.refer_by);
    formData.append("additional_mrp_percentage", data.additional_mrp_percentage);
    formData.append("security_amount", data.security_amount);
    // formData.append("logo", logo !== undefined && logo !== '' ? logo : props.editMerchant[0].logo);
    formData.append("logo", checkUndeNullBlank(logo) ? logo : removeImg !== undefined && removeImg !== "" ? "" : props.editMerchant[0].logo);
    formData.append("old_logo", removeImg !== undefined && removeImg !== "" ? "deleted" : checkUndeNullBlank(props.editMerchant[0].logo) ? props.editMerchant[0].logo : '');

    axios({
      method: "post",
      url: `${process.env.REACT_APP_GET_MERCHANT}/update/${props.editMerchant[0].id}`,
      data: formData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      props.setLoader(false);
      if (res.data.error === false) {
        // props.merchantListing();
        props.setEditmodal(false);
        props.onCommonFilter(props.filterInput, props.page);
        setRemoveImg("");
        Swal.fire({ title: 'Success', text: res.data.msg, icon: 'success', })
      }
    }).catch(function (response) {
      props.setLoader(false);
      errorHandling(response, "");
    });
  }
  return (
    <Modal isOpen={props.editmodal} toggle={props.editmodal} scrollable={true} size="lg">
      <ModalHeader toggle={toggleClose}>Edit Merchants</ModalHeader>
      <ModalBody>
        {Object.entries(props.editMerchant).length > 0 &&
          <Form onSubmit={merchantEditSubmit(onEditMMerchantSubmit)}>
            <Row className="mx-0 justify-content-start addModal">
              <Col sm={4}>
                <FormGroup>
                  <Label for="companyName_edit">Enter Company Name<span className="text-danger">*</span></Label>
                  <input {...editMerchantdata("company_name", { required: 'Please Enter Company Name' })} className="form-control-sm form-control" placeholder="Enter Company Name*" id="companyName_edit" />
                  {errors2.company_name && <span className="text-danger d-block error">{errors2.company_name.message}</span>}
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label for="email_edit">Enter Email<span className="text-danger">*</span></Label>
                  <input {...editMerchantdata("email", {
                    required: 'Please Enter Email',
                    pattern: { value: /\S+@\S+\.\S+/, message: 'Please Enter Valid Email' }
                  })} type="text" className="form-control-sm form-control" placeholder="Enter Email*" id="email_edit" />
                  {errors2.email && <span className="text-danger d-block error">{errors2.email.message}</span>}
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label for="brandName_edit">Enter Brand Name<span className="text-danger">*</span></Label>
                  <input {...editMerchantdata("brand_name", { required: 'Please Brand Name' })} className="form-control-sm form-control" placeholder="Enter Brand Name*" id="brandName_edit" />
                  {errors2.brand_name && <span className="text-danger d-block error">{errors2.brand_name.message}</span>}
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label for="contact_edit">Enter Mobile Number<span className="text-danger">*</span></Label>
                  <input {...editMerchantdata("mobile", {
                    required: 'Please Enter Phone Number',
                    minLength: { value: 10, message: "Please Enter 10 Digit Mobile Number" },
                    maxLength: { value: 10, message: "Please Enter 10 Digit Mobile Number" },
                    pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Mobile Number' }
                  })} type="text" className="form-control-sm form-control" placeholder="Enter Phone Number*" id="contact_edit" />
                  {errors2.mobile && <span className="text-danger d-block error">{errors2.mobile.message}</span>}
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label for="walletBlance_edit">Enter Wallet Balance<span className="text-danger">*</span></Label>
                  <input {...editMerchantdata("wallet_balance", {
                    required: 'Please Wallet Balance',
                    pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Wallet Balance' }
                  })} type="text" readOnly className="form-control-sm form-control" placeholder="Enter Wallet Balance*" id="walletBlance_edit" />
                  {errors2.wallet_balance && <span className="text-danger d-block error">{errors2.wallet_balance.message}</span>}
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label for="maxLiaShip_edit">Enter Max Liability Shipment<span className="text-danger">*</span></Label>
                  <input {...editMerchantdata("max_liability_per_shipment", {
                    required: 'Please Enter Max Liability Shipment',
                    pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Max Liability Shipment' }
                  })} type="text" className="form-control-sm form-control" placeholder="Enter Max Liability Shipment*" id="maxLiaShip_edit" />
                  {errors2.max_liability_per_shipment && <span className="text-danger d-block error">{errors2.max_liability_per_shipment.message}</span>}
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label for="maxLiabShip_add">Enter Max COD Value <small>(per order)</small></Label>
                  <input {...editMerchantdata("max_cod_value_per_order", { pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Max COD Value' } })} type="text" className="form-control-sm form-control" placeholder="Enter Max COD Value" id="maxCodVal_add" />
                  {errors2.max_cod_value_per_order && <span className="text-danger d-block error">{errors2.max_cod_value_per_order.message}</span>}
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label for="security_add">Enter Security Amount </Label>
                  <input {...editMerchantdata("security_amount", { pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Security Amount' }, min: { value: 500, message: "Please Enter Minimum 500" } })} type="text" className="form-control-sm form-control" placeholder="Enter Security Amount" id="security_add" />
                  {errors2.security_amount && <span className="text-danger d-block error">{errors2.security_amount.message}</span>}
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label for="password">Password</Label>
                  <InputGroup>
                    <input {...editMerchantdata("password")} className="form-control-sm form-control" type={passwordTypeView ? "password" : "text"} readOnly placeholder="Password" id="" />
                    <InputGroupAddon addonType="append" onClick={() => setPasswordTypeView(!passwordTypeView)}>
                      <InputGroupText className="py-0"> {passwordTypeView ? <EyeOff /> : <Eye />}</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label for="brandLogo_edit">Upload Brand Logo</Label>
                  <input {...editMerchantdata("brandlogo")} type="file" onChange={handleFileInput} accept=".jpg, .jpeg, .png" className="form-control-sm form-control" id="brandLogo_edit" />
                  {props.editMerchant !== undefined && props.editMerchant[0].logo !== undefined && props.editMerchant[0].logo !== null && props.editMerchant[0].logo !== "" &&
                    <div className={`edit-brand-logo download-img-con mt-2 ${removeImg}`}>
                      <UrlImageDownloader imageUrl={process.env.REACT_APP_IMAGE_MERCHANT + props.editMerchant[0].logo} />
                      <span className="remove-img" onClick={() => removeConfirm()}><FontAwesomeIcon icon={faTimes} /></span>
                      {/* <img src={process.env.REACT_APP_IMAGE_MERCHANT+props.editMerchant[0].logo} alt="logo" style={{width:"150px"}} /> */}
                    </div>}
                  {/* {errors2.brandlogo && <span className="text-danger d-block error">{errors2.brandlogo.message}</span>} */}
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label for="remittance_tat_period_edit">Remittance TAT Period<span className="text-danger">*</span></Label>
                  <input {...editMerchantdata("remittance_tat_period", { required: 'Please Enter Remittance TAT Period' })} className="form-control-sm form-control" placeholder="Enter Remittance TAT Period*" id="remittance_tat_period_edit" />
                  {errors2.remittance_tat_period && <span className="text-danger d-block error">{errors2.remittance_tat_period.message}</span>}
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label for="refer_by_edit">Refer By</Label>
                  <input {...editMerchantdata("refer_by", { required: false })} className="form-control-sm form-control" placeholder="Enter Refer By" id="refer_by_edit" />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label for="additional_mrp_percentage_edit">MRP Increase %</Label>
                  <input {...editMerchantdata("additional_mrp_percentage", { pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please a Valid Percentage' },required: false })} className="form-control-sm form-control" placeholder="Enter MRP Increase %" id="additional_mrp_percentage_add" />
                  {errors2.additional_mrp_percentage && <span className="text-danger d-block error">{errors2.additional_mrp_percentage.message}</span>}

                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <FormGroup check className="pl-0">
                    <Label>Billing<span className="text-danger">*</span></Label>
                    <div>
                      <span className="form-check2 mr-2">
                        <input className="mx-2" {...editMerchantdata("billing", { required: 'Please Choose Billing' })} type="radio" value="2" id="edt_pre_billing" />
                        <Label for="edt_pre_billing">Prepaid Billing</Label>
                      </span>
                      <span className="form-check2">
                        <input className="mx-2" {...editMerchantdata("billing", { required: 'Please Choose Billing' })} type="radio" value="1" id="edt_post_billing" />
                        <Label for="edt_post_billing">Postpaid Billing</Label>
                      </span>
                    </div>
                    {errors2.billing && <span className="text-danger d-block error">{errors2.billing.message}</span>}
                  </FormGroup>
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <FormGroup check className="pl-0">
                    <Label>Freight Type<span className="text-danger">*</span></Label>
                    <div>
                      <span className="form-check2 mr-2">
                        <input className="mx-2"  {...editMerchantdata("freight_type", { required: 'Please Choose Freight Type' })} type="radio" value="1" id="fixCharge_add" />
                        <Label for="fixCharge_add">Fixed Charge</Label>
                      </span>
                      <span className="form-check2">
                        <input className="mx-2" {...editMerchantdata("freight_type", { required: 'please Choose Freight Type' })} type="radio" value="2" id="varCharge_add" />
                        <Label for="varCharge_add">Variables Charge</Label>
                      </span>
                    </div>
                    {errors2.freight_type && <span className="text-danger d-block error">{errors2.freight_type.message}</span>}
                  </FormGroup>
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <FormGroup check className="pl-0">
                    <Label>Ship Status<span className="text-danger">*</span></Label>
                    <div>
                      <span className="form-check2 mr-2">
                        <input className="mx-2" {...editMerchantdata("ship_status", { required: 'Please Choose Ship Status' })} type="radio" value="1" id="active_add" />
                        <Label for="active_add">Active</Label>
                      </span>
                      <span className="form-check2">
                        <input className="mx-2" {...editMerchantdata("ship_status", { required: 'Please Choose Ship Status' })} type="radio" value="0" id="inactive_add" />
                        <Label for="inactive_add">InActive</Label>
                      </span>
                    </div>
                    {errors2.ship_status && <span className="text-danger d-block error">{errors2.ship_status.message}</span>}
                  </FormGroup>
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <FormGroup check className="pl-0">
                    <Label>Document Status<span className="text-danger">*</span></Label>
                    <div>
                      <span className="form-check2 mr-2">
                        <input className="mx-2" {...editMerchantdata("document_status", { required: 'Please Choose Document Status' })} type="radio" value="1" id="validate" />
                        <Label for="validate">Validated</Label>
                      </span>
                      <span className="form-check2">
                        <input className="mx-2" {...editMerchantdata("document_status", { required: 'Please Choose Document Status' })} type="radio" value="0" id="not_validate" />
                        <Label for="not_validate">Not Validated</Label>
                      </span>
                    </div>
                    {errors2.ship_status && <span className="text-danger d-block error">{errors2.ship_status.message}</span>}
                  </FormGroup>
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <FormGroup check className="pl-0">
                    <Label>Status<span className="text-danger">*</span></Label>
                    <div>
                      <span className="form-check2 mr-2">
                        <input className="mx-2" {...editMerchantdata("status", { required: true })} type="radio" value="1" id="edt_active_add" />
                        <Label for="edt_active_add">Active</Label>
                      </span>
                      <span className="form-check2">
                        <input className="mx-2" {...editMerchantdata("status", { required: true })} type="radio" value="0" id="edt_inactive_add" />
                        <Label for="edt_inactive_add">InActive</Label>
                      </span>
                    </div>
                  </FormGroup>
                </FormGroup>
              </Col>
              <Col sm={12} className="text-right">
                <Button className="ctm-btn btn-sm" color="primary">Save</Button>
              </Col>
            </Row>
          </Form>
        }
      </ModalBody>
    </Modal>
  )
}

export default EditMerchant