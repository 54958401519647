import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form, FormGroup, CardTitle, Spinner } from 'reactstrap';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import axios from 'axios';
import { checkData } from '../../../ObjectExist';
import { errorHandling } from '../../../utlis';
import { baseString } from '../../../config';

const TrackSnippet = (props) => {
  const [loader, setLoader] = useState(false);
  const { register: trackingSnippet, handleSubmit: trackingSnippetSubmit, formState: { errors: errors3 }, reset } = useForm();

  useEffect(() => {
    setLoader(true)
    const data = {}
    data.handler = 'tracking_code'
    data.admin_id = checkData(props.user) && props.user[0].id
    // axios.post(`${process.env.REACT_APP_API_SNIPPET_VIEW}`, data)
    axios({
      method: "post",
      url: process.env.REACT_APP_API_SNIPPET_VIEW,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false)
      if (res.data.error === false) {
        let myresponse = res.data.data[0]
        reset(myresponse);
      }
    })
      .catch((err) => {
        setLoader(false)
        errorHandling(err, "");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const onUpdatetrackingSnippet = (data) => {
    data.handler = 'tracking_code'
    data.admin_id = checkData(props.user) && props.user[0].id
    setLoader(true)
    // axios.post(`${process.env.REACT_APP_API_SNIPPET_UPDATE}`, data)
    axios({
      method: "post",
      url: process.env.REACT_APP_API_SNIPPET_UPDATE,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false)
      if (res.data.error === false) {
        Swal.fire({ title: 'Success', text: res.data.msg, icon: 'success', })
      }
    })
      .catch((err) => {
        setLoader(false)
        errorHandling(err, "");
      });
  };
  return (
    <>
      {loader && <div className="formLoader"><Spinner /></div>}
      <Form onSubmit={trackingSnippetSubmit(onUpdatetrackingSnippet)}>
        <div className="sub-page-header">
          <CardTitle tag="h4" className="sub-page-title">Tracking Code Snippet</CardTitle>
        </div>
        <Row>
          <Col sm={12}>
            <FormGroup>
              <textarea {...trackingSnippet("setting_value")} type="textarea" placeholder="Please Enter API snippet" rows={15} id="snippet_value" className="form-control-sm form-control" />
              {errors3.setting_value && <span className="text-danger d-block error">{errors3.setting_value.message}</span>}
            </FormGroup>
          </Col>
          <Col sm={12} className="mt-2 text-right">
            <Button className="ctm-btn btn-sm" color="primary"> Save</Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default TrackSnippet;