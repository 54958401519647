import React, {useState, useEffect} from 'react';
import { Row, Col, Card, CardBody,Button, CardTitle, FormGroup,Form, CardHeader, Table, Badge,Pagination, PaginationItem, PaginationLink, Spinner} from 'reactstrap';
import {Link, useHistory} from 'react-router-dom';
import { ArrowLeft, ChevronLeft, ChevronRight } from 'react-feather';
import { DateRangePicker } from 'rsuite';
// import 'rsuite/dist/styles/rsuite-default.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown,faChevronDown, faPlus, faWallet} from '@fortawesome/free-solid-svg-icons';
import { CreditCard} from 'react-feather';
import AddPassbook from './AddPassbook';
import { baseString, download_delay, pay_status, wallet_head } from '../../../config';
import { changeIntoDateTime, firstDayOftheMonth, errorHandling } from '../../../utlis';
import NodataFound from '../../NodataFound';
import LoaderDefault from '../../LoaderDefault';
import axios from 'axios';
import Swal from 'sweetalert2'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { checkData, checkUndeNullBlank } from '../../../ObjectExist';
import { encryptStorageLogin } from '../../../EncStorage';
import moment from 'moment';


const Passbook = (props) => {
  const [walletMdl, setWalletMdl] = useState(false);
  const toggleWallet = () => setWalletMdl(!walletMdl);
  const [filterDate, setFilterDate] = useState('');

  const { merchantId } = useParams();

  const [passbook, setPassbook] = useState({})
  const [amount, setAmount] = useState('')
  const [tableloader, setTbaleLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [selectperpage,setSelectperpage] = useState(20);
  const [currentCount,setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState('');
  const [isExport, setIsExport] = useState(false);
  const history = useHistory();

    const dropFilterCount = 20

  const onDateChange = (e) => {
    setFilterDate(e.text);
  };
  let dateRangeRef =  dateRange => dateRangeRef = dateRange;
  useEffect(() => {
    passbookListing()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const passbookListing = () => {
    setTbaleLoader(true);
    let data = {}
      if(props.user[0].user_type === "Super"){
        data={"merchant_id":merchantId,"admin_id":props.user[0].id}
      }if(props.user[0].user_type === "Merchant"){
        data={"merchant_id" : props.user[0].id}
      }
    reset();
    setIsExport(false);
    setFilterDate([firstDayOftheMonth(),new Date()]);
    setFilterInput('')
    setCurrentCount(1)
    setPage(1)
    // axios.post(`${process.env.REACT_APP_MERCHANTPASSBOOK_LIST}?start_date=${moment(firstDayOftheMonth()).format('YYYY-MM-DD')}&end_date=${moment().format('YYYY-MM-DD')}&page=${1}&per_page=${20}`,data)
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANTPASSBOOK_LIST}?start_date=${moment(firstDayOftheMonth()).format('YYYY-MM-DD')}&end_date=${moment().format('YYYY-MM-DD')}&page=${1}&per_page=${20}`,
      data: data,
      headers: {"token":window.btoa(baseString)}
      }).then((res) => {
      setTbaleLoader(false);
      if(res.data !== undefined && res.data.fatal !== true){
        if(parseInt(res.data.errno)>0){
          if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
          if(res.data.error === false){
            setPassbook(res.data);
            setAmount(res.data.passbookSum[0]);
            let amtsum = res.data.passbookSum[0]
            let balance = amtsum.CreditAmt-amtsum.DebitAmt
            if(balance !== props.user[0].wallet_balance){
              let data = props.user
              data[0].wallet_balance=balance;
              encryptStorageLogin.setItem('userenc', JSON.stringify(data));
            }
            // console.log("amount",res.data.passbookSum[0])
          }
          else{
            Swal.fire({title: 'Error',text: res.msg,icon: 'error',})
          }
        }
      }
    }).catch((err) => {
      errorHandling(err,"reload")
      setTbaleLoader(false);
    });
  }
  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = {"page":1, "per_page":event.target.value};
    onpaginationFilter(filterInput,pagePerpage)
  }
  function nextPageData() {
  if(checkData(passbook.data)&&checkUndeNullBlank(passbook.totalPage)){
    // &&checkUndeNullBlank(passbook.totalPage).length>page.length
      if(passbook.totalPage > page){
        setCurrentCount(currentCount+Object.entries(passbook.data).length)
    setPage(page+1)
    setLoader(true)
      let pagePerpage = {"page":page+1, "per_page":selectperpage};
      onpaginationFilter(filterInput,pagePerpage);
    }
  }
  }
  function previousPageData() {
  if(page >1){
  setPage(page-1)
  checkData(passbook.data)
  if(currentCount>Object.entries(passbook.data).length){
    setCurrentCount(currentCount-selectperpage)
  }else{
    setCurrentCount(1)
  }
  let pagePerpage = {"page":page >1 && page-1, "per_page":selectperpage};
    onpaginationFilter(filterInput,pagePerpage);
  }
  }
  const { register, handleSubmit, reset } = useForm();
  const onpaginationFilter = (data,pagePerpage) => {
    onCommonFilter(data,pagePerpage)
  };
  const onSubmit = (data,pagePerpage) => {
    setIsExport(true);
    pagePerpage.page=1
    if(checkUndeNullBlank(data.txnid) || checkUndeNullBlank(data.head_id) || checkUndeNullBlank(data.pay_status) || checkUndeNullBlank(filterDate)){
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data,pagePerpage)
    }else{
      Swal.fire({title: 'info',text: 'Please Choose Filter',icon: 'info',})
    }
  };
  const onCommonFilter = (data,pagePerpage) => {
    // data.start_date = filterDate !== '' ? changeIntoDateYmd(filterDate[0]) : '';
    // data.end_date = filterDate !== '' ? changeIntoDateYmd(filterDate[1]) : '';
    // setFilterInput(data);
    setLoader(true)
    let sDate = checkData(filterDate) ? moment(filterDate[0]).format('YYYY-MM-DD') : '';
    let eDate = checkData(filterDate) ? moment(filterDate[1]).format('YYYY-MM-DD') : '';
    let byData = {txnid:checkUndeNullBlank(data.txnid)?data.txnid:'',head_id:checkUndeNullBlank(data.head_id)?data.head_id:'',pay_status:checkUndeNullBlank(data.pay_status)?data.pay_status:''}
    if(props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant"){
      byData.merchant_id=props.user[0].id
    }else{
      byData.merchant_id=merchantId
      byData.admin_id=props.user[0].id
    }
    setFilterInput(byData);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_MERCHANTPASSBOOK_LIST}?txnid=${checkUndeNullBlank(data.txnid)?data.txnid:''}&head_id=${checkUndeNullBlank(data.head_id)?data.head_id:''}&pay_status=${checkUndeNullBlank(data.pay_status)?data.pay_status:''}&start_date=${sDate}&end_date=${eDate}&page=${checkUndeNullBlank(pagePerpage.page)?pagePerpage.page:page}&per_page=${checkUndeNullBlank(pagePerpage.per_page)?pagePerpage.per_page:selectperpage}`,
        data: byData,
        headers: {"token":window.btoa(baseString)}
    }).then(function (response) {
          setLoader(false);
          if(response.data.error === false){
            setPassbook(response.data);
            setAmount(response.data.passbookSum[0]);
          }
        }).catch(function (response) {
          setLoader(false);
          errorHandling(response,"")
        });
  };
 

  const exportShipment = () => {
    if(isExport){
      let data = filterInput
      if(props.user[0].user_type === "Super"){
        data.admin_id = props.user[0].id
      }else{
        data.merchant_id = props.user[0].id
      }
      data.is_export=1
      setLoader(true);  
      // axios.post(`${process.env.REACT_APP_MERCHANTPASSBOOK_LIST}?txnid=${checkUndeNullBlank(data.txnid)?data.txnid:''}&head_id=${checkUndeNullBlank(data.head_id)?data.head_id:''}&pay_status=${checkUndeNullBlank(data.pay_status)?data.pay_status:''}&start_date=${moment(firstDayOftheMonth()).format('YYYY-MM-DD')}&end_date=${moment().format('YYYY-MM-DD')}&page=${page}&per_page=${selectperpage}`, data)
      axios({
        method: "post",
        url: `${process.env.REACT_APP_MERCHANTPASSBOOK_LIST}?txnid=${checkUndeNullBlank(data.txnid)?data.txnid:''}&head_id=${checkUndeNullBlank(data.head_id)?data.head_id:''}&pay_status=${checkUndeNullBlank(data.pay_status)?data.pay_status:''}&start_date=${moment(firstDayOftheMonth()).format('YYYY-MM-DD')}&end_date=${moment().format('YYYY-MM-DD')}&page=${page}&per_page=${selectperpage}`,
        data: data,
        headers: {"token":window.btoa(baseString)}
        }).then((res) => {
      if(res.data !== undefined && res.data.fatal !== true && res.data !== true){
        if(parseInt(res.data.errno)>0){
          setLoader(false);
          if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
          if(res.data.is_export === 1){
            setTimeout(function(){ 
              setLoader(false);
              history.push("/my-downloads"); 
            },download_delay);
          }
        }
      }
    }).catch((err) => {
      setLoader(false)
      errorHandling(err,"")
    });
    }else{
      Swal.fire({icon:'error',title:'Error', text:'Please Choose Filter'})
    }
  }
  
  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
       <Col sm={12}>
        <Card className="p-0 my-3 wallet shadow content-wrapper border-0">
          <Card className="border-0">
            <CardHeader className="page-header d-flex justify-content-between flex-wrap">
              <CardTitle className="page-title">Manage Passbook </CardTitle>
              <div className="card-header-right d-flex flex-wrap justify-content-end align-items-center ml-auto">
                {props.user !== undefined ? props.user[0].user_type === "Super" && 
                <><Link to='/merchants' className="btn-text btn-light"><span className="icon mr-2"><ArrowLeft /></span><span>Back to Merchant List</span></Link>
                <div className="btn-text btn-light" onClick={toggleWallet}><span className="icon mr-2"><FontAwesomeIcon icon={faPlus} /></span><span>Add</span></div> 
                </>: ''}              
                
                {checkData(passbook.data) && <div className="btn-text btn-light" onClick={exportShipment}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Download Passbook</span></div>}            
              </div>
              {props.user !== undefined ? props.user[0].user_type === "Super" &&
              <AddPassbook passbookListing={passbookListing} user={props.user} isOpen={walletMdl} setWalletMdl={setWalletMdl} toggle={toggleWallet}/>
              :''}
            </CardHeader>
                <Form onSubmit={handleSubmit(onSubmit)} className="cardFilter">
                  <Row className="mx-0 justify-content-start justify-content-sm-center">
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <FormGroup>
                          <input {...register("txnid")} className="form-control-sm form-control" placeholder="Enter Trans. ID" />
                        </FormGroup>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <FormGroup>
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <select {...register("pay_status")}  className="form-control form-control-sm">
                            <option value="">Pay Status</option>
                            <option value="1">Paid</option>
                            <option value="0">Unpaid</option>
                            </select>
                        </FormGroup>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <FormGroup>
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <select {...register("head_id")}  className="form-control form-control-sm">
                            <option value="">Select Head</option>
                            {Object.entries(wallet_head).map(([key,value]) => (
                              <option value={key}>{value}</option>
                              ))}
                            </select>
                        </FormGroup>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={3}>
                      <FormGroup>
                        <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} placeholder="Choose Tran. Date Range" format="dd-MM-yyyy" ref={dateRangeRef} change={onDateChange} />
                        </FormGroup>                      
                    </Col>                                      
                    <Col sm={3} className="btn-container">
                      <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                      <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" outline onClick={passbookListing}>Reset</Button>
                    </Col>
                  </Row>
                </Form>
                <div className="statistics my-4"> 
                    <Row className="mx-auto" style={{maxWidth:"600px"}}>
                          <Col >
                            <div className="media my-1">
                                <div className="avatar mr-3">
                                  <span className="d-flex align-items-center justify-content-center avatar-content badge badge-success rounded-circle">
                                  <CreditCard />
                                  </span>
                                </div>
                                <div className="my-auto media-body">
                                  <h6 className="font-weight-bolder mb-0 text-nowrap">{checkUndeNullBlank(amount) && checkUndeNullBlank(amount.CreditAmt) ? <> ₹ {amount.CreditAmt} </> : '₹ 0.00'}</h6>
                                  <p className="font-small-3 mb-0 card-text fsc-3">Credit</p>
                                </div>
                            </div>
                          </Col>
                          <Col >
                            <div className="media my-1">
                                <div className="avatar mr-3">
                                  <span className="d-flex align-items-center justify-content-center avatar-content badge badge-danger rounded-circle">
                                  <CreditCard />
                                  </span>
                                </div>
                                <div className="my-auto media-body">
                                  <h6 className="font-weight-bolder mb-0 text-nowrap">{ amount !== undefined && amount.DebitAmt!==undefined && parseFloat(amount.DebitAmt)>0 ? <> ₹ {amount.DebitAmt} </> : '₹ 0.00'}</h6>
                                  <p className="font-small-3 mb-0 card-text fsc-3">Debit</p>
                                </div>
                            </div>
                          </Col>
                          <Col >
                            <div className="media my-1">
                                <div className="avatar mr-3">
                                  <span className="d-flex align-items-center justify-content-center avatar-content badge badge-info rounded-circle">
                                  <FontAwesomeIcon icon={faWallet} />
                                  </span>
                                </div>
                                <div className="my-auto media-body">
                                  <h6 className="font-weight-bolder mb-0 text-nowrap">{amount !== '' && amount !== undefined && amount !== 'null' ? <>₹ {(amount.CreditAmt-amount.DebitAmt).toFixed(2)} </>: '₹ 0.00'}</h6>
                                  <p className="font-small-3 mb-0 card-text fsc-3">Ballance</p>
                                </div>
                            </div>
                          </Col>
                    </Row>
                </div>
              <CardBody className="p-0">
              <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      <th className="text-nowrap">Head</th>
                      <th className="text-nowrap">Transaction ID</th>
                      <th className="text-nowrap">Amount</th>
                      <th className="text-nowrap">Pay Status</th>
                      <th className="text-nowrap">Date/Time</th>
                    </tr>
                  </thead>
                  <tbody>
                  {checkData(passbook.data) ? passbook.data.map((item, index) => (
                    <tr key={index} className="a">
                      <td>{wallet_head[item.head_id]}</td>
                      <td><span>{item.txnid}</span>{parseInt(item.pay_status) === 0 && <><br /><span>{item.remarks}</span></>}</td>
                      <td>{item.credit_amt > item.debit_amt ? item.credit_amt : item.debit_amt}</td>
                      <td><Badge outline color={item.pay_status === 1 ? "success" : "danger"}>{pay_status[item.pay_status]}</Badge></td>
                      <td>{changeIntoDateTime(item.created_at)}</td>
                    </tr>
                  ))
                  :tableloader ?<td colSpan="5"><LoaderDefault /></td>: <td colSpan="5" className="text-center"><NodataFound /></td>}
                   </tbody>
                </Table>
                {checkData(passbook) && checkData(passbook.data) && checkUndeNullBlank(passbook.TotalRows) ?
                <>
                {passbook.TotalRows > dropFilterCount ?
               <Row className="m-0 table-footer">
               <Col xs="6">               
                <Pagination aria-label="Page navigation example align-items-center">
                    <PaginationItem className="prev">
                      <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink> 
                    </PaginationItem>
                    <PaginationItem className="d-flex align-items-center">
                      <span className="border-0 mx-3">
                      {checkData(passbook) && checkData(passbook.data) && checkUndeNullBlank(passbook.TotalRows) ?
                 <span class="small">{currentCount} to {(currentCount-1)+(Object.entries(passbook.data).length)} of {passbook.TotalRows}</span>: ''}  
                      </span> 
                    </PaginationItem>
                    <PaginationItem className="next">
                      <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                    </PaginationItem>
                </Pagination>
                </Col>
                <Col xs="6">
                <Form>
                  <FormGroup>
                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                      <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                          <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          </select>
                  </FormGroup>
                </Form>
                </Col>
               </Row>:''}
               </>
               :''}
              </CardBody>
            </Card>
        </Card>
      </Col>
      
      </Row>
       </div>
  );
}

export default Passbook;