import React, {useState} from 'react';
import { Row, Col, Card, CardBody,Button, CardTitle, Input,FormGroup,Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, Label} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import { faChevronDown, faChevronRight, faDownload, faArrowDown, faArrowUp} from '@fortawesome/free-solid-svg-icons';
import { DateRangePicker } from 'rsuite';
import { useForm } from "react-hook-form";
import { ChevronLeft, ChevronRight } from 'react-feather';


const DebitNote = (props) => {

  const [excelmodal, setExcelmodal] = useState(false);
  const [excelFile, setExcelFile] = useState();

  const excelUpload = () => {
    setExcelmodal(!excelmodal);
    resetDebitNotefile();
    if(!excelmodal){setExcelFile("");}
  }

  const handleFileInput = (e) => {
    setExcelFile(e.target.files[0]);
  }

  const { register:uploadDebitNote, handleSubmit:debitNoteSubmit,formState: { errors}, reset : resetDebitNotefile} = useForm();
  const onUploadDebitNote = () =>{
  }

  return (
    <div className="app-content content overflow-hidden">
      <Row>
       <Col sm={12}>
        <Card className="p-0 my-3 courierDebitNote shadow content-wrapper border-0">
          <Card className="border-0">
            <CardHeader className="page-header d-flex flex-wrap justify-content-between">
              <CardTitle className="page-title">Manage Courier Debit Note</CardTitle>
              <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                <Link className="btn-text btn-light mx-2"><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Download Debit Note</span></Link>
                <div className="btn-text btn-light" onClick={excelUpload} ><span className="icon mr-2"><FontAwesomeIcon icon={faArrowUp} /></span><span>Upload Debit Note</span></div>              
              </div>
              <Modal isOpen={excelmodal} toggle={excelmodal} scrollable={true} >
                <ModalHeader toggle={excelUpload}>Upload Debit Note</ModalHeader>
                <ModalBody>
                  <Form onSubmit={debitNoteSubmit(onUploadDebitNote)}>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Upload all Your Debit Note via XLSX</p>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Please download below given template</p>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Remove sample data and upload your data</p>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Make sure you atleast fill mandatory fields.</p>

                   <Row className="m-0 pb-1">
                     <Col sm={12} className="p-0">
                        <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span> <Link target="_blank" download to="/format/courier_debit_note.xlsx" className=""><FontAwesomeIcon icon={faDownload} />  Download 
                        </Link> 
                        <span className="ml-2">Debit Note Format File</span></p>
                     </Col>
                     <Col sm={12} className="m-auto pt-1 p-0">
                      <FormGroup>
                        <input {...uploadDebitNote("excelUpload",{ 
                              required:'Please Upload XLSX File',
                              validate: {
                                lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                                acceptedFormats: files =>
                                ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                                  files[0]?.type
                                ) || 'Only .xslx file allowed',
                            },})} type="file" id="excelUpload" accept=".xlsx" className="custom-file-input"  onChange={handleFileInput} />
                        <Label className="custom-file-label" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name:'Choose file' }</Label>
                        {errors.excelUpload && <span className="text-danger d-block error">{errors.excelUpload.message}</span>}
                      </FormGroup>
                     </Col>
                     <Col sm={12} className="text-right">
                        <Button className="ctm-btn btn-sm" color="primary">Upload</Button>
                      </Col>
                  </Row>
                  </Form>  
                </ModalBody>
              </Modal>
            </CardHeader>
            <Form className="cardFilter">
                <Row className="mx-0 justify-content-start justify-content-md-center"> 
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                        <Input type="text" placeholder="Enter Debit Note No" className="form-control-sm" />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={2}>
                    <FormGroup>                      
                      <div className="position-relative">
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <Input type="select" name="select" className="form-control-sm">
                            <option>Select Category</option>
                            <option>Claim (As per liability)</option>
                            <option>Reversal (Freight issues)</option>
                            <option>Reversal (Weight issues)</option>
                        </Input>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                        <Input type="text" placeholder="Enter AWB No" className="form-control-sm" />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={5} lg={4} xl={3}>
                    <FormGroup>
                        <Input type="text" placeholder="Enter Courier Name" className="form-control-sm" />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      <DateRangePicker placeholder="Choose Tra. Date Range" format="dd-MM-yyyy"/>
                    </FormGroup>                      
                  </Col>
                  <Col sm={3} className="btn-container">
                    <Button className="ctm-btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="ctm-btn btn-sm mx-2" color="primary" outline>Reset</Button>
                  </Col>
                </Row>
              </Form>  
              <CardBody className="p-0">
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      <th className="text-nowrap">Category</th>
                      <th className="text-nowrap">Debit Note No.</th>
                      <th className="text-nowrap">AWB No.</th>
                      <th className="text-nowrap">Courier</th>
                      <th className="text-nowrap">Merchant</th>
                      <th className="text-nowrap">Desc.</th>
                      <th className="text-nowrap">Transaction Date</th>
                    </tr>
                   </thead>
                  <tbody>
                    <tr>
                      
                    </tr>
                   </tbody>
                </Table>
                <Row className="m-0 table-footer">
                  <Col xs="6">
                    <Pagination>
                          <PaginationItem className="prev">
                            <PaginationLink previous to="#" className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink> 
                          </PaginationItem>
                          <PaginationItem className="next ml-3">
                            <PaginationLink next to="#" className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                          </PaginationItem>
                      </Pagination>
                    </Col>
                    <Col xs="6">                
                    <Form>
                      <FormGroup>
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <Input type="select" name="select" className="form-control-sm">
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              </Input>
                      </FormGroup>
                    </Form>
                    </Col>
                </Row>
              </CardBody>
            </Card>
        </Card>
      </Col>
      
      </Row>
       </div>
  );
}

export default DebitNote;