import React, { useEffect, useRef, useState } from 'react';
import { Table,Modal,ModalBody,ModalHeader,Row, Col, FormGroup, Form, Label, Card, CardBody, CardSubtitle,CardTitle, Button, Spinner, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown , faCheck} from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import axios from 'axios';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { errorHandling } from '../../utlis';
import { baseString } from '../../config';
import ChannelOrder from './ChannelOrder';
import StarRatings from "react-star-ratings";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const EditSidebar = (props) => {
    const {selectedPickupPoint,handleSelectChange2,pickupLocation,setValue,selectedPickupPoint2,handleSelectChange3,service,courierwithPrice ,searchQuery,pickupDefault,
    setSearchQuery,
    sortOrder,
    handleSortOrderChange,
    selectedCourier,
    setSelectedCourier,
    setSelectedCourierPrice,
    orderDetailDate,
    faCheck,handleFormSubmit,onSubmitOrder,setChannelOrder,setTableLoader,setOrderUpdate,setEditmodalOrder,handleInputChange,setFormDataN,formDataN} = props; 
  const [orderDetail, setOrderDetail] = useState({})
  const [loader, setLoader] = useState(false);

  const [showFirstPopup, setShowFirstPopup] = useState(true);
  const [showPopup, setShowPopup] = useState(true);

  const [scheduledDate, setScheduledDate] = useState(null);


  useEffect(() => {
    const responseDate = new Date(orderDetailDate);
    const currentHour = responseDate.getUTCHours();
    const isAfter12pm = currentHour >= 12;

    // If the given time is after 12pm, set scheduled date to the next day
    if (isAfter12pm) {
      const nextDay = new Date(responseDate);
      nextDay.setUTCDate(responseDate.getUTCDate() + 1);
      setScheduledDate(nextDay);
    } else {
      // If the given time is before 12pm, scheduled date is the same day
      setScheduledDate(responseDate);
    }
  }, [orderDetailDate]);

  const getRatingColor = (val) => {
    if (val < 3) return "red";
    else if (val < 4) return "#fd7e14";
    else if (val <= 5) return "#198754";
  };

  

  const [editedData,setEditedData]=useState({});
  
  const { register: editOrderDetails, handleSubmit: orderDetailEditSubmit, formState: { errors: errors2 }, reset: reset2 } = useForm();
  const onEditOrderDetailSubmit = (data) => {

    setEditedData(data)
   
    
    setLoader(true);
    data.merchant_id = props.merchantId
    data.order_id = data.id
    if (checkUndeNullBlank(props.orderDetails.orderDetail.system_tags)) {
      let text = props.orderDetails.orderDetail.system_tags;
      let result = text.match(/Short Address/g);
      if (checkUndeNullBlank(result) && result[0] === 'Short Address') {
        if (props.orderDetails.orderDetail.system_tags.indexOf("Address Updated") !== -1) {
          data.system_tags = props.orderDetails.orderDetail.system_tags;
        } else {
          data.system_tags = props.orderDetails.orderDetail.system_tags.replace("Short Address", "Address Updated");;
        }
      }
    } else {
      data.system_tags = "Address Updated";
    }
    

      // setShowFirstPopup(false)
      // setShowPopup(true);   
      // setShowFirstPopup(true)
        setShowFirstPopup(true);
        setShowPopup(true);   

      const { toggleEditOrderOpen } = props;

          toggleEditOrderOpen();

  };
  
  const [calculatedValue, setCalculatedValue] = useState(null);
  const [chargableWeight, setChargableWeight] = useState(null);

  const handleCalculate = () => {
    const { length, breadth, height,weight } = formDataN;
    const result = (length * breadth * height) / 5000;
    setCalculatedValue(result);
    const higherWeight = Math.max(result, parseFloat(weight) || 0);
    setChargableWeight(higherWeight);
  };
 

  const RtsOdrConfirm = (data) => {
    Swal.fire({
      html: '<h6 class="mb-0">Do you want to mark this Ready To Ship ?</h6>',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      customClass: 'confirm-swal-fortag-con rts-con'
    }).then((result) => {
      if (result.isConfirmed) {
        data.merchant_id = props.merchantId;
        data.order_id = data.id;

        if (checkUndeNullBlank(props.orderDetails.orderDetail.system_tags)) {
          if (props.orderDetails.orderDetail.system_tags.indexOf("Ready To Ship") !== -1) {
            data.system_tags = props.orderDetails.orderDetail.system_tags;
          } else {
            data.system_tags = props.orderDetails.orderDetail.system_tags + ",Ready To Ship";
          }
        } else {
          data.system_tags = "Ready To Ship";
        }
        axios({
          method: "post",
          url: `${process.env.REACT_APP_CHANNEL_ORDER_EDIT}`,
          data: data,
          headers: { "token": window.btoa(baseString) }
        }).then((res) => {
          setLoader(false);
          if (res.data.error === false) {
            props.setEditmodal !== undefined && props.setEditmodal(false);
            props.setOrderDetail !== undefined && props.setOrderDetail({});
            reset2();
            Swal.fire({
              title: 'Success',
              text: res.data.msg,
              icon: 'success',
              customClass: 'swal_scs_err_popup'
            })
           
            setOrderDetail(res.data.data)

          } else {
            setLoader(false);
            Swal.fire({ icon: "error", title: "Error", text: res.data.msg, customClass: 'swal_scs_err_popup', })
          }
        })
          .catch((err) => {
            errorHandling(err, "")
          });
      }
    })
  }

  const CancelOdrConfirm = () => {
    Swal.fire({
      html: '<h6 class="mb-0">Do you want to cancel order ?</h6>',
      input: 'textarea',
      inputAttributes: {
        autocapitalize: 'off',
        placeholder: 'Please Enter Remarks',
      },
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: 'Save',
      customClass: {
        popup: 'confirm-swal-fortag-con',
        input: 'form-control form-control-sm cancel-odr-remarks'
      },
      showLoaderOnConfirm: true,
      preConfirm: (inputVal) => {
        let data = {}
        data.order_ids = checkUndeNullBlank(props.orderDetails.orderDetail.id) ? [props.orderDetails.orderDetail.id] : 0;
        data.merchant_name = checkData(props.user) && props.user[0].name;
        data.merchant_id = checkData(props.user) && props.user[0].id;
        data.order_status_id = 2;
        data.remarks = inputVal;

        axios({
          method: "post",
          url: `${process.env.REACT_APP_CHANNEL_ORDER_UPDATE_STATUS}`,
          data: data,
          headers: { "token": window.btoa(baseString) }
        }).then((res) => {
          if (res.data.error === false) {
            props.setEditmodal !== undefined && props.setEditmodal(false);
            props.setOrderDetail !== undefined && props.setOrderDetail({});
            reset2();
            Swal.fire({ icon: 'success', text: res.data.msg, title: 'Success', customClass: 'swal_scs_err_popup', });
          } else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', customClass: 'swal_scs_err_popup', })
          }
        }).catch((err) => {
          errorHandling(err, "");
        });
      },
    })
  }



  return (
    <>
      {loader && <div className="formLoader"><Spinner /></div>}

      
        
      {showPopup && (
        <Form className='ship-popup-box'>
           
        <div className='row justify-content-between'>
          
        <FormGroup className='col-4 weight-barr'>
          <Label>Enter Dead Weight</Label>
          <input
            type='text'
            name='weight'
            className='form-control'
            value={formDataN.weight}
            onChange={handleInputChange}
          />
          <span class="dimension"> Kg </span>
        </FormGroup>
        <div className='col-8 d-flex justify-content-end pr-0'>
        <FormGroup className='col-4 down-icon-parent'>
          <Label>
            Select Pickup Point
            <span className="down-icon-pick">
            {<FontAwesomeIcon icon={faChevronDown} />}
            </span>
          </Label>
          <select
  className="form-control-sm form-control choose-option"
  value={selectedPickupPoint}
  onChange={handleSelectChange2}
  style={{ background: '#f3f3f3;' }}
>
  {pickupLocation !== undefined &&
    pickupLocation !== null &&
    Object.entries(pickupLocation).length > 0 ? (
    <>
    
    <option>Select Pickup Point</option>
      {pickupDefault.map((val, index) => (
        <option
        className='default-opt'
          key={`default_${index}`}
          value={val.pickup_pincode}
          selected={index === 0}
        >
           {val.contact_name}
        </option>
      ))}
      {pickupLocation.map((item, index) => (
        <option
          key={`location_${index}`}
          value={item.pickup_pincode}
        >
          {item.contact_name}
        </option>
      ))}
    </>
  ) : (
    <option value="">No options available</option>
  )}
</select>


        </FormGroup>
        <FormGroup className='col-4 down-icon-parent'>
          <Label>Select Services
            <span className='down-icon-pick'>
            {<FontAwesomeIcon icon={faChevronDown} />}
            </span>
          </Label>
          <select
            className="form-control-sm form-control choose-option "
            value={selectedPickupPoint2}
            onChange={handleSelectChange3}
            style={{background:'#f3f3f3;'}}
          >
            <option value="">
              Select Service
            </option>
            {Object.entries(service).map(
              ([key, value]) => (
                <option value={key}>
                  {value}
                </option>
              )
            )}
          </select>
        </FormGroup>
        </div>
        
        </div>
       
        <div className='vol-calc d-flex justify-content-between align-items-center'>
         <FormGroup>
          <Label>Volumetric Height</Label>
          <input
            type='text'
            name='height'
            className='form-control'
            value={formDataN.height}
            onChange={handleInputChange}
          />
          <span class="dimension"> CM </span>
        </FormGroup>
        <FormGroup>
          <Label>Volumetric Breadth</Label>
          <input
            type='text'
            name='breadth'
            className='form-control'
            value={formDataN.breadth}
            onChange={handleInputChange}
          />
          <span class="dimension"> CM </span>
        </FormGroup>
        {/* <FormGroup>
          <Label>Enter Weight</Label>
          <input
            type='text'
            name='weight'
            className='form-control'
            value={formDataN.weight}
            onChange={handleInputChange}
          />
          <span class="dimension"> Kg </span>
        </FormGroup> */}
        <FormGroup>
          <Label>Volumetric Length</Label>
          <input
            type='text'
            name='length'
            className='form-control'
            value={formDataN.length}
            onChange={handleInputChange}
          />
          <span class="dimension"> CM </span>
        </FormGroup>
        <button type="button" className='btn btn-primary btn-price-cal' onClick={handleCalculate}>Fetch Price</button>
        </div>

        
<div className='chargable d-flex'>
        {calculatedValue !== null && (
        <div className='mr-5'>
          <p>Volumetric Weight:<Badge className='ml-2'>{calculatedValue} Kg</Badge></p>
        </div>
      )}
      {chargableWeight !== null && (
        <div>
          <p>Chargable Weight:<span className="badge ml-2" style={{background:'lightgreen', color:'green',fontSize:'13px' }}>{chargableWeight} Kg</span></p>
        </div>
      )}
      </div>
       
      <div className="courier-list" name="scroll-to-element">
  {checkData(courierwithPrice) && courierwithPrice ? (
    <>
      <div className='courier-box d-flex justify-content-between'>
        <FormGroup className='form-group col-12 p-0'>
          <Label>Search Courier</Label>
          <input
            className="form-control mb-4"
            type="text"
            placeholder="Search courier ..."
            value={searchQuery}
            style={{ padding: '18px' }}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </FormGroup>
        {/* <FormGroup className='form-group col-3'>
          <Label className="mr-2">Sort By Price</Label>
          <select
            value={sortOrder}
            onChange={handleSortOrderChange}
            className="form-select mb-4"
          >
            <option selected value="">
              Select
            </option>
            <option value="asc">Low to High</option>
            <option value="desc">High to Low</option>
          </select>
        </FormGroup> */}
      </div>
    </>
  ) : (
    ""
  )}

    <div className='channel-order-tt'>
  <Table className='text-left custom-table channel-table table courier-table'>
  {checkData(courierwithPrice) && courierwithPrice ? (
    <thead>
      <tr>
        <th></th>
        <th className="text-left">Courier Name</th>
        <th className="text-center">Chargable Weight</th>
        <th className="text-center">EDD
        <select
            value={sortOrder}
            onChange={handleSortOrderChange}
            className="form-select sortOrdeer "
            style={{marginBottom:'0 !important'}}
          >
            <option selected value="">
              Select
            </option>
            <option value="asc">Low to High</option>
            <option value="desc">High to Low</option>
          </select>
        </th>
        <th className="text-center">Rating
        <select
            value={sortOrder}
            onChange={handleSortOrderChange}
            className="form-select sortOrdeer "
            style={{marginBottom:'0 !important'}}
          >
            <option selected value="">
              Select
            </option>
            <option value="asc">Low to High</option>
            <option value="desc">High to Low</option>
          </select>
        </th>
        <th className="text-center">SPD</th>
        <th className="text-center">
        Price
          <select
            value={sortOrder}
            onChange={handleSortOrderChange}
            className="form-select sortOrdeer "
            style={{marginBottom:'0 !important'}}
          >
            <option selected value="">
              Select
            </option>
            <option value="asc">Low to High</option>
            <option value="desc">High to Low</option>
          </select>
          </th>
      </tr>
    </thead>
    ):(
      ""
    )}
    <tbody>
      {loader ? (
        <tr>
          <td colSpan="7" className="text-center">
            <p>no result found</p>
          </td>
        </tr>
      ) : (
        checkData(courierwithPrice) &&
        courierwithPrice
          .filter(item => {
            return item.courier_name
              .toLowerCase()
              .includes(searchQuery.toLowerCase());
          })
          .sort((a, b) => {
            if (sortOrder === "asc") {
              return a?.price?.total - b?.price?.total;
            }
            if (sortOrder === "desc") {
              return b?.price?.total - a?.price?.total;
            } else {
              return 0;
            }
          })
          ?.map((item, index) => (
            <tr key={index}>
              <td className="text-center">
                <input
                  type="radio"
                  className='form-check-input radio-id-tot'
                  disabled={
                    checkData(item.price) &&
                    checkUndeNullBlank(item.price.total) &&
                    item.price.total >= 0
                      ? false
                      : true
                  }
                  value={item.courier_id}
                  id={item.courier_id}
                  checked={selectedCourier === item.courier_id}
                  onChange={() => {
                    setSelectedCourier(item.courier_id);
                    setSelectedCourierPrice({
                      all_charges: item?.price,
                      all_prices: item?.price?.total,
                      zone_category_id: item?.zone_category_id,
                    });
                  }}
                />
              </td>
              <td className="text-center d-flex align-items-center">
                <img
                  src={process.env.REACT_APP_IMAGE_COURIER + item?.courier_img}
                  alt="courier img"
                  className="courier_img img-fluid mr-3"
                  width="60"
                />
                <h6>{item.courier_name}</h6>
              </td>
              <td className="text-center">{item?.charged_weight_in_kgs || "NA"} Kg</td>
              <td className="text-center">{item?.tat_in_days || "NA"} Days</td>
              <td className="text-center">
              <StarRatings
                rating={4}
                starDimension="12px"
                starSpacing="1px"
                starRatedColor={getRatingColor(
                  4
                )}
                numberOfStars={5}
                                  />
              </td>
              <td className="text-center">
              {scheduledDate?.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' })}
              </td>
              <td>
                <b>
                ₹
                {checkData(item?.price) &&
                checkUndeNullBlank(item?.price?.total)
                  ? item?.price?.total?.toFixed(2)
                  : "NA"}
                  </b>
              </td>
            </tr>
          ))
      )}
    </tbody>
  </Table>
  </div>
</div>

        {courierwithPrice.length >
        0 ? null : (
          <Button
            onClick={handleFormSubmit}
            type="submit"
            color="primary"
            
          >
            Check Price
          </Button>
        )}
        {courierwithPrice.length > 0 && (
          <p
            className="btn bg-success mb-0 ml-4 text-light fs-6 "
            onClick={onSubmitOrder}
            type="submit"
            color="primary"
          >
            Place Order
          </p>
        )}
</Form>

      )}
    </>
  );
}

export default EditSidebar;