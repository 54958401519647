import React, { useState, useEffect } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  Col,
  Row,
  Button,
  Alert,
  CardTitle,
} from "reactstrap";
import axios from "axios";
import { errorHandling } from "../../../utlis";
import { baseString } from "../../../config";
import { checkData, checkUndeNullBlank } from "../../../ObjectExist";
import Swal from "sweetalert2";
import { FaEye } from "react-icons/fa";
import Courier_Assign from "../../../assets/img/Courier Assigned.jpeg";
import Order_confirmed from "../../../assets/img/Order Confirmed.jpeg";
import Delivery_failed from "../../../assets/img/Delivery Failed.jpeg";



const Whatsapp = (props) => {
  const [loader, setLoader] = useState(false);
  const [template, setTemplate] = useState([]);
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [input4, setInput4] = useState("");
  const [input5, setInput5] = useState("");
  const [input6, setInput6] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [switchOn, setSwitchOn] = useState(0);
  // const [smsOn,setSMSOn] = useState(0);
  const [error, setError] = useState("");
  const [inputFields, setInputFields] = useState([1]);

  const [dynamicMsgVars, setDynamicMsgVars] = useState({});
  const [dynamicBtnVars, setDynamicBtnVars] = useState({ text: "", url: "" });

  const [whatappData, setWhatsappData] = useState({});
  const [whatappDataEdit, setWhatsappDataEdit] = useState();

  const [sociaiPhoneId, setSociaiPhoneId] = useState("");
  const [sociaiUserId, setSociaiUserId] = useState("");
  const [hover, setHover] = useState(false);
  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [hover4, setHover4] = useState(false);

  const handleTemplateChange = (event) => {
    const checked = event.target.checked;
    const value = event.target.value;
    if (checked) {
      setTemplate([...template, value]);
    } else {
      setTemplate(template.filter((item) => item !== value));
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInput(value);
  };

  const handleInputChange2 = (event) => {
    const value2 = event.target.value;
    setInput2(value2);
  };



  const handleInputChange3 = (event) => {
    const value3 = event.target.value;
    setInput3(value3);
  };

  const handleInputChange4 = (event) => {
    const value4 = event.target.value;
    setInput4(value4);
  };

  const handleInputChange5 = (event) => {
    const value5 = event.target.value;
    setInput5(value5);
  };

  const handleInputChange6 = (event) => {
    const value6 = event.target.value;
    setInput6(value6);
  };



  const handlePhoneNumberChange = (event) => {
    let input = event.target.value;
    setPhoneNumber(input);
  };
  



  const handleSwitchChange = () => {
    setSwitchOn((prevValue) => (prevValue === 0 ? 1 : 0));
  };

  // const handleSMSChange = ()=>{
  //   setSMSOn((val)=>(val === 0 ? 1:0));
  // }

  // const handleMsgVarChange = (key, value) => {
  //   setDynamicMsgVars((prevState) => ({
  //     ...prevState,
  //     [key]: value,
  //   }));
  // };

  const handleSociaiPhoneIdChange = (event) => {
    setSociaiPhoneId(event.target.value);
  };
          
  const handleSociaiUserIdChange = (event) => {
    setSociaiUserId(event.target.value);
  };

  const addInputField = () => {
    if (inputFields.length < 10) { // Limit to 10 inputs
      setInputFields([...inputFields, inputFields.length + 1]);
    }
  };

  // const handleBtnVarChange = (event) => {
  //   const { name, value } = event.target;
  //   setDynamicBtnVars((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_MERCHANT_WHATSAPP_SERVICE_VIEW,
          {
            merchant_id:
              props.user !== undefined &&
              props.user !== null &&
              props.user[0].user_type === "Merchant"
                ? props.user[0].id
                : "",
          },
          { headers: { token: window.btoa(baseString) } }
        );
         
        if (response.data.length > 0) {
          setWhatsappDataEdit(response.data[0]);
          // console.log(whatappDataEdit,'wyy')
        }
      } catch (error) {
        errorHandling(error, "reload");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (whatappDataEdit) {
      setInput(whatappDataEdit.order_confirmation_template_name || "");
      setInput2(whatappDataEdit.delivery_failed_template_name || "");
      setInput3(whatappDataEdit.whatsapp_token || "");
      setInput4(whatappDataEdit.order_dispatch_template_name || "");
      setInput5(whatappDataEdit.order_ofd_template_name || "");
      setInput6(whatappDataEdit.courier_assign_template_name || "");
      setPhoneNumber(whatappDataEdit.whatsapp_phone || "");
      setSwitchOn(whatappDataEdit.whatsapp_on_off || 0);
      setSociaiPhoneId(whatappDataEdit.sociai_phone_id || ""); // Set Sociai Phone ID
      setSociaiUserId(whatappDataEdit.sociai_user_id || "");  // Set Sociai User ID
    }

    setTemplate([
      whatappDataEdit?.order_confirmation_template_name?.length > 0
        ? "orderPlaced"
        : "",
      whatappDataEdit?.delivery_failed_template_name?.length > 0
        ? "deliveryFailed"
        : "",
        whatappDataEdit?.order_dispatch_template_name?.length > 0
        ? "orderdispatched"
        : "",
        whatappDataEdit?.order_ofd_template_name?.length >0 
        ? "outfordelhivery"
        : "",
        whatappDataEdit?.courier_assign_template_name?.length >0 
        ? "courierAssigned"
        : "",

    ]);
  }, [whatappDataEdit]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (template.length === 0 || !switchOn) {
      setError("Please check the checkbox and turn on the service");
      return;
    }
    setError("");

    whatappData.whatsapp_phone = phoneNumber;
    whatappData.merchant_id = props.user[0].id;
    whatappData.whatsapp_on_off = switchOn;
    whatappData.order_confirmation_template_name = input;
    whatappData.delivery_failed_template_name = input2;
    whatappData.order_dispatch_template_name = input4;
    whatappData.order_ofd_template_name = input5;
    whatappData.courier_assign_template_name = input6;
    
    // whatappData.order_confirmation_template_name = input4;
    whatappData.sociai_phone_id = sociaiPhoneId;  // Include Sociai Phone ID
    whatappData.sociai_user_id = sociaiUserId;   // Include Sociai User ID
    whatappData.whatsapp_token = input3;
    whatappData.dynamic_msg_vars= dynamicMsgVars;  // Add dynamic message variables
    whatappData.dynamic_btn_vars=dynamicBtnVars;  // Add dynamic button variables

    // console.log(whatappData,'whatappdata')

    if (checkData(props.user) && props.user[0].user_type === "Merchant") {
      setLoader(true);
      axios({
        method: "post",
        url: process.env.REACT_APP_MERCHANT_WHATSAPP_SERVICE,
        data: whatappData,
        headers: { token: window.btoa(baseString) },
      })
        .then(function (response) {
          // console.log(response,'ess')
          setLoader(false);
          if (response.data.error === false) {
            Swal.fire({
              title: "Success",
              text: response.data.msg
                ? response.data.msg
                : "Data Saved Successfully",
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "Error",
              text: response.data.msg
                ? response.data.msg
                : "Something went wrong",
              icon: "error",
            });
          }
        })
        .catch(function (err) {
          setLoader(false);
          errorHandling(err, "reload");
        });
    }
    // console.log(`Form submitted:\nPhone Number: ${phoneNumber}\nService: ${switchOn ? "1" : "0"}\nTemplate: ${template}\nInput: ${input}`);
  };
  return (
    <>
    <Form>
      <div className="sub-page-header">
        <CardTitle tag="h4" className="sub-page-title">
          Whatsapp Integration
        </CardTitle>
      </div>
      <Col sm={6}>
          <FormGroup>
            <Label for="sociai_user_id">Sociai User ID:</Label>
            <InputGroup>
              <Input
                style={{ height: "auto" }}
                type="text"
                id="sociai_user_id"
                placeholder="Enter Sociai ID"
                value={sociaiUserId}
                onChange={handleSociaiUserIdChange}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup>
            <Label for="sociai_phone_id">Sociai Phone ID:</Label>
            <InputGroup>
              <Input
                style={{ height: "auto" }}
                type="text"
                id="sociai_phone_id"
                placeholder="Enter Sociai Phone ID"
                value={sociaiPhoneId}
                onChange={handleSociaiPhoneIdChange}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      <Row style={{ alignItems: "center" }}>
        <Col sm={6}>
          <FormGroup>
            <Label for="phoneNumber">Sender Number:</Label>
            <InputGroup>
              <InputGroupAddon addonType="prepend">+91</InputGroupAddon>
              <Input
                style={{ height: "auto" }}
                type="text"
                id="phoneNumber"
                placeholder="Enter 10-digit number"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup>
            <Label for="sociai_user_id">Sociai User ID:</Label>
            <InputGroup>
              <Input
                style={{ height: "auto" }}
                type="text"

                id="sociai_user_id"
                placeholder="Enter Sociai ID"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup>
            <Label for="sociai_phone_id">Sociai Phone ID:</Label>
            <InputGroup>
              <Input
                style={{ height: "auto" }}
                type="text"
                id="sociai_phone_id"
                placeholder="Enter Sociai Phone ID"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup>
            <Label for="sociai_user_id">Sender Number:</Label>
            <InputGroup>
              <Input
                style={{ height: "auto" }}
                type="text"
                id="sociai_user_id"
                placeholder="Enter Sociai ID"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup style={{ marginBottom: 0, marginLeft: "30px" }}>
            <Label for="switch">Service:</Label>
            <CustomInput
              type="switch"
              id="switch"
              checked={switchOn}
              onChange={handleSwitchChange}
              label={switchOn ? "on" : "off"}
              value={switchOn ? 1 : 0}
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup className="wptoken mt-4 mb-3">
        <Row
          className="d-flex"
          style={{ paddingLeft: "25px", alignItems: "center" }}
        >
          <Label className="d-flex pl-0" for="input">
            Whatsapp Token:
          </Label>
          <Input
            type="text"
            id="input"
            placeholder="Enter Whatsapp Token"
            value={input3}
            onChange={handleInputChange3}
          />
        </Row>
      </FormGroup>
      <FormGroup tag="fieldset">
        <Label>
          <b>Templates:</b>
        </Label>
        <Row style={{ paddingLeft: "25px", alignItems: "center"}}>
          <CustomInput
            type="checkbox"
            id="orderPlaced"
            name="orderPlaced"
            label="Order Placed"
            value="orderPlaced"
            className="col-2"
            checked={template.includes("orderPlaced")}
            onChange={handleTemplateChange}
          />
          
          {template.includes("orderPlaced") && (
            <FormGroup className="col-6">
              
              <Label for="input" style={{paddingRight:"10px"}}>Enter Template Name :</Label>
              
              {hover && <img src={Order_confirmed} alt="Hover Image" style={{position:"absolute",top:"-310px",width:"300px", height:"300px", display:"block"}} />}
              <FaEye
                   onMouseEnter={() => setHover(true)}
                   onMouseLeave={() => setHover(false)}
                   style={{color:"#1b5a8f", cursor:"pointer",position:"relative",}}
                    />
              
              
              <Input
                type="text"
                id="input"
                placeholder="Enter Name"
                value={input}
                onChange={handleInputChange}
              />
              
            </FormGroup>
          )}
          
        </Row>
        <Row  style={{ paddingLeft: "25px", alignItems: "center", }}>
          <CustomInput
            type="checkbox"
            id="courierAssigned"
            name="courierAssigned"
            label="Courier Assign"
            value="courierAssigned"
            className="col-2"
            checked={template.includes("courierAssigned")}
            onChange={handleTemplateChange}
            
          />
          {template.includes("courierAssigned") && (
            <FormGroup className="col-6">
              <Label for="input" style={{paddingRight:"10px"}}>Enter Template Name :</Label>
              {hover1 && <img src={Courier_Assign} alt="Hover Image" style={{position:"absolute",top:"-310px",width:"300px", height:"300px", display:"block"}} />}
              <FaEye
                   onMouseEnter={() => setHover1(true)}
                   onMouseLeave={() => setHover1(false)}
                   style={{color:"#1b5a8f", cursor:"pointer",position:"relative",}}
                    />
              <Input
                type="text"
                id="input"
                placeholder="Enter Name"
                value={input6}
                onChange={handleInputChange6}
              />
            </FormGroup>
          )}
        </Row>
        <Row style={{ paddingLeft: "25px", alignItems: "center", }}>
          <CustomInput
            type="checkbox"
            id="orderdispatched"
            name="orderdispatched"
            label="Order Dispatch"
            value="orderdispatched"
            className="col-2"
            checked={template.includes("orderdispatched")}
            onChange={handleTemplateChange}
            
          />
          {template.includes("orderdispatched") && (
            <FormGroup className="col-6">
              <Label for="input" style={{paddingRight:"10px"}}>Enter Template Name :</Label>
              {hover2 && <img src="https://via.placeholder.com/150" alt="Hover Image" style={{position:"absolute",top:"-310px",width:"300px", height:"300px", display:"block"}} />}
              <FaEye
                   onMouseEnter={() => setHover2(true)}
                   onMouseLeave={() => setHover2(false)}
                   style={{color:"#1b5a8f", cursor:"pointer",position:"relative",}}
                    />
              <Input
                type="text"
                id="input"
                placeholder="Enter Name"
                value={input4}
                onChange={handleInputChange4}
              />
            </FormGroup>
          )}
        </Row>
        <Row style={{ paddingLeft: "25px", alignItems: "center", }}>
          <CustomInput
            type="checkbox"
            id="outfordelhivery"
            name="outfordelhivery"
            label="Out for Delivery"
            value="outfordelhivery"
            className="col-2"
            checked={template.includes("outfordelhivery")}
            onChange={handleTemplateChange}
            
          />
          {template.includes("outfordelhivery") && (
            <FormGroup className="col-6">
              <Label for="input" style={{paddingRight:"10px"}}>Enter Template Name :</Label>
              {hover3 && <img src="https://via.placeholder.com/150" alt="Hover Image" style={{position:"absolute",top:"-310px",width:"300px", height:"300px", display:"block"}} />}
              <FaEye
                   onMouseEnter={() => setHover3(true)}
                   onMouseLeave={() => setHover3(false)}
                   style={{color:"#1b5a8f", cursor:"pointer",position:"relative",}}
                    />
              <Input
                type="text"
                id="input"
                placeholder="Enter Name"
                value={input5}
                onChange={handleInputChange5}
              />
            </FormGroup>
          )}
        </Row>
        <Row style={{ paddingLeft: "25px", alignItems: "center" }}>
          <CustomInput
            type="checkbox"
            id="deliveryFailed"
            name="deliveryFailed"
            label="Delivery Failed"
            value="deliveryFailed"
            className="col-2"
            checked={template.includes("deliveryFailed")}
            onChange={handleTemplateChange}
          />
          {template.includes("deliveryFailed") && (
            <FormGroup className="col-6">
              <Label for="input" style={{paddingRight:"10px"}}>Enter Template Name :</Label>
              {hover4 && <img src={Delivery_failed} alt="Hover Image" style={{position:"absolute",top:"-310px",width:"300px", height:"300px", display:"block"}} />}
              <FaEye
                   onMouseEnter={() => setHover4(true)}
                   onMouseLeave={() => setHover4(false)}
                   style={{color:"#1b5a8f", cursor:"pointer",position:"relative",}}
                    />
              <Input
                type="text"
                id="input"
                placeholder="Enter Name"
                value={input2}
                onChange={handleInputChange2}
              />
            </FormGroup>
          )}
        </Row>
        
        
       
      </FormGroup>

      {error && (
        <Alert style={{ marginTop: "20px" }} color="danger">
          {error}
        </Alert>
      )}

      {/* Dynamic Message Variables */}
      {/* {inputFields.map((field, index) => (
        <FormGroup key={index}>
          <Label for={`dynamicMsgVar${field}`}>Dynamic Message Var {field}:</Label>
          <Input
            type="text"
            id={`dynamicMsgVar${field}`}
            placeholder={`{{ ${field} }}`}
            onChange={(e) => handleMsgVarChange(field, e.target.value)}
          />
        </FormGroup>
      ))} */}

      {/* <Button
        color="primary"
        onClick={addInputField}
        disabled={inputFields.length >= 10}
      >
        Add More
      </Button> */}

      {/* Dynamic Button Variables */}
      {/* <FormGroup>
        <Label for="dynamicBtnText">Button Text:</Label>
        <Input
          type="text"
          id="dynamicBtnText"
          name="text"
          placeholder="Enter button text"
          value={dynamicBtnVars.text}
          onChange={handleBtnVarChange}
        />
      </FormGroup> */}
      {/* <FormGroup>
        <Label for="dynamicBtnUrl">Button URL:</Label>
        <Input
          type="text"
          id="dynamicBtnUrl"
          name="url"
          placeholder="Enter button URL"
          value={dynamicBtnVars.url}
          onChange={handleBtnVarChange}
        />
      </FormGroup> */}

      <Form>
        <Button
          className="btn-sm ctm-btn btn btn-primary mt-2"
          onClick={handleSubmit}
          type="submit"
        >
          Submit
        </Button>
      </Form>
    </Form>
    {/* // SMS Integration */}
    {/* <Form>
    <div className="sub-page-header" style={{marginTop:"40px"}}>
        <CardTitle tag="h4" className="sub-page-title">
          SMS Integration
        </CardTitle>
      </div>
      <Col sm={6}>
          <FormGroup style={{ marginBottom: 0 }}>
            <Label for="switch">SMS Service:</Label>
            <CustomInput
              type="switch"
              id="SMSswitch"
              checked={smsOn}
              onChange={handleSMSChange}
              label={smsOn ? "Active" : "Inactive"}
              value={smsOn ? 1 : 0}
            />
          </FormGroup>
        </Col>
    </Form> */}
    </>
    
  );
};

export default Whatsapp;

// order_assign_template_name	
// delivery_failed_template_name	
// order_confirmation_template_name