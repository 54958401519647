import React, { useEffect, useState } from 'react';
import { CardTitle, FormGroup, Label, CustomInput } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import { tr } from 'date-fns/locale';

const ModifyChannelIntegeration = (props) => {
  const [switchValue, setSwitchValue] = useState(false);
  const [switchValue2, setSwitchValue2] = useState(true);
  const [switchValue3, setSwitchValue3] = useState(true);
  const [switchValue4, setSwitchValue4] = useState(true);
  const [switchValue5, setSwitchValue5] = useState(true);
  const [switchValue6, setSwitchValue6] = useState(true);
  const [switchValue10, setSwitchValue10] = useState(true);


  useEffect(() => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_MERCHANT_CHANNEL_STATUS_VIEW}`,
      data: {
        merchant_id: props.merchantId,
      }
    }).then((res) => {
      if (res.data.error === false) {
        const settings = JSON.parse(res?.data?.settings[0]?.channel_order_settings);
        // console.log(settings.tagFilter, settings.multipleOrder, settings.suspiciousOrder, settings.userTags, settings.systemTags);
        setSwitchValue(res?.data?.data[0]?.channel_setup_block === 1 ? true : false);
        setSwitchValue2(settings?.tagFilter === 1 ? true : false);
        setSwitchValue3(settings?.multipleOrder === 1 ? true : false);
        setSwitchValue4(settings?.suspiciousOrder === 1 ? true : false);
        setSwitchValue5(settings?.userTags === 1 ? true : false);
        setSwitchValue6(settings?.systemTags === 1 ? true : false);
        setSwitchValue10(settings?.download === 1? true :false);
        
      }
    })
  }, [])


  const handleSwitchToggle = (event) => {
    const newSwitchValue = event.target.checked;
    setSwitchValue(newSwitchValue);

    const statusToSend = newSwitchValue ? 1 : 0;

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_MERCHANT_CHANNEL_STATUS_CHANGE}`,
      data: {
        merchant_id: props.merchantId,
        status: statusToSend,
      }
    })
    .then((res) => {
      if (res.data.error === false) {
        setSwitchValue(newSwitchValue);
      }
    })
    .catch((err) => {
      console.log(err);
    });
  };


  
  const handleSwitchToggle2 = (event) => {
    const newSwitchValue2 = event.target.checked;
    setSwitchValue2(newSwitchValue2);

    const statusToSend2 = newSwitchValue2 ? 'tag-filter-1' : 'tag-filter-0';

  };

  const handleSwitchToggle3 = (event) => {
    const newSwitchValue3 = event.target.checked;
    setSwitchValue3(newSwitchValue3);

    const statusToSend3 = newSwitchValue3 ? "multiple-order-1" : "multiple-order-0";

  };

  const handleSwitchToggle4 = (event) => {
    const newSwitchValue4 = event.target.checked;
    setSwitchValue4(newSwitchValue4);

    const statusToSend4 = newSwitchValue4 ? "suspicious-order-1" : "suspicious-order-0";

  };

  const handleSwitchToggle5 = (event) => {
    const newSwitchValue5 = event.target.checked;
    setSwitchValue5(newSwitchValue5);

    const statusToSend5 = newSwitchValue5 ? "User-Tags-Filter-1" : "User-Tags-Filter-0" ;

  };

  const handleSwitchToggle6 = (event) => {
    const newSwitchValue6 = event.target.checked;
    setSwitchValue6(newSwitchValue6);

    const statusToSend6 = newSwitchValue6 ? "System-Tags-Filter-1" : "System-Tags-Filter-0";

  };

    const handleSwitchToggle10 = (event) => {
    const newSwitchValue10 = event.target.checked;
    setSwitchValue10(newSwitchValue10);

    const statusToSend10 = newSwitchValue10 ? "download-1" : "download-0";

  };

  const switchTagsHandle = (e) => {
    e.preventDefault();
    const settings_payload = 
      {'tagFilter' : switchValue2 ? 1 : 0,
       'multipleOrder':switchValue3 ? 1:0,
       'suspiciousOrder': switchValue4 ? 1:0,
       'userTags' : switchValue5 ? 1:0,
       'systemTags': switchValue6 ? 1:0,
       'download' : switchValue10 ? 1:0,
      }
    ;

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_MERCHANT_CHANNEL_ORDER_STATUS}`,
      data: {
        merchant_id: props.merchantId,
        settings_payload: JSON.stringify(settings_payload),
      }
    })
    .then((res) => {
      if (res.data.error === false) {
        Swal.fire('Success', 'Channel Integration Updated Successfully', 'success');
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }



  return (
    <div>
      <div className="sub-page-header">
        <CardTitle tag="h4" className="sub-page-title">Channel Integration</CardTitle>
      </div>
      <FormGroup>
        <Label for="switch">Channel Integration: {switchValue ? 'ON' : 'OFF'}</Label>
        <CustomInput type="switch" id="switch" onChange={handleSwitchToggle} checked={switchValue} />
      </FormGroup>
        {/* <FormGroup>
          <Label for="switch2">Tags Filter: {switchValue2 ? 'ON' : 'OFF'}</Label>
          <CustomInput type="switch" id="switch2" onChange={handleSwitchToggle2} checked={switchValue2} />
        </FormGroup> */}
      <FormGroup>
        <Label for="switch5">User Tags Filter: {switchValue5 ? 'ON' : 'OFF'}</Label>
        <CustomInput type="switch" id="switch5" onChange={handleSwitchToggle5} checked={switchValue5} />
      </FormGroup>
      <FormGroup>
        <Label for="switch6">System Tags Filter: {switchValue6 ? 'ON' : 'OFF'}</Label>
        <CustomInput type="switch" id="switch6" onChange={handleSwitchToggle6} checked={switchValue6} />
      </FormGroup>

      {/* <FormGroup>
        <Label for="switch3">Multiple Order: {switchValue3 ? 'On' : 'OFF'}</Label>
        <CustomInput type="switch" id="switch3" onChange={handleSwitchToggle3} checked={switchValue3} />
      </FormGroup> */}
      {/* <FormGroup>
        <Label for="switch4">Suspicious Order: {switchValue4 ? 'On' : 'OFF'}</Label>
        <CustomInput type="switch" id="switch4" onChange={handleSwitchToggle4} checked={switchValue4} />
      </FormGroup>  */}
      <FormGroup>
        <Label for="switch10">Download: {switchValue10 ? 'ON' : 'OFF'}</Label>
        <CustomInput type="switch" id="switch10" onChange={handleSwitchToggle10} checked={switchValue10} />
      </FormGroup> 
       <button onClick={switchTagsHandle} className='btn' style={{backgroundColor:"#1b5a8f", color:"white"}} type='button'>Submit</button>

       
    </div>
  );
};

export default ModifyChannelIntegeration;

