import React, { useState } from 'react';
import { Row, Col, Button, FormGroup, Form, Modal, ModalHeader, ModalBody, Label, Spinner } from 'reactstrap';
// import 'rsuite/dist/styles/rsuite-default.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { baseString, wallet_head } from '../../../config';
import { errorHandling } from '../../../utlis';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useParams } from 'react-router-dom'

const AddPassbook = (props) => {
  const [loader, setLoader] = useState(false)
  const { merchantId } = useParams();
  const { register: addPassbook, handleSubmit: addPassbookSubmit, formState: { errors }, reset } = useForm();
  const onAddPassbookSubmit = (data) => {
    setLoader(true)
    data.merchant_id = merchantId;
    data.admin_id = props.user[0].id;
    axios({
      method: "post",
      url: `${process.env.REACT_APP_ENTRY_PASSBOOK}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false)
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          if (res.data.error === false) {
            props.setWalletMdl(false);
            props.passbookListing();
            reset();
            Swal.fire({ title: 'Successful', text: res.data.msg, icon: 'success' })
          } else {
            Swal.fire({ title: 'Error', text: res.data.msg, icon: 'error' })
          }
        }
      }
    }).catch((err) => {
      setLoader(false);
      errorHandling(err, "")
    });
  };
  const onError = (errors, e) => console.log('Error', errors);
  return (
    <>
      {loader && <div className="formLoader"><Spinner /></div>}
      <Modal isOpen={props.isOpen} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle}>Add New Entry</ModalHeader>
        <ModalBody>
          <Form onSubmit={addPassbookSubmit(onAddPassbookSubmit, onError)}>
            <Row className="mx-0 justify-content-center addModal">
              <Col sm={12}>
                <FormGroup>
                  <Label for="select_head">Select Head<span className="text-danger">*</span></Label>
                  <div className="position-relative">
                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                    <select {...addPassbook("head_id", { required: 'Please Select Head' })} className="form-control-sm form-control" id="select_head">
                      <option value="">Select Head</option>
                      {Object.entries(wallet_head).map(([key, value]) => (
                        <option value={key}>{value}</option>
                      ))}
                    </select>
                  </div>
                  {errors.head_id && <span className="text-danger d-block error">{errors.head_id.message}</span>}
                </FormGroup>
              </Col>
              <Col sm={12}>
                <FormGroup>
                  <Label for="amount">Enter Amount<span className="text-danger">*</span></Label>
                  <input {...addPassbook("amount", { required: 'Please Enter Amount' })} type="text" placeholder="Amount" id="amount" className="form-control" />
                  {errors.amount && <span className="text-danger d-block error">{errors.amount.message}</span>}
                </FormGroup>
              </Col>
              <Col sm={12}>
                <FormGroup>
                  <Label for="trans_id">Enter Transaction Id<span className="text-danger">*</span></Label>
                  <input  {...addPassbook("txnid", { required: 'Please Enter Transaction Id' })} placeholder="Transaction Id" id="trans_id" className="form-control" />
                  {errors.txnid && <span className="text-danger d-block error">{errors.txnid.message}</span>}
                </FormGroup>
              </Col>
              <Col sm={12}>
                <FormGroup>
                  <Label for="rmrks">Remarks</Label>
                  <input  {...addPassbook("remarks")} placeholder="Enter Remarks" id="rmrks" className="form-control" />
                  {errors.remarks && <span className="text-danger d-block error">{errors.remarks.message}</span>}
                </FormGroup>
              </Col>
              <Col sm={12} className="text-right">
                <Button className="btn ctm-btn btn-sm" color="primary">Save</Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
}

export default AddPassbook;