import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, FormGroup, Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, Label, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faFileExcel, faChevronRight, faDownload } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { ArrowLeft, ChevronLeft, ChevronRight } from 'react-feather';
import axios from 'axios';
import { changeIntoDate, errorHandling, remExtraSpace } from "../../utlis"
import configData, { baseString } from "../../config";
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';
import NodataFound from '.././NodataFound'
import LoaderDefault from '../LoaderDefault';
import config from '../../config';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';

const Zoning = (props) => {
  const [editmodal, setEditmodal] = useState(false);
  const [excelmodal, setExcelmodal] = useState(false);
  const [zoneList, setZoneList] = useState({});
  const [page, setPage] = useState(1);
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const { courierId } = useParams();
  const [editZone, setEditZone] = useState({});
  const [excelFile, setExcelFile] = useState();
  const [isExtval, setisExtval] = useState(0);
  const [isRoival, setisRoival] = useState(0);
  const [isSpecialval, setisSpecialval] = useState(0);
  const [isMetroval, setisMetroval] = useState(0);
  const [loader, setLoader] = useState(false);
  const [loadertable, setLoadertable] = useState(false);
  const [filterInput, setFilterInput] = useState('');
  const dropFilterCount = 20
  let courierName = (new URLSearchParams(window.location.search)).get("name")
  const excelEdit = () => {
    setExcelmodal(!excelmodal);
  }
  const handleFileInput = (e) => {
    setExcelFile(e.target.files[0])
  }
  const toggleEditClose = () => {
    setEditmodal(false);
  }
  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }
  function nextPageData() {
    if (checkData(zoneList.data) && checkUndeNullBlank(zoneList.totalPage)) {
      // &&checkUndeNullBlank(zoneList.totalPage).length>page.length
      if (zoneList.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(zoneList.data).length)
        setPage(page + 1)
        setLoader(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(zoneList.data)
      if (currentCount > Object.entries(zoneList.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }
  const toggleEdit = (i) => {
    setEditmodal(!editmodal);
    setLoader(true);
    fetch(`${process.env.REACT_APP_GET_COURIER_ZONING}/view/${i}`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
        "Accept": 'application/json',
        "token": window.btoa(baseString)
      },
    }).then((resp) => {
      resp.json().then((result) => {
        setLoader(false);
        let myResponse = result[0];
        reset2(myResponse);
        setEditZone(result[0]);
        setisExtval(myResponse.is_extended)
        setisRoival(myResponse.is_roi)
        setisSpecialval(myResponse.is_special_destination)
        setisMetroval(myResponse.is_metro)
      }).catch(err => {
        setLoader(false);
        errorHandling(err, "")
      });
    });
  }
  const zoningListing = () => {
    setLoadertable(true);
    setFilterInput('')
    setCurrentCount(1)
    setPage(1)
    axios({
      method: "post",
      url: `${process.env.REACT_APP_GET_COURIER_ZONING}`,
      data: { "courier_id": courierId },
      headers: { "token": window.btoa(baseString) }
    })
      .then(function (response) {
        setLoadertable(false);
        if (!response.data.fatal) {
          setZoneList(response.data)
        }
      })
      .catch(function (response) {
        setLoadertable(false);
        errorHandling(response, "reload");
      });
  }
  useEffect(() => {
    zoningListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courierId])
  const { register, handleSubmit } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };
  const onSubmit = (data, pagePerpage) => {
    pagePerpage.page = 1
    if (checkUndeNullBlank(data.pincode)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
      Swal.fire({ title: 'info', text: 'Please Choose Filter', icon: 'info', })
    }
  };
  const onCommonFilter = (data, pagePerpage) => {
    setFilterInput(data);
    setLoader(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_GET_COURIER_ZONING}?pincode=${checkUndeNullBlank(data.pincode) ? remExtraSpace(data.pincode) : ''}&page=${checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: { "courier_id": courierId },
      headers: { "token": window.btoa(baseString) }
    })
      .then(function (response) {
        setLoader(false);
        if (response.data.fatal !== true) {
          setZoneList(response.data)
        }
      }).catch(function (response) {
        setLoader(false);
        errorHandling(response, "");
      });
  };
  const { register: editZoningdata, handleSubmit: zoningEditSubmit, formState: { errors: errors2 }, reset: reset2 } = useForm();
  const onEditZoningSubmit = (data) => {
    setLoader(true);
    let formData = new FormData();
    formData.append("zone_id", data.zone_id);
    formData.append("pincode", data.pincode);
    formData.append("is_extended", data.is_extended);
    formData.append("is_metro", data.is_metro);
    formData.append("is_roi", data.is_roi);
    formData.append("is_special_destination", data.is_special_destination);

      axios({
        method: "post",
        url: `${process.env.REACT_APP_GET_COURIER_ZONING}/update/${editZone.id}`,
        data: formData,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
        setLoader(false);
        if (res.data.error === false) {
          setEditmodal(false);
          zoningListing();
          Swal.fire({ title: 'Success', text: res.data.msg, icon: 'success', });
        }
      })
      .catch(function (response) {
        setLoader(false);
        errorHandling(response, "");
      });
  };

  const { register: uploadZoningdata, handleSubmit: zoningUploadSubmit, formState: { errors: errors3 } } = useForm();
  const onUploadZoningSubmit = (data) => {
    setLoader(true);
    let formData = new FormData();
    formData.append("file", excelFile);
    formData.append("courier_id", courierId);

      axios({
        method: "post",
        url: `${process.env.REACT_APP_GET_COURIER_ZONING}/upload`,
        data: formData,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
        setLoader(false);
        if (res.data.error === false) {
          setExcelmodal(false);
          Swal.fire({ title: 'Success', text: res.data.msg, icon: 'success', });
          zoningListing();
        }
      })
      .catch(function (response) {
        setLoader(false);
        errorHandling(response, "upload");
      });
  };

  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
        <Col sm={12}>
          <Card className="p-0 my-3 courierZoing shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex justify-content-between flex-wrap">
                <CardTitle className="mb-0 page-title">Manage {courierName} Courier Zoing</CardTitle>
                <div className="card-header-right d-flex align-items-center ml-auto">
                  <Link to='/courier' className="btn-text btn-light"><span className="icon"><ArrowLeft /></span><span>Back to Courier List</span></Link>
                  <div className="btn-text btn-light mx-2" onClick={excelEdit}><span className="icon mr-2"><FontAwesomeIcon icon={faFileExcel} /></span><span>Upload Pincode</span></div>
                </div>
                <Modal isOpen={excelmodal} toggle={excelmodal} scrollable={true} >
                  <ModalHeader toggle={excelEdit}>Upload Pincodes</ModalHeader>
                  <ModalBody>
                    <Form onSubmit={zoningUploadSubmit(onUploadZoningSubmit)}>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Upload all Your Pincode via XLSX</p>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Please download below given template</p>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Remove sample data and upload your data</p>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Make sure you atleast fill mandatory fields.</p>

                      <Row className="m-0 pb-1">
                        <Col sm={12} className="p-0">
                          <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span> <Link target="_blank" download to="/format/courier_zoning.xlsx" className=""><FontAwesomeIcon icon={faDownload} />  Download
                          </Link>
                            <span className="ml-2">Order Format File</span></p>
                        </Col>
                        <Col sm={12} className="m-auto pt-1 p-0">
                          <FormGroup>
                            <input {...uploadZoningdata("excelUpload", {
                              required: 'Please Upload XLSX File',
                              validate: {
                                lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                                acceptedFormats: files =>
                                  ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                                    files[0]?.type
                                  ) || 'Only .xslx file allowed',
                              },
                            })} type="file" id="excelUpload" accept=".xlsx" className="custom-file-input" onChange={handleFileInput} />
                            <Label className="custom-file-label" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name : 'Choose file'}</Label>
                            {errors3.excelUpload && <span className="text-danger d-block error">{errors3.excelUpload.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12} className="text-right">
                          <Button className="ctm-btn btn-sm" color="primary">Upload</Button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
                <Modal isOpen={editmodal} toggle={editmodal} scrollable={true} >
                  <ModalHeader toggle={toggleEditClose}>Edit Courier Pincodes</ModalHeader>
                  <ModalBody>
                    <Form onSubmit={zoningEditSubmit(onEditZoningSubmit)}>
                      <Row className="mx-0 editModal">
                        <Col sm={12}>
                          <FormGroup>
                            <Label for="pincode">Enter Pincode</Label>
                            <input {...editZoningdata("pincode", { required: 'Please Enter Pincode' })} className="form-control-sm form-control" placeholder="Pincode*" id="pincode" checked="" />
                            {errors2.full_name && <span className="text-danger d-block mb-2">{errors2.full_name.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12}>
                          <FormGroup>
                            <Label for="selectZone">Select Zone</Label>
                            <div className="position-relative">
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select {...editZoningdata("zone_id")} className="form-control-sm form-control">
                                <option value="0">Selcet Service</option>
                                {Object.entries(config.zone_id).map(([key, value]) => (
                                  <option value={key}>{value}</option>
                                ))}
                              </select>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm={12}>
                          <FormGroup>
                            <Label>Is Extended</Label>
                            <div>
                              <span className="mr-2">
                                <input {...editZoningdata("is_extended", { required: 'Select Type' })} name="is_extended" type="radio" value="1" id="yes_ext" onClick={() => setisExtval(1)} checked={isExtval === 1} />
                                <Label className="mx-2" for="yes_ext">Yes</Label>
                              </span>
                              <span>
                                <input {...editZoningdata("is_extended", { required: 'Select Type' })} name="is_extended" type="radio" value="0" id="no_ext" onClick={() => setisExtval(0)} checked={isExtval === 0} />
                                <Label className="mx-2" for="no_ext">No</Label>
                              </span>
                            </div>
                            {errors2.is_extended && <span className="text-danger d-block mb-2">{errors2.is_extended.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12}>
                          <FormGroup>
                            <Label>Is ROI</Label>
                            <div>
                              <span className="mr-2">
                                <input {...editZoningdata("is_roi", { required: 'Select Type' })} type="radio" value="1" id="yes_roi" onClick={() => setisRoival(1)} checked={isRoival === 1} />
                                <Label className="mx-2" for="yes_roi">Yes</Label>
                              </span>
                              <span>
                                <input {...editZoningdata("is_roi", { required: 'Select Type' })} type="radio" value="0" id="no_roi" onClick={() => setisRoival(0)} checked={isRoival === 0} />
                                <Label className="mx-2" for="no_roi">No</Label>
                              </span>
                            </div>
                            {errors2.is_roi && <span className="text-danger d-block mb-2">{errors2.is_roi.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12}>
                          <FormGroup>
                            <Label>Is Special Destination</Label>
                            <div>
                              <span className="mr-2">
                                <input {...editZoningdata("is_special_destination", { required: 'Select Type' })} type="radio" value="1" id="yes_spec" onClick={() => setisSpecialval(1)} checked={isSpecialval === 1} />
                                <Label className="mx-2" for="yes_spec">Yes</Label>
                              </span>
                              <span>
                                <input {...editZoningdata("is_special_destination", { required: 'Select Type' })} type="radio" value="0" id="no_spec" onClick={() => setisSpecialval(0)} checked={isSpecialval === 0} />
                                <Label className="mx-2" for="no_spec">No</Label>
                              </span>
                            </div>
                            {errors2.is_special_destination && <span className="text-danger d-block mb-2">{errors2.is_special_destination.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12}>
                          <FormGroup>
                            <Label>Is Metro</Label>
                            <div>
                              <span className="mr-2">
                                <input {...editZoningdata("is_metro", { required: 'Select Type' })} type="radio" value="1" id="yes_metro" onClick={() => setisMetroval(1)} checked={isMetroval === 1} />
                                <Label className="mx-2" for="yes_metro">Yes</Label>
                              </span>
                              <span>
                                <input {...editZoningdata("is_metro", { required: 'Select Type' })} type="radio" value="0" id="no_metro" onClick={() => setisMetroval(0)} checked={isMetroval === 0} />
                                <Label className="mx-2" for="no_metro">No</Label>
                              </span>
                            </div>
                            {errors2.is_metro && <span className="text-danger d-block mb-2">{errors2.is_metro.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12} className="text-right">
                          <Button className="ctm-btn btn-sm" color="primary">Save</Button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
              </CardHeader>
              <Form onSubmit={handleSubmit(onSubmit)} className="cardFilter">
                <Row className="mx-0 pt-1 justify-content-start justify-content-lg-center">
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      <input {...register("pincode")} className="form-control-sm form-control" placeholder="Enter Pincode" />
                    </FormGroup>
                  </Col>
                  <Col sm={3} className="btn-container">
                    <Button className="ctm-btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="ctm-btn btn-sm mx-2" color="primary" outline onClick={zoningListing}>Reset</Button>
                  </Col>
                </Row>
              </Form>
              <CardBody className="p-0">
                <div className="showing-count">
                  {checkData(zoneList) && checkData(zoneList.data) && checkUndeNullBlank(zoneList.TotalRows) ?
                    <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(zoneList.data).length)} of {zoneList.TotalRows}</span> : ''}
                </div>
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      <th>Pincode</th>
                      <th>Extended</th>
                      <th>Special Dest.</th>
                      <th>Metro</th>
                      <th>ROI</th>
                      <th>Created</th>
                      <th>Modified</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkData(zoneList.data) ? zoneList.data.map((item, index) => (
                      <tr>
                        <td>{item.pincode}</td>
                        <td>{configData.yes_no[item.is_extended] || 'No'}</td>
                        <td>{configData.yes_no[item.is_special_destination] || 'No'}</td>
                        <td>{configData.yes_no[item.is_metro] || 'No'}</td>
                        <td>{configData.yes_no[item.is_roi] || 'No'}</td>
                        <td>{(item.created_at !== undefined && item.created_at !== null ? changeIntoDate(item.created_at) : 'NA')}</td>
                        <td>{(item.updated_at !== undefined && item.updated_at !== null ? changeIntoDate(item.updated_at) : 'NA')}</td>
                        <td><span><Button className="ctm-btn btn-sm ml-2" color="info" outline onClick={() => toggleEdit(item.id)}>Edit</Button></span></td>
                      </tr>
                    )) : loadertable ? <tr><td colSpan="8"><LoaderDefault /></td></tr> :
                      <tr><td colSpan="8"><NodataFound /></td></tr>}
                  </tbody>
                </Table>
                {checkData(zoneList) && checkData(zoneList.data) && checkUndeNullBlank(zoneList.TotalRows) ?
                  <>
                    {zoneList.TotalRows > dropFilterCount ?
                      <Row className="m-0 table-footer">
                        <Col xs="6">
                          <Pagination aria-label="Page navigation example align-items-center">
                            <PaginationItem className="prev">
                              <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                            </PaginationItem>
                            <PaginationItem className="next ml-3">
                              <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </Col>
                        <Col xs="6">
                          <Form>
                            <FormGroup>
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                                <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row> : ''}
                  </>
                  : ''}
              </CardBody>
            </Card>
          </Card>
        </Col>

      </Row>
    </div>
  );
}

export default Zoning;