import React, { useEffect, useState } from 'react';
import {Row, Col, Button, Form, FormGroup, Label, Input, CustomInput } from 'reactstrap';
import {
  errorHandling,
} from "../../utlis";
import {
  baseString,
} from "../../config";
import { checkData, checkUndeNullBlank } from "../../ObjectExist";
import Swal from "sweetalert2";
import axios from "axios";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown} from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';



const EditUserPermission = (props) => {
    const location = useLocation();
    const { user } = location.state || {};

    // console.log(user,'djkw')

  const [loader, setLoader] = useState(false);

  
  const initialPermissions ={
    Dashboard:["Dailysummary","Pendingshipment","Shipmentsummary","Freightandremittance","Courierremittance"],
    Masters:["City",'City-add','City-edit',"State","state-add",'State-edit'],
    Courier:["Add","Active","Edit","Courierdetail"],
    RegisteredMerchant:["Add","Active","Edit","Merchantdetail"],
    SignupMerchant:["Merchantdetail"],
    Allorders:["GetAllOrders"],
    ChannelOrders:["AllchannelOrders","Action",'Overview'],
    Shipments:["Allshipments","Cancel","Statusrecheck","Uploadbulkstatus","Changestatus"],
    Updatelost:["Updatelostshipment","Downlaodlostshipment"],
    RTO:['Rto-List',"Download"],
    LiveNDR:['LiveNDR-data','Take-action',"Actionpending","Actiontaken","ExportliveNDR","UploadNDR"],
    AllNDR:['AllNDR-Data',"ExportallNDR"],
    Weightmismatch:['Weight-data',"Download","Upload","Uploadweightmistchfile","Uploadweightmistchstatus"],
    Merchantremittance:['Merchantremmittance-data',"Downloadremittance"],
    Intiateremittance:['Upload',"Save","Delete"],
    Adjustremittance:["UploadAdjusted","DownloadAdjusted",'Adjustremmittance-data'],
    Remittancetransactions:["Remittancetransactions-data","Download"],
    Courierremittance:["Courierremittance-data","Download","Upload"],
    Merchantfreight:["Exportfreight","Freightrecalculate","Freightcorrection","Zonecorrection",'Freight-data'],
    Merchantinvoices:["Generateinvoice","Get"],
    Creditnote:["Upload","Get"],
    Debitnote:["Create","Get"],
    Utilitytool:["Order-Data","Get","Frieght-Data"],
    Servicibilty:['Get',"Upload","Download"],
    Pricecalculator:["Get"],
    Courierledger:["Create","Get"],
    Merchantledger:["Create","Get"],
};
   const [displayedPermissions, setDisplayedPermissions] = useState(initialPermissions);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    user_type: "",
    permissions: {},
  });
  useEffect(() => {
    let isMounted = true;
    axios({
      method: "get",
      url: `https://api.logistify.in/api/v1/users/listparticularuser/${user.id}`,
    })
      .then(function (response) {
        if (isMounted) {
          setLoader(false);
          if (response) {
            // console.log(response.data.data.userDetails, 'yeyhe');
            setFormData(prevFormData => ({
              ...prevFormData,
              ...response?.data?.data?.userDetails
            }));
          } else {
            Swal.fire({
              title: "Error",
              text: response.data.msg ? response.data.msg : "Something went wrong",
              icon: "error",
            });
          }
        }
      })
      .catch(function (err) {
        if (isMounted) {
          setLoader(false);
          Swal.fire({
            title: "Error",
            text: err,
            icon: "error",
          });
        }
      });
  
    return () => {
      isMounted = false;
    };
  }, [user.id]); // Add user.id to dependency array
  
  const handleEditForm = () => {
    axios({
      method: "post",
      url: `https://api.logistify.in/api/v1/users/edit/${user.id}`,
      data: formData,
    })
      .then(function (response) {
        setLoader(false);
        if (response.data.error === false) {
          Swal.fire({
            title: "Success",
            text: response.data.msg
              ? response.data.msg
              : "Data Saved Successfully",
            icon: "success",
          });
        } else {
          Swal.fire({
            title: "Error",
            text: response.data.msg
              ? response.data.msg
              : "Something went wrong",
            icon: "error",
          });
        }
      })
      .catch(function (err) {
        setLoader(false);
        errorHandling(err, "reload");
      });
  };



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleCheckboxChange = (category, permission) => {
    setFormData(prevData => {
      const isChecked = prevData.permissions[category]?.includes(permission);

      const updatedPermissions = {
        ...prevData.permissions,
        [category]: isChecked
          ? prevData.permissions[category].filter(p => p !== permission)
          : [...(prevData.permissions[category] || []), permission]
      };
      return {
        ...prevData,
        permissions: updatedPermissions
      };
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.name || !formData.email || !formData.password || !formData.user_type) {
      Swal.fire({
        title: "Error",
        text: "Please fill in all required fields",
        icon: "error",
      });
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      Swal.fire({
        title: "Error",
        text: "Passwords do not match",
        icon: "error",
      });
      return;
    }
    // console.log(formData);
  };

  return (
    <div className="app-content content overflow-hidden">
      <div className='card'>
        <div className='card-body'>
      <Row>
        <h6 className='pb-3 pt-3'>Edit ({user?.name}) for Permission</h6>
        <Col sm={12}>
    <Form onSubmit={handleSubmit}>
      <div className='inputs-all-permission row justify-content-between'>
      <FormGroup row>
        <Label for="name" sm={4}>Name</Label>
        <Col sm={12}>
          <Input type="text" name="name" id="name" placeholder="Enter your name" value={formData.name} onChange={handleChange} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="email" sm={4}>Email</Label>
        <Col sm={12}>
          <Input type="email" name="email" id="email" placeholder="Enter your email" value={formData.email} onChange={handleChange} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="password" sm={4}>Password</Label>
        <Col sm={12}>
          <Input type="password" name="password" id="password" placeholder="Enter your password" value={formData.password} onChange={handleChange} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="confirmPassword" sm={4}>Confirm Password</Label>
        <Col sm={12}>
          <Input type="password" name="confirmPassword" id="confirmPassword" placeholder="Confirm your password" value={formData.confirmPassword} onChange={handleChange} />
        </Col>
      </FormGroup>
      {/* <FormGroup row className='down-icon-parent'>
        <Label for="userType" sm={4}>User Type</Label>
        <Col sm={12}>
        <span className='down-icon-pick' style={{bottom:0}}>
        {<FontAwesomeIcon icon={faChevronDown} />}
        </span>
          <Input type="select" name="user_type" id="userType" value={formData.user_type} onChange={handleChange}>
            <option value="">Select User Type</option>
            <option value="admin">Admin</option>
            <option value="ops">Operations</option>
            <option value="accounts">Accounts</option>
          </Input>
        </Col>
      </FormGroup> */}
      </div>
      <h6 className='pb-3 pt-3'>Create Permissions</h6>
      <FormGroup row>
        <Col sm={12} className='permission-checkbox'>
        {Object.entries(displayedPermissions).map(([category, permissions]) => (
  <div key={category}>
    <p className='mb-1'><b>{category}</b></p>
    <div className='inputs-wrappNew flex-wrap d-flex p-3 '>
      {permissions.map(permission => (
        <CustomInput
          key={permission}
          type="checkbox"
          id={`${category}-${permission}`}
          label={permission}
          checked={(formData.permissions[category] || []).includes(permission)}
          onChange={() => handleCheckboxChange(category, permission)}
        />
      ))}
    </div>
  </div>
))}


          <Col className='text-right'>
          <Button onClick={handleEditForm} color="primary" type="submit">Save</Button>
          </Col>
        </Col>
      </FormGroup>
    </Form>
    </Col>
    </Row>
    </div>
    </div>
    </div>
  );
};

export default EditUserPermission;
