import React, {useEffect, useState} from 'react';
import { Card, CardBody, Row, Col,Badge} from 'reactstrap';
import { checkData, checkUndeNullBlank } from '../../../../ObjectExist';
import LoaderDefault from '../../../LoaderDefault';
import {connect, useDispatch} from 'react-redux'
import { dashFreightOverView } from '../../../../actions';
import { errorHandling} from '../../../../utlis';
import { Pie } from 'react-chartjs-2';


const FreightOverview = (props) => {
    const [frieghtView, setFrieghtView] = useState({});
    const [loadertable, setLoadertable] = useState(false);
  const dispatch = useDispatch();
  const listingSummary = () => {
    setLoadertable(true);
    let mypost = {"merchant_id":props.user[0].id}
    let myget = {}
    dispatch(dashFreightOverView(mypost,myget)).then((response) => {
        if(response.error === false){
            setFrieghtView(response.data);
        }
        setLoadertable(false)
      }).catch(function (response) {
        setLoadertable(false);
        errorHandling(response,"reload");
      });
    }
    useEffect(() => {
        if(checkData(props.state) && checkData(props.state.viewAllFreightOver)&& checkData(props.state.viewAllFreightOver.data)){
            setFrieghtView(props.state.viewAllFreightOver.data);
          }else{
            listingSummary();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let totalFreight = checkData(frieghtView) && checkUndeNullBlank(frieghtView.total_freight) ? Math.round(frieghtView.total_freight)  : 0  ;
    let paidFreight = checkData(frieghtView) && checkUndeNullBlank(frieghtView.total_freight_paid) ?  Math.round(frieghtView.total_freight_paid)  : 0 ;
    let adjustedFreight = checkData(frieghtView) && checkUndeNullBlank(frieghtView.total_Freight_adjusted) ?  Math.round(frieghtView.total_Freight_adjusted)  : 0 ;
    let dueFreight = totalFreight-paidFreight-adjustedFreight;
    let dueFreightInPie = checkData(frieghtView) && (frieghtView.total_freight_paid > 0 || frieghtView.total_Freight_adjusted > 0) ? dueFreight : 0 ;
    let paidAdjustedPie = checkData(frieghtView) && (frieghtView.total_freight_paid > 0 || frieghtView.total_Freight_adjusted > 0) ? Math.round(frieghtView.total_Freight_adjusted)+paidFreight : 0 ;

    const data = {
        labels: ['Total Freight', 'Due Freight', 'Paid/Adjusted'],
        datasets: [
          {
            label: 'Freight (counts)',
            data: [totalFreight,dueFreightInPie,paidAdjustedPie],
            backgroundColor: [
                'rgb(27 90 142)',
                'rgb(179 100 23)',
                'rgb(47,129,83)',
            ],
            hoverOffset: 4
          },
        ],      
    };

    const options = {
        plugins: { 
            tooltip: {
                enabled: false
            },           
          title: {
            display: false,
            text: ''
          },
          legend: {
            display: false,
            position:"right",
            labels: { usePointStyle: true }
          },
        },       
      };



    return (
        <div className="freightoverview d-lg-flex w-100">
            <Card className="shadow border-0 w-100">
                <div className="page-header content-header d-flex flex-wrap justify-content-between align-items-center">
                    <div className="page-title">Freight Overview</div>  
                </div>
                <CardBody className="p-0">                    
                    <Row className="mx-0">
                        {!loadertable ?
                        <>
                        <Col sm={7} md={7} lg={12} xl={7}>
                            <Row className="freight-overview-list mb-sm-2">                            
                                <Col xs={6} sm={6} className="border-sort-right">
                                    <div className='media'>
                                        <div className='media-body my-auto py-2'>
                                            <h4 className="card-val card-val2 text-primary"><span>{checkData(frieghtView) ? checkUndeNullBlank(frieghtView.total_freight) ? Math.round(frieghtView.total_freight) : 0 : 0}</span></h4>
                                            <p className="text-muted font-weight-500 title-card">Total Freight</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} sm={6} className="border-sort-bottom">
                                    <div className='media'>
                                        <div className='media-body my-auto py-2'>
                                            <h4 className="card-val card-val2 text-primary"><span>{paidFreight}</span></h4>
                                            <p className="text-muted font-weight-500 title-card">Freight Paid</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} sm={6} className="border-sort-top border-sort-right-2">
                                    <div className='media'>
                                        <div className='media-body my-auto py-2'>
                                            <h4 className="card-val card-val2 text-primary"><span>{adjustedFreight}</span></h4>
                                            <p className="text-muted font-weight-500 title-card">Freight Adjusted</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} sm={6} className="">
                                    <div className='media'>
                                        <div className='media-body my-auto py-2'>
                                            <h4 className="card-val card-val2 text-due"><span>{dueFreight}</span></h4>
                                            <p className="text-muted font-weight-500 title-card">Due Freight</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={5} md={5} className="d-flex d-lg-none d-xl-flex align-items-center justify-content-center px-xl-0 mb-2">
                            <div className="d-flex align-items-center">
                                <div className="freightPiecon" style={{maxWidth: "100px",width:"100%"}}>
                                    <Pie data={data} options={options} />
                                </div>
                                <div className="freightPieLabels">
                                    <Badge color='success' className='mb-2'><span className="d-block mb-1">Paid/Adjusted</span><span className="d-block">{paidFreight+adjustedFreight}</span></Badge><br/>
                                    <Badge color='warning' className='mb-2'><span className="d-block mb-1">Due Freight</span><span className="d-block">{dueFreight}</span></Badge><br/>
                                    <Badge color='primary'><span className="d-block mb-1">Total Freight</span><span>{totalFreight}</span></Badge>
                                </div>
                            </div>
                        </Col>
                        </>
                        : <div className="py-2"><LoaderDefault /></div>}
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
}

function mapDispatchToProps (state) {
    return{
      state:state.DataReducer
    }
  }
  export default connect(mapDispatchToProps)(FreightOverview);