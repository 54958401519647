import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardTitle, FormGroup, Form, CardHeader, Table, Button, Label, Spinner } from 'reactstrap';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import axios from 'axios';
import Swal from 'sweetalert2'
import { useParams } from 'react-router-dom'
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { errorHandling } from '../../utlis';
import { baseString, states } from '../../config';


const EditPickupPoint = (props) => {
    const [viewPickup, setViewPickup] = useState({})
    const { register: editPickupPoint, handleSubmit: pickupPointSubmit, formState: { errors }, reset: reset2 } = useForm();
    const [loader, setLoader] = useState(false);
    const { merchantId } = useParams();


    useEffect(() => {
        setLoader(true);
        axios({
            method: "post",
            url: `${process.env.REACT_APP_MERCHANT_PICKUPPOINT_VIEW}/${merchantId}`,
            headers: { "token": window.btoa(baseString) }
        })
            .then(function (response) {
                setLoader(false);
                let myResponse = response.data[0];
                reset2(myResponse);
                setViewPickup(myResponse)
            })
            .catch(function (response) {
                setLoader(false);
                errorHandling(response, "");
            });
    }, [merchantId, reset2])
    const onEditPickupPoint = (data) => {
        setLoader(true);
        let formData = new FormData();
        formData.append("contact_name", data.contact_name);
        formData.append("pickup_details_for_label", data.pickup_details_for_label !== undefined ? data.pickup_details_for_label : "");
        formData.append("return_details_for_label", data.return_details_for_label !== undefined ? data.return_details_for_label : "");
        formData.append("address_label_bottom_line", data.address_label_bottom_line);
        formData.append("smart_ship_hub_id", data.smart_ship_hub_id);
        formData.append("smart_ship_1kg_hub_id", data.smart_ship_1kg_hub_id);
        formData.append("smart_ship_bulk_hub_id", data.smart_ship_bulk_hub_id);
        formData.append("smart_ship_exp_hub_id", data.smart_ship_exp_hub_id);
        formData.append("parcelx_warehouse_id", data.parcelx_warehouse_id);
        formData.append("parcelx_0_5kg_warehouse_id", data.parcelx_0_5kg_warehouse_id);
        formData.append("parcelx_1kg_warehouse_id", data.parcelx_1kg_warehouse_id);
        formData.append("parcelx_0_5kg_fixS_warehouse_id", data.parcelx_0_5kg_fixS_warehouse_id	);
        formData.append("shipway_warehouse_id", data.shipway_warehouse_id);

        axios({
            method: "post",
            url: `${process.env.REACT_APP_MERCHANT_PICKUPPOINT_UPDATE}/${merchantId}`,
            data: formData,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            setLoader(false);
            if (res.data.error === false) {
                Swal.fire({
                    title: 'Success',
                    text: res.data.message,
                    icon: 'success',
                })
            }
        })
            .catch((err) => {
                setLoader(false);
                errorHandling(err, "");
            });
    };

    let history = useHistory();
    const goToPreviousPath = () => {
        if (checkUndeNullBlank(history.location.key)) {
            history.goBack();
        }
    }

    return (
        <div className="app-content content overflow-hidden">
            {loader && <div className="formLoader"><Spinner /></div>}
            {Object.entries(viewPickup).length > 0 &&
                <Row>
                    <Col sm={12}>
                        <div className="p-0 my-3 addPickupPoints content-wrapper border-0">
                            <Card className="shadow border-0">
                                <CardHeader className="page-header d-flex flex-wrap justify-content-between">
                                    <CardTitle className="page-title">View Pickup Points</CardTitle>
                                    <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                                        {/* <Link to="/my-pickuppoint" className="btn-text btn-light"><span className="icon"><ArrowLeft /></span><span>Back to Pickup Points List</span></Link> */}
                                        <div onClick={goToPreviousPath} className="btn-text btn-light"><span className="icon"><ArrowLeft /></span><span>Back to Pickup Points List</span></div>
                                    </div>
                                </CardHeader>
                                <CardBody className="pt-0">
                                    <Row>
                                        <Col sm={6}>
                                            <CardHeader className="px-0"><CardTitle>Pickup Details</CardTitle></CardHeader>
                                            <Table>
                                                <tbody>
                                                    <tr>
                                                        <th className="border-0">Pickup Address 1 :</th>
                                                        <td>{viewPickup.pickup_address_1}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Pickup Address 2 :</th>
                                                        <td>{viewPickup.pickup_address_2}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Pickup Pincode :</th>
                                                        <td>{viewPickup.pickup_pincode}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Pickup City :</th>
                                                        <td>{viewPickup.pickup_city_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Pickup State :</th>
                                                        <td>{states[viewPickup.pickup_state_id]}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Pickup Mobile :</th>
                                                        <td>{viewPickup.pickup_phone}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                        <Col sm={6}>
                                            <CardHeader className="px-0"><CardTitle>Return Details</CardTitle></CardHeader>
                                            <Table>
                                                <tbody>
                                                    <tr>
                                                        <th className="border-top-0">Return Address 1 :</th>
                                                        <td>{viewPickup.return_address_1}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Return Address 2 :</th>
                                                        <td>{viewPickup.return_address_2}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Return Pincode :</th>
                                                        <td>{viewPickup.return_pincode}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Return City :</th>
                                                        <td>{viewPickup.return_city_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Return State :</th>
                                                        <td>State Name</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Return Mobile :</th>
                                                        <td>{viewPickup.return_phone}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Form onSubmit={pickupPointSubmit(onEditPickupPoint)}>
                                <Card className="shadow border-0">
                                    <CardHeader className="d-flex justify-content-between"><CardTitle>Edit Info</CardTitle></CardHeader>
                                    <CardBody className="pt-0">
                                        <Row className="mx-0">
                                            <Col sm={12} className="px-0">
                                                <FormGroup>
                                                    <Label>Pickup Point/Contact Name</Label>
                                                    <input {...editPickupPoint("contact_name", { required: 'Please Enter Contact Name' })} className="form-control-sm form-control" placeholder="Full Name" />
                                                    {errors.contact_name && <span className="text-danger d-block mb-2">{errors.contact_name.message}</span>}
                                                </FormGroup>
                                            </Col>
                                            {checkData(props.user) && props.user[0].user_type === "Super" &&
                                                <>
                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <Label>Smart Ship Hub Id</Label>
                                                            <input {...editPickupPoint("smart_ship_hub_id")} className="form-control-sm form-control" placeholder="Smart Ship Hub Id" />
                                                            {errors.smart_ship_hub_id && <span className="text-danger d-block mb-2">{errors.smart_ship_hub_id.message}</span>}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <Label>Smart Ship 1Kg Hub Id</Label>
                                                            <input {...editPickupPoint("smart_ship_1kg_hub_id")} className="form-control-sm form-control" placeholder="Smart Ship 1Kg Hub Id" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <Label>Smart Ship Bulk Hub Id</Label>
                                                            <input {...editPickupPoint("smart_ship_bulk_hub_id")} className="form-control-sm form-control" placeholder="Smart Ship Bulk Hub Id" />
                                                            {errors.smart_ship_bulk_hub_id && <span className="text-danger d-block mb-2">{errors.smart_ship_bulk_hub_id.message}</span>}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <Label>Smart Ship Express Hub Id</Label>
                                                            <input {...editPickupPoint("smart_ship_exp_hub_id")} className="form-control-sm form-control" placeholder="Smart Ship Express Hub Id" />
                                                            {errors.smart_ship_exp_hub_id && <span className="text-danger d-block mb-2">{errors.smart_ship_exp_hub_id.message}</span>}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <Label>Parcel X 5kg Warehouse id</Label>
                                                            <input {...editPickupPoint("shipway_warehouse_id")} className="form-control-sm form-control" placeholder="Shipway Warehouse Id" />
                                                            {errors.shipway_warehouse_id && <span className="text-danger d-block mb-2">{errors.shipway_warehouse_id.message}</span>}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <Label>Fship Warehouse id</Label>
                                                            <input {...editPickupPoint("intargos_hub_id")} className="form-control-sm form-control" placeholder="Fship HUB id" />
                                                            {errors.intargos_hub_id && <span className="text-danger d-block mb-2">{errors.intargos_hub_id.message}</span>}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <Label>Parcel X Warehouse Id</Label>
                                                            <input {...editPickupPoint("parcelx_warehouse_id")} className="form-control-sm form-control" placeholder="Parcel X Warehouse Id" />
                                                            {errors.parcelx_warehouse_id && <span className="text-danger d-block mb-2">{errors.parcelx_warehouse_id.message}</span>}
                                                        </FormGroup>
                                                    </Col>

                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <Label>Parcel X 0.5KG Fixed Warehouse Id</Label>
                                                            <input {...editPickupPoint("parcelx_0_5kg_warehouse_id")} className="form-control-sm form-control" placeholder="Parcel X 0.5KG Fixed Warehouse Id" />
                                                            {errors.parcelx_0_5kg_warehouse_id && <span className="text-danger d-block mb-2">{errors.parcelx_0_5kg_warehouse_id.message}</span>}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <Label>Parcel X 1kg Fixed Warehouse Id</Label>
                                                            <input {...editPickupPoint("parcelx_1kg_warehouse_id")} className="form-control-sm form-control" placeholder="Parcel X 1kg Fixed Warehouse Id" />
                                                            {errors.parcelx_1kg_warehouse_id && <span className="text-danger d-block mb-2">{errors.parcelx_1kg_warehouse_id.message}</span>}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <Label>Parcel X 1kg Fixed Shirt Warehouse Id</Label>
                                                            <input {...editPickupPoint("parcelx_0_5kg_fixS_warehouse_id")} className="form-control-sm form-control" placeholder="Parcel X 1kg Fixed Shirt Warehouse Id" />
                                                            {errors.parcelx_0_5kg_fixS_warehouse_id && <span className="text-danger d-block mb-2">{errors.parcelx_0_5kg_fixS_warehouse_id.message}</span>}
                                                        </FormGroup>
                                                    </Col>
                                                </>
                                            }
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Row>
                                    <Col md={6}>
                                        <Card className="shadow border-0">
                                            <CardHeader className="d-flex justify-content-between"><CardTitle>Pickup Details</CardTitle><div><small>(For Address Label)</small></div></CardHeader>
                                            <CardBody className="pt-0">
                                                <FormGroup>
                                                    <textarea {...editPickupPoint("pickup_details_for_label")} className="form-control-sm form-control" placeholder="Pickup Details For address label" />
                                                </FormGroup>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md={6}>
                                        <Card className="shadow border-0">
                                            <CardHeader className="d-flex justify-content-between"><CardTitle>Return Details</CardTitle><div><small>(For Address Label)</small></div></CardHeader>
                                            <CardBody className="pt-0">
                                                <FormGroup>
                                                    <textarea {...editPickupPoint("return_details_for_label")} className="form-control-sm form-control" placeholder="Return Details For address label" />
                                                </FormGroup>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Card className="shadow border-0 d-none">
                                    <CardHeader><CardTitle className="mb-0">Address Labels Bottom Line</CardTitle><small>(e.g note)</small></CardHeader>
                                    <CardBody className="pt-0">
                                        <FormGroup>
                                            <textarea {...editPickupPoint("address_label_bottom_line")} className="form-control-sm form-control" placeholder="Address Lables Bottom Line" />
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                                <Row>
                                    <Col sm={12} className="text-right ml-auto btn-container">
                                        <Card><Button className="ctm-btn btn-sm" color="primary">Save</Button></Card>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>

                </Row>
            }
        </div>
    );
}

export default EditPickupPoint;