import React, { useEffect } from 'react';
import { Row, Col, Button, Label, FormGroup, Form, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useForm } from "react-hook-form";
import { StateList } from './StateList';
import Swal from 'sweetalert2'
import { errorHandling } from '../../utlis';
import { baseString } from '../../config';

const EditPincode = (props) => {
  const { register: editpincode, handleSubmit: pincodeEditSubmit, formState: { errors }, reset } = useForm();
  useEffect(() => {
    reset(props.editpin[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, props.editpin])
  const onEditPincodeSubmit = (data) => {
    props.setLoader !== undefined && props.setLoader(true);
    let dataAdd = { "city_id": data.city_id, "state_id": data.state_id, "pincode": data.pincode };
    fetch(`${process.env.REACT_APP_GET_PINCODE}/${props.editpin[0].id}`, {
      method: 'PUT',
      headers: {
        "Content-Type": 'application/json',
        "Accept": 'application/json',
        "token": window.btoa(baseString)
      },
      body: JSON.stringify(dataAdd)
    }).then((resp) => {
      resp.json().then((result) => {
        if (result.error === false) {
          props.setLoader !== undefined && props.setLoader(false);
          props.seteditModal !== undefined && props.seteditModal(false);
          props.pincodeListing !== undefined && props.pincodeListing();
          Swal.fire({
            title: 'Success',
            text: result.message,
            icon: 'success',
          })
        }
      });
    }).catch((err) => {
      props.setLoader !== undefined && props.setLoader(false);
      errorHandling(err, "");
    });
  };

  const handleClick = event => {
    props.onclick(event.target.value);
  }

  return (

    <Modal isOpen={props.isOpen} toggle={props.toggle} scrollable={true}>
      <ModalHeader toggle={handleClick}>Edit Pincode</ModalHeader>
      <ModalBody>
        {Object.entries(props.editpin).length > 0 &&
          <Form onSubmit={pincodeEditSubmit(onEditPincodeSubmit)}>
            <Row className="mx-0 justify-content-center addModal">
              <Col sm={12}>
                <FormGroup>
                  <Label for="state_edit">Select State<span className="text-danger">*</span></Label>
                  <div className="position-relative">
                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                    <select {...editpincode("state_id", { required: 'Please Select State' })} className="form-control-sm form-control" id="state_edit" onChange={props.onchange}>
                      <option value={props.editpin[0].state}>{props.editpin[0].state}</option>
                      {StateList.map((item, index) => (
                        <option value={item.state_id}>{item.state_name}</option>
                      ))
                      }
                    </select>
                    {errors.state_id && <span className="text-danger d-block error">{errors.state_id.message}</span>}
                  </div>
                </FormGroup>
              </Col>
              {(Object.entries(props.statecity).length > 0) &&
                <Col sm={12}>
                  <FormGroup>
                    <Label for="city_edit">Select City<span className="text-danger">*</span></Label>
                    <div className="position-relative">
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                      <select {...editpincode("city_id", { required: 'Please Select City' })} className="form-control-sm form-control" id="city_edit">
                        <option value="">Select City</option>
                        {Object.entries(props.statecity).length > 0 && props.statecity !== undefined ? props.statecity.map((item, index) => (
                          <option value={item.id}>{item.name}</option>
                        ))
                          : ''}
                      </select>
                      {errors.city_id && <span className="text-danger d-block error">{errors.city_id.message}</span>}
                    </div>
                  </FormGroup>
                </Col>
              }
              <Col sm={12}>
                <FormGroup>
                  <Label for="pincode_edit">Enter Pincode<span className="text-danger">*</span></Label>
                  <input {...editpincode("pincode", {
                    required: 'Please Enter Pincode.',
                    minLength: { value: 6, message: "at least 6 digits" },
                    maxLength: { value: 6, message: "maximum 6 digits" },
                    pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Pincode' }
                  })} type="text" className="form-control-sm form-control" placeholder="Enter Pincode" id="pincode_edit" />
                  {errors.pincode && <span className="text-danger d-block error">{errors.pincode.message}</span>}
                </FormGroup>
              </Col>
              <Col sm={12} className="text-right">
                <Button className="ctm-btn btn-sm" color="primary">Save</Button>
              </Col>
            </Row>
          </Form>
        }
      </ModalBody>
    </Modal>

  );
}

export default EditPincode;