import React, { useState, useRef } from 'react';
import { Row, Col, Button, CardTitle, FormGroup, Form, Label, Spinner } from 'reactstrap';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Swal from 'sweetalert2';
import { errorHandling } from '../../utlis';
import { baseString } from '../../config';


const ResetPassword = (props) => {

    const [loader, setLoader] = useState(false);

    const { register: reasetPassword, handleSubmit: reasetPasswordSubmit, watch, formState: { errors: errors4 } } = useForm();


    const new_password = useRef({});
    new_password.current = watch("new_password", "");
    const onResetPasswordSetails = (data) => {
        setLoader(true);
        axios({
            method: "post",
            url: process.env.REACT_APP_MERCHANT_RESETPASSWORD,
            data: { "merchant_id": props.merchantId, "password": data.new_password },
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            setLoader(false);
            if (res.data.error === false) {
                Swal.fire({
                    title: 'Success',
                    text: res.data.msg,
                    icon: 'success',
                })
            }
        }).catch((err) => {
            setLoader(false);
            errorHandling(err, "");
        });
    };



    return (
        <>
            {loader && <div className="formLoader"><Spinner /></div>}
            <Form onSubmit={reasetPasswordSubmit(onResetPasswordSetails)}>
                <div className="sub-page-header">
                    <CardTitle tag="h4" className="sub-page-title">Reset Password</CardTitle>
                </div>
                <Row>
                    <Col sm={12} md={6} xl={6} className="mb-2">
                        <FormGroup>
                            <Label for="password">Enter New Password<span className="text-danger">*</span></Label>
                            <input {...reasetPassword("new_password", {
                                required: 'Please Enter New Password',
                                minLength: { value: 6, message: "at least 6 Characters" }
                            })} type="password" className="form-control-sm form-control" placeholder="Enter New Password" id="password" />
                            {errors4.new_password && <span className="text-danger d-block error">{errors4.new_password.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={12} md={6} xl={6} className="mb-2">
                        <FormGroup>
                            <Label for="confirmPass">Enter Confirm Password<span className="text-danger">*</span></Label>
                            <input {...reasetPassword("confirm_password", {
                                required: 'Please Enter Confirm Password', validate: value => value === new_password.current || "The passwords do not match",
                                minLength: { value: 6, message: "at least 6 Characters" }
                            })} type="password" className="form-control-sm form-control" placeholder="Enter Confirm Password" id="confirmPass" />
                            {errors4.confirm_password && <span className="text-danger d-block error">{errors4.confirm_password.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={12} className="mt-2 text-right">
                        <Button className="ctm-btn btn-sm" color="primary"> Save</Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

export default ResetPassword;
