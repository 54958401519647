import React, { useState, useEffect } from 'react';
import { Row, Col, FormGroup, CardTitle, Spinner, Label, Badge } from 'reactstrap';
import axios from 'axios';
import { checkData, checkUndeNullBlank } from '../../../ObjectExist';
import { errorHandling } from '../../../utlis';
import { baseString } from '../../../config';

const CustomerRating = (props) => {
  const [loader, setLoader] = useState(false);
  const [ratingStatus, setRatingStatus] = useState(false);

  useEffect(() => {
    viewMerchantRating();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const viewMerchantRating = () => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_SETTING}/view`,
      data: { "setting_id": 5, "merchant_id": props.user[0].id },
      headers: { "token": window.btoa(baseString) }
    })
      .then(function (response) {
        setLoader(false);
        if (response.data.length > 0) {
          let myresponse = response.data[0];
          setRatingStatus(myresponse)
        }
      })
      .catch(function (err) {
        setLoader(false);
        errorHandling(err, "")
      });
  }

  let status = checkData(ratingStatus) && checkUndeNullBlank(ratingStatus.setting_value) && parseInt(ratingStatus.setting_value) === 1 ? 'Enabled' : 'Disabled'
  return (
    <>
      {loader && <div className="formLoader"><Spinner /></div>}
      <div className="sub-page-header">
        <CardTitle tag="h4" className="sub-page-title">Customer Rating</CardTitle>
      </div>
      <Row>
        <Col sm={6}>
          <FormGroup>
            <Label>Rating<span className="text-danger">*</span></Label>
            <div>
              <span>
                <Badge color={`${status === 'Enabled' ? 'success' : 'danger'}`}>{status}</Badge>
              </span>
            </div>
          </FormGroup>
        </Col>
        <Col sm={12}>
          <img src={process.env.PUBLIC_URL + '/images/rating-demo.png'} alt="rating-img" className="img-fluid" />
        </Col>
      </Row>
    </>
  );
}

export default CustomerRating;