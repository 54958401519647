import React, { useState } from 'react';
import axios from 'axios';
import { Button, CardTitle, CardBody, Table, Modal, ModalHeader, ModalBody, ModalFooter, Form, Label, Col, Row, FormGroup } from 'reactstrap';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import { baseString } from '../../../config';

function Assignsubmerchant(props) {
  const [addModal, SetaddModal] = useState(false);
  const AdduserToggle = () => SetaddModal(!addModal);

  const { register: addMerchantUser, handleSubmit: MerchantSubmit, reset, formState: { errors } } = useForm();

  const onAddMerchantSubmit = async (data) => {
    let formData = {};
    formData.merchant_id = props.merchantId;
    formData.pickupId = data.pickupid;
    formData.username = data.username;
    formData.password = data.password;

    try {
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_MERCHANT_ASSIGN_PICKUP_USER}`,
        data: formData,
        headers: { token: window.btoa(baseString) },
      });

      if (response.data.success === true) {
        Swal.fire({
          title: 'Success',
          text: response?.data?.message || 'Data Saved Successfully',
          icon: 'success',
        });
        reset();
      } else {
        Swal.fire({
          title: 'Error',
          text: response?.data?.message || 'Something went wrong',
          icon: 'error',
        });
        reset();
      }
    } catch (err) {
      Swal.fire({
        title: 'Error',
        text: err?.response?.data?.message || err?.message || 'Something went wrong',
        icon: 'error',
      });
      reset();
    }
  };

  return (
    <CardBody sm={12}>
      <div className="sub-page-header d-flex justify-content-between flex-wrap">
        <CardTitle className="page-title">Assign User - Pickup Point Wise</CardTitle>
        <div className="card-header-right d-flex flex-wrap align-items-center">
          <Button color="primary" onClick={AdduserToggle}>Add User</Button>
        </div>
        {
          <Modal isOpen={addModal} AdduserToggle={AdduserToggle} scrollable={true} size="lg">
            <ModalHeader toggle={AdduserToggle} >Add User</ModalHeader>
            <ModalBody>
              <Form onSubmit={MerchantSubmit(onAddMerchantSubmit)}>
                <Row>
                  <Col sm={4}>
                    <FormGroup>
                      <Label for="pickupid_add">Enter Pickup Point Id<span className="text-danger">*</span></Label>
                      <input {...addMerchantUser("pickupid", { required: "Please Enter Pickup id", pattern: { value: /^[0-9]+$/, message: "Invalid id" } })} className="form-control-sm form-control" placeholder="Enter Id" id="pickupid_add" />
                      {errors.pickupid && <span className="text-danger d-block error">{errors.pickupid.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup>
                      <Label for="username_add">Enter Username<span className="text-danger">*</span></Label>
                      <input {...addMerchantUser("username", { required: "Please Enter Username" })} className="form-control-sm form-control" placeholder="Enter Username" id="username_add" />
                      {errors.username && <span className="text-danger d-block error">{errors.username.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup>
                      <Label for="password_add">Enter Password<span className="text-danger">*</span></Label>
                      <input {...addMerchantUser("password", { required: "Please Enter Password" })} className="form-control-sm form-control" placeholder="Enter Password" id="password_add" />
                      {errors.password && <span className="text-danger d-block error">{errors.password.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col sm={12} className="text-right">
                    <Button className="ctm-btn btn-sm" color="primary">Save</Button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
            {/* <ModalFooter>
              <Button color="primary" onClick={AdduserToggle}>Cancel</Button>
            </ModalFooter> */}
          </Modal>
        }
      </div>

      <div>
        <h6 style={{ marginTop: "50px" }}>List of Active Users</h6>
        <Table responsive className="custom-table">
          <thead className="text-center" style={{ backgroundColor: 'rgba(27, 90, 143, 0.1)' }}>
            <tr>
              <th className="text-nowrap">Pickup Id</th>
              <th className="text-nowrap">Username</th>
              <th className="text-nowrap">Password</th>
              <th className="text-nowrap">Action</th>
            </tr>
          </thead>
          <tbody className="text-center">
            <tr style={{ lineHeight: "1" }}>
              <td>124</td>
              <td>trendily#123</td>
              <td>1233456</td>
              <td>
                <Button style={{ marginRight: "5px" }} color="primary" outline>Edit</Button>
                <Button color="danger" outline>Delete</Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </CardBody>
  );
}

export default Assignsubmerchant;
