import React,{useState, useEffect} from 'react';
import { Row, Col, Card, CardBody,CardTitle,Form, FormGroup, Label, Button, Input, InputGroup, InputGroupAddon, InputGroupText, Spinner } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import {Eye, EyeOff } from 'react-feather';
import loginBg from "../../assets/img/login-bg.svg";
import { useForm } from 'react-hook-form';
import { LoginAdmin } from '../../actions';
import { connect, useDispatch } from "react-redux";
import Swal from 'sweetalert2';
import { encryptStorageLogin } from '../../EncStorage';

const AdminLogin = (props) => {
    const [passwordType, setPasswordType] = useState(true);
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const [userName,setUserName]=useState([]);
useEffect(() =>{
    // if(props.user !== undefined && props.user !== null && Object.entries(props.user).length > 0){
    //     history.push("/");
    //   }else{
    //     history.push("/login/admin");
    //   }
}, [])

    const { register:loginInput, handleSubmit:loginSubmit, formState: { errors }} = useForm();
    const onAdminLogin = (data) => {
        setLoader(true);
        data.email = data.email.trim();
        data.password = data.password.trim();
        dispatch(LoginAdmin(data)).then(data => {
            
            if(data.error !== true ){
                setLoader(false);
                props.onUserIdChange(data.data[0].id)
                encryptStorageLogin.setItem('userenc', JSON.stringify(data.data));
                history.push("/");
            }else{
                setLoader(false);
                Swal.fire({icon:'error', text:"Invalid Credentials", title:'Error'});
            }
          }).catch(function (response) {
          setLoader(false)
          Swal.fire({title:'Error', icon:'error', text:response.msg})
      });
    };
    return (
        <div className="app-content m-0 p-0 content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
          <Row>
           <Col sm={12}>            
            <div className="p-0 LoginPage content-wrapper">
                <Row>
                    <Link href="#" className="brand-logo text-center py-3 py-lg-0 d-none" ><img src={`../logo${process.env.REACT_APP_ADMIN_ID}.png`} alt="logo" style={{maxWidth:'200px'}} /></Link>
                    <Col sm={12} lg={7} className="d-none d-lg-flex align-items-center p-5">
                        <div className="d-lg-flex justify-content-center align-items-center px-5 w-100">
                            <img className="img-fluid" src={loginBg} alt="img" />
                        </div>
                    </Col>
                    <Col sm={12} lg={5} className="d-flex align-items-center px-2 p-lg-5 bg-white">
                        <Card className="border-0 px-xl-3 mx-auto">
                            <Link href="#" className="brand-logo2 text-center py-3 py-lg-0" ><img src={`../logo${process.env.REACT_APP_ADMIN_ID}.png`} alt="logo" style={{maxWidth:'200px'}} /></Link>
                            <CardBody className="align-items-center">
                              <CardTitle tag="h3">Welcome to {process.env.REACT_APP_NAME} Admin</CardTitle>
                              <p className="card-text">Please sign-in to your account and start the {process.env.REACT_APP_NAME}</p>
                              <Form onSubmit={loginSubmit(onAdminLogin)}>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <input {...loginInput("email",{ required: 'Please Enter Email'})} className="form-control" placeholder="Enter Email" id="email" />
                                    {errors.email && <span className="text-danger d-block mb-2">{errors.email.message}</span>}
                                </FormGroup> 
                                <FormGroup>
                                    <div className="d-flex justify-content-between">
                                        <Label for="password">Password</Label>
                                    </div>
                                    <InputGroup>
                                        <input {...loginInput("password",{ required: 'Please Enter Password'})} type={passwordType? "password" : "text"} placeholder="Enter Password" className="form-control" id="password" />
                                        <InputGroupAddon addonType="append" onClick={() => setPasswordType(!passwordType)}>
                                            <InputGroupText className="py-0"> {passwordType ? <Eye /> : <EyeOff />}</InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>  
                                    {errors.password && <span className="text-danger d-block mb-2">{errors.password.message}</span>}
                                </FormGroup>
                                <FormGroup>
                                    <FormGroup check>
                                        <Label check><Input type="checkbox" />Remember Me </Label>
                                    </FormGroup>
                                </FormGroup>                                
                                <FormGroup>              
                                    <Button className="ctm-btn btn-sm w-100" color="primary"> Sign in</Button>                                     
                                </FormGroup>
                                <p className="text-center">
                                    <span>New on our platform?</span>
                                    <Link to="/register" className="ml-25">Create an account</Link>
                                </p>
                              </Form>
                            </CardBody>
                        </Card>                          
                    </Col>                    
                </Row>
              
            </div>
            </Col>         
          </Row>
        </div>
    );
}
function mapStateToProps (state) {
    return{
      state:state.DataReducer
    }
  }
export default connect(mapStateToProps)(AdminLogin);