import { baseString } from "../../config"

function isDate(val) {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === '[object Date]'
  }
  
  function isObj(val) {
    return typeof val === 'object'
  }
  
   function stringifyValue(val) {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val)
    } else {
      return val
    }
  }
  
  function buildForm({ action, params }) {
    const form = document.createElement('form')
    form.setAttribute('method', 'post')
    form.setAttribute('action', action)
  
    Object.keys(params).forEach(key => {
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', key)
      input.setAttribute('value', stringifyValue(params[key]))
      form.appendChild(input)
    })
  
    return form
  }
  
  function post(details) {
    const form = buildForm(details)
    document.body.appendChild(form)
    form.submit()
    form.remove()
  }
  const getData=(data)=>
  {
    return fetch(process.env.REACT_APP_PAYU_CALLBACK_SEND_URL,{
        method:"POST",
        headers:{
            Accept:"application/json",
            "Content-Type":"application/json",
            "token": window.btoa(baseString)
        },
        body:JSON.stringify(data)
    }).then(response=>response.json()).catch(err=>console.log(err))
  }
export const makePaymentpayu=(param)=>
    {
getData().then(response=>{
    var information={
        action:"https://secure.payu.in/_payment",
        params:param
       }
  post(information)
})
}

