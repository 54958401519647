import Swal from 'sweetalert2';
import { order_status } from './config';
import { checkUndeNullBlank } from './ObjectExist';
import { toast } from 'react-toastify';

export function changeIntoDate(dateTime){
    
    if(dateTime !== undefined && dateTime !== null){
    let date=new Date(dateTime),
    month = '' + (date.getUTCMonth() + 1),
    day = '' + date.getUTCDate(),
    year = '' + date.getUTCFullYear();

    if(month.length < 2) month = '0' + month;
    if(day.length < 2) day = '0' + day;
     return [day,month,year].join('-');
    }else{
        return ''
    }
}
export function changeIntoDateYmd(dateTime){
    if(dateTime !== undefined && dateTime !== null){
        var date = new Date(dateTime);
        var month = date.getUTCMonth() + 1; //months from 1-12
        var day = date.getUTCDate();
        var year = date.getUTCFullYear();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10){
            day = '0' + day;
        }
        let newdate = year + "-" + month + "-" + day
        return newdate;
    }else{
        return ''
    }
}
export function changeIntoDatedmY(dateTime){
    if(dateTime !== undefined && dateTime !== null){
    let date=new Date(dateTime).toLocaleDateString('sv-SE', {
        day: '2-digit',month: '2-digit',year: 'numeric'});
     return date;
    }else{
        return ''
    }
}
export function daysCount(endDate, startDate){
    const ONE_DAY = 1000 * 60 * 60 * 24;
    let s_date =changeIntoDateYmd(startDate);
    let e_date =changeIntoDateYmd(endDate);
    if((endDate !== undefined && endDate !== null) && (startDate !== undefined && startDate !== null)){
    const differenceMs = Math.abs(Date.parse(e_date) - Date.parse(s_date));
    const totalDays = Math.round(differenceMs / ONE_DAY);
    return totalDays
    }else{
        return '0'
    }
}
export function getPickupDate(dateTime){
    if(dateTime !== undefined && dateTime !== null){
        // let orderDate = new Date(dateTime);
        // let OrderDateHour = orderDate.getHours();
        let today = new Date();
        let currentHour = today.getHours();
        let todayDate = changeIntoDateYmd(today.setDate(today.getDate()));
        let tommorowDate = changeIntoDateYmd(today.setDate(today.getDate() + 1));
        if(currentHour < 11){    //i am changing it to 11 from 12;
            return todayDate
        }else{
            return tommorowDate
        }
    }else{
        return ''
    }
}
export function changeIntoDateTime(dateTime){
    if(dateTime !== undefined && dateTime !== null){
        var date = new Date(dateTime);
        var month = date.getUTCMonth() + 1; //months from 1-12
        var day = date.getUTCDate();
        var year = date.getUTCFullYear();
        var hour = date.getUTCHours();
        var minute = date.getUTCMinutes();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10){
            day = '0' + day;
        }
        
       let newdate = day + "-" + month + "-" + year + " " + hour + ":" + minute
     return newdate;
    }else{
        return 'NA'
    }
}
export function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
export function randomNumberByDate() {
    let random=Math.floor((Math.random() * 100 + 1));
    var ts = parseInt(Date.now()/1000);
    let randomNumber=parseInt(ts)+parseInt(random);
    return randomNumber

}
export const copyToClipBoard = async copyMe => {
    try {
        await navigator.clipboard.writeText(copyMe);
        return true;
    } catch (err) {
        return false;
    }
};
export const moreText = (text) =>{
    const limit = 50;
    if(checkUndeNullBlank(text)&&text.length >= limit){
        return text.substr(0,50) + "...";
    }else{
        return text;
    }
}
export const getFirstLetter = (name)=>{
    if(checkUndeNullBlank(name)){
      return name.substring(0,1);
    }
}
export const removeObjData = (obj,delList) =>{
    if(obj !== "" && delList !== "" && delList.length !== 0){
        delList.forEach((item) => {
          delete obj[item];
        }); 
        return obj; 
    }else{
        return "Please Pass Valid Parameters"; 
    }     
}
export const addObjData = (obj,delList) =>{
    if(obj !== "" && delList !== "" && delList.length !== 0){
        return obj; 
    }else{
        return "Please Pass Valid Parameters"; 
    }     
}
export const firstDayOftheMonth = () =>{
    var date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), 1);
}
export const lastDayOftheMonth = () =>{
    var date = new Date();
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}
export const extension = (filename) => {
    var r = /.+\.(.+)$/.exec(filename);
    return r ? r[1] : null;
  }
export const remExtraSpace = (value) =>{
    return value.replace(/\s+/g, ' ').trim();
  }

export const errorHandling = (err,forError) =>{
    if(String(err) === "Error: Network Error" || String(err) === "TypeError: Failed to fetch" ){
        // Swal.fire({icon:'error',html:`Network Error<br/>${forError === "" ? "Please try again!" : forError === "reload" || forError === "Reload" ? 'Please '+forError+' and try again!' : "Please try again!"}`,title:'Error'});
        // console.log(err);
    }else{
        Swal.fire({icon:'error',text:err,title:'Error'});
    }
}
export function urlencode(str) {
    str = (str).toString();
    return encodeURIComponent(str)
  }
export function arrayValueSum(value) {
    let sum = 0;
    for (let i = 0; i < value.length; i++) {
        sum += value[i];
    }
    return sum
  }
export function characterCheck(value) {
    let text = value;
    let pattern = /[^a-zA-Z0-9!@#$%&*()_\-+={[}\]|\:;"'<,>.?\/\\~ ]/g;
    let result = text.match(pattern);
    if(result!=='' && result!=='Null' && result!==null){
        result='Error '+result
    }
    return result
  }
export function checkAdminStatus(userType, statusId, lostStatue) {
    let result =  order_status[statusId];
    if(userType === "Super" && statusId === 13 && lostStatue === 1){
        result = order_status[13]
    }
    if(userType === "Merchant" && statusId === 15 && lostStatue === 1){
        result = order_status[15]
    }
    return result
  }
export function copied(text) {
        let decision = copyToClipBoard(text);
        decision.then(function(result) {
          if(result === true){
            toast("Copied!", {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
    return decision

  }
export function nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }
  
  export const capitalize = (str, lower = false) =>(lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());